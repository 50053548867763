<script lang="ts" setup>
import { onMounted, onUpdated, reactive } from "vue";
import { useRoute } from "vue-router";
import { useHelperStore } from "@/stores/helperStore";
import { useUiStore } from "@/stores/uiStore";
import FormTicketNewCandidateSearch from "@/pages/forms/FormTicketNewCandidateSearch.vue";
import FormTicketEmployeePayrollDisputes from "@/pages/forms/FormTicketEmployeePayrollDisputes.vue";
import FormTicketEmployeeLoanAndDeductions from "@/pages/forms/FormTicketEmployeeLoanAndDeductions.vue";
import FormTicketCertificateOfEmployment from "@/pages/forms/FormTicketCertificateOfEmployment.vue";
import FormTicketITRRequest from "@/pages/forms/FormTicketITRRequest.vue";
import FormTicketEmployeeLeaveRequestVue from "@/pages/forms/FormTicketEmployeeLeaveRequest.vue";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import FormCTCPayslip from "@/pages/forms/FormCTCPayslip.vue";
import FormOvertimeRequest from "@/pages/forms/FormOvertimeRequest.vue";
import FormEmployeeFeedback from "@/pages/forms/FormEmployeeFeedback.vue";
import FormEmployeeTardiness from "@/pages/forms/FormEmployeeTardiness.vue";
import FormSalaryIncrease from "@/pages/forms/FormSalaryIncrease.vue";
import FormCCTV from "@/pages/forms/FormCCTV.vue";
import FormITEquipment from "@/pages/forms/FormITEquipment.vue";
import FormSupportRequest from "@/pages/forms/FormSupportRequest.vue";

import FormComplete201FileUpdate from "@/pages/forms/FormComplete201FileUpdate.vue";
import FormOccurencePointsRequest from "@/pages/forms/FormOccurencePointsRequest.vue";
import FormTicketLoginsActivation from "@/pages/forms/FormTicketLoginsActivation.vue";
import FormTicketLoginsDeactivation from "@/pages/forms/FormTicketLoginsDeactivation.vue";
import FormEmploymentContractRequest from "@/pages/forms/FormEmploymentContractRequest.vue";
import FormTicketEmployeeTeamChangeRequest from "@/pages/forms/FormTicketEmployeeTeamChangeRequest.vue";
import FormOccurencePointsDispute from "@/pages/forms/FormOccurencePointsDispute.vue";
import FormDeactivationTicket from "@/pages/forms/tickets/FormDeactivationTicket.vue";
import FormShiftChangeRequest from "@/pages/forms/tickets/FormShiftChangeRequest.vue";
import FormTicketEmployeeAttendanceDispute from "@/pages/forms/FormTicketEmployeeAttendanceDispute.vue";
import FormTicketWFHPCSetupRequest from "@/pages/forms/FormTicketWFHPCSetupRequest.vue";
import FormWorkstationRelocationRequest from "@/pages/forms/FormWorkstationRelocationRequest.vue";
import FormTemporaryAccessCard from "@/pages/forms/FormTemporaryAccessCard.vue";

const HelperStore = useHelperStore();
const UiStore = useUiStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;

const componentData = reactive({
    inlineAlert: false,
    inlineAlertMessage: "",
    inlineType: "",
    ticketType: HelperStore.getTicketType(Number(id ?? 0)),
});

const componentToastData = reactive({
    message: "Ticket was created successfully",
    subject: "",
});

onMounted(() => {
    setBreadCrumbs();
});

onUpdated(() => {
    setBreadCrumbs();
});

const getFormName = (id: number) => {
    switch (id) {
        case 1:
            return FormTicketNewCandidateSearch;
        case 2:
            return FormTicketEmployeePayrollDisputes;
        case 3:
            return FormTicketEmployeeLoanAndDeductions;
        case 4:
            return FormTicketCertificateOfEmployment;
        case 5:
            return FormTicketEmployeeLeaveRequestVue;
        case 6:
            return FormTicketITRRequest;
        case 7:
            return FormCTCPayslip;
        case 8:
            return FormOvertimeRequest;
        case 9:
            return FormSalaryIncrease;
        case 10:
            return FormEmployeeFeedback;
        case 11:
            return FormEmployeeTardiness;
        case 12:
            return FormCCTV;
        case 13:
            return FormITEquipment;
        case 14:
            return FormSupportRequest;
        case 15:
            return FormOccurencePointsRequest;
        case 16:
            return FormComplete201FileUpdate;
        case 17:
            return FormTicketLoginsActivation;
        case 18:
            return FormTicketLoginsDeactivation;
        case 19:
            return FormEmploymentContractRequest;
        case 20:
            return FormTicketEmployeeTeamChangeRequest;
        case 21:
            return FormOccurencePointsDispute;
        case 24:
            return FormDeactivationTicket;
        case 25:
            return FormShiftChangeRequest;
        case 26:
            return FormTicketEmployeeAttendanceDispute;
        case 27:
            return FormTicketWFHPCSetupRequest;
        case 28:
            return FormWorkstationRelocationRequest;
        case 30:
            return FormTemporaryAccessCard;
    }
};

const setBreadCrumbs = () => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
};

const handleOutput = (event: { type: string }) => {
    if (event) {
        componentData.inlineAlert = true;
        componentData.inlineAlertMessage =
            event.type === "success"
                ? "Ticket was created successfully"
                : "Unable to create ticket at this time";
        componentData.inlineType = event.type;
        window.scrollTo(0, 0);
    }
};

const handleToastNotification = (event: { name: string }) => {
    componentToastData.subject = event.name;
};
</script>
<template>
    <PageHeaderStandard
        :title="HelperStore.getTicketType(Number(id ?? 0)).name"
    />

    <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-9">
            <component
                :is="getFormName(parseInt(id ?? '0'))"
                @output="handleOutput"
                @ticketType="handleToastNotification"
            />
        </div>
    </div>
</template>
