<script lang="ts" setup>
import { onMounted, PropType, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { useClientInvoiceConfigStore } from "@/stores/clientInvoiceConfigStore";
import { useClientRecurringSubscriptionStore } from "@/stores/clientRecurringSubscriptionStore";
import ClientRecurringSubscriptionInterface from "@/pages/interfaces/clientRecurringSubscriptionInterface";
import ClientInterface from "@/pages/interfaces/clientsInterface";
import Button from "@/base-components/Button";
import {
    FormInput,
    FormLabel,
    InputGroup,
} from "@/base-components/Form";
import FormTextarea from "@/base-components/Form/FormTextarea.vue";
import Lucide from "@/base-components/Lucide";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import TomSelect from "@/base-components/TomSelect";

const ClientRecurringSubscriptionStore = useClientRecurringSubscriptionStore();
const ClientInvoiceConfigStore = useClientInvoiceConfigStore();

const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const route = useRoute();
const emit = defineEmits(["close"]);
const props = defineProps({
    config: {
        type: Object,
        default: {} as ClientRecurringSubscriptionInterface,
    },
    client: {
        type: Object as PropType<ClientInterface>,
        required: true,
    },
});

const formData = reactive({
    id: 0,
    client_id: +route?.params?.id ?? 0,
    invoice_subscription_id: "",
    client_invoice_config_id: "",
    subscription: "",
    effective_from: dayjs().format("YYYY-MM-DD"),
    effective_to: "",
    last_charge: dayjs().format("YYYY-MM-DD"),
    quantity: 1,
    amount: 100,
    frequency: "",
    notes: "",
    user_id: 0,
    client_invoice_config: "",
});

const backendValidationErrors = ref({});
const rules = {
    subscription: { required },
    invoice_subscription_id: {},
    effective_from: { required },
    effective_to: { required: requiredIf(() => false) },
    last_charge: {},
    quantity: { required },
    amount: { required },
    frequency: { required },
    client_invoice_config_id: { required },
    notes: {},
};
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(formData), { $externalResults });
onMounted(() => {
    const { config } = toRefs(props);

    if (config?.value?.id) {
        formData.id = config.value.id;
        formData.client_id = config.value.client_id;
        formData.client_invoice_config_id = String(
            config.value.client_invoice_config_id
        );
        formData.invoice_subscription_id = String(
            config.value.invoice_subscription_id
        );
        formData.subscription = config.value.subscription;
        formData.effective_from = config.value.effective_from;
        formData.effective_to = config.value.effective_to;
        formData.last_charge = config.value.last_charge;
        formData.quantity = config.value.quantity;
        formData.amount = config.value.amount;
        formData.frequency = config.value.frequency;
        formData.notes = config.value.notes;
        formData.user_id = config.value.user_id;

        if (config.value.client_invoice_config_id) {
            ClientInvoiceConfigStore.getClientInvoiceConfig(
                config.value.client_invoice_config_id
            );
        }
    }

    const params = {
        client_id: route?.params?.id,
        dt_params: "",
        searchable_columns: "",
    };
    ClientInvoiceConfigStore.getClientInvoiceConfigs(params);
});

const getBillTypeLabel = (billType: string | undefined): string => {
    if (billType === "adjustment") {
        return "Adjustment Only";
    } else if (billType === "monthly") {
        return "Monthly Cycle";
    } else if (billType === "combined") {
        return "Monthy Cycle w/ Adjustment";
    } else if (billType === "manual") {
        return "Manual";
    } else {
        return "";
    }
};

const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};

const showErrorsNotification = () => {
    errorNotificationToggle();
};

const onChangeEffectiveFrom = (event: Event) => {
    formData.effective_from = (event.target as HTMLInputElement).value;
};

const onChangeEffectiveTo = (event: Event) => {
    formData.effective_to = (event.target as HTMLInputElement).value;
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
const showSuccessNotification = () => {
    successNotificationToggle();
};

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;
    }
    try {
        formData.id === 0
            ? await ClientRecurringSubscriptionStore.addClientRecurringSubscription(
                formData as unknown as ClientRecurringSubscriptionInterface
            )
            : await ClientRecurringSubscriptionStore.updateClientRecurringSubscription(
                formData as unknown as ClientRecurringSubscriptionInterface
            );

        closeForm();
        showSuccessNotification();
    } catch (error: any) {
        $externalResults.value = error.response.data.errors;

        backendValidationErrors.value = {
            message: ["Something went wrong."],
        };

        showErrorsNotification();
    }
};

const closeForm = () => {
    formData.client_id = 0;
    formData.invoice_subscription_id = "";
    formData.subscription = "";
    formData.effective_from = dayjs().format("YYYY-MM-DD");
    formData.effective_to = "";
    formData.last_charge = dayjs().format("YYYY-MM-DD");
    formData.quantity = 1;
    formData.amount = 100;
    formData.frequency = "";
    formData.client_invoice_config_id = "";
    formData.notes = "";
    emit("close");
};
</script>
<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y p-5">
                    <div>
                        <FormLabel class="form-label" for="subscription">
                            Subscription <span class="text-red-500">*</span>
                        </FormLabel>

                        <FormInput
                            id="subscription"
                            v-model="formData.subscription"
                            :value="formData.subscription"
                            class="form-control w-full"
                            name="subscription"
                            type="text"
                        />
                        <template v-if="validate.subscription.$error">
                            <div
                                v-for="(error, index) in validate.subscription
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-3">
                        <FormLabel
                            class="form-label"
                            for="client_invoice_config_id"
                        >
                            Invoice Configuration
                            <span class="text-red-500">*</span>
                        </FormLabel>
                        <TomSelect
                            id="client_invoice_config_id"
                            v-model="formData.client_invoice_config_id"
                            :value="formData.client_invoice_config_id"
                            class="w-full"
                            :options="{
                                allowEmptyOption: true,
                                placeholder: 'Select Invoice Configuration',
                            }"
                        >
                            <option value="">
                                Select Invoice Configuration
                            </option>
                            <option
                                v-for="config in ClientInvoiceConfigStore
                                    .clientInvoiceConfigs.data"
                                :key="config.id"
                                :value="config.id?.toString()"
                                :selected="
                                    ClientInvoiceConfigStore.clientInvoiceConfig
                                        .id == config.id
                                "
                            >
                                {{ getBillTypeLabel(config.bill_type) }}
                            </option>
                        </TomSelect>
                    </div>
                    <div class="mt-1 mb-1">
                        <template v-if="validate.client_invoice_config_id.$error">
                            <div
                                v-for="(error, index) in validate.client_invoice_config_id.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-3">
                        <FormLabel class="form-label" for="frequency">
                            Frequency <span class="text-red-500">*</span>
                        </FormLabel>
                        <TomSelect
                            id="frequency"
                            v-model="formData.frequency"
                            :value="formData.frequency"
                            class="w-full"
                            name="frequency"
                        >
                            <option>Select Frequency</option>
                            <option value="monthly">monthly</option>
                            <option value="quarterly">quarterly</option>
                            <option value="yearly">yearly</option>
                        </TomSelect>
                    </div>
                    <div class="mt-1 mb-1">
                        <template v-if="validate.frequency.$error">
                            <div
                                v-for="(error, index) in validate.frequency
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <FormLabel class="form-label" for="effective_from">
                            Effective From <span class="text-red-500">*</span>
                        </FormLabel>

                        <FormInput
                            id="effective_from"
                            v-model="formData.effective_from"
                            :value="formData.effective_from"
                            class="form-control w-full"
                            name="effective_from"
                            placeholder=""
                            type="date"
                            max="9999-12-31"
                            @change="onChangeEffectiveFrom($event)"
                        />
                        <template v-if="validate.effective_from.$error">
                            <div
                                v-for="(error, index) in validate.effective_from
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <FormLabel class="form-label" for="effective_to">
                            Effective To
                        </FormLabel>

                        <FormInput
                            id="effective_to"
                            v-model="formData.effective_to"
                            :value="formData.effective_to"
                            class="form-control w-full"
                            name="effective_to"
                            placeholder=""
                            type="date"
                            max="9999-12-31"
                            @change="onChangeEffectiveTo($event)"
                        />
                        <p class="mt-2 text-sm leading-6 text-gray-500">
                            Empty effective to will continue perpetually
                        </p>
                        <template v-if="validate.effective_to.$error">
                            <div
                                v-for="(error, index) in validate.effective_to
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <FormLabel class="form-label" for="quantity">
                            Quantity <span class="text-red-500">*</span>
                        </FormLabel>

                        <FormInput
                            id="quantity"
                            v-model.number="formData.quantity"
                            :value="formData.quantity"
                            class="form-control w-full"
                            min="1"
                            name="quantity"
                            placeholder=""
                            type="number"
                        />
                        <template v-if="validate.quantity.$error">
                            <div
                                v-for="(error, index) in validate.quantity
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <FormLabel class="form-label" for="amount">
                            Amount <span class="text-red-500">*</span>
                        </FormLabel>

                        <InputGroup>
                            <FormInput
                                id="amount"
                                v-model.number="formData.amount"
                                :value="formData.amount"
                                type="number"
                                min="1"
                                step="0.01"
                                class="form-control w-full"
                            />
                            <InputGroup.Text v-if="client?.currency">
                                {{ client?.currency?.code }}
                            </InputGroup.Text>
                        </InputGroup>
                        <template v-if="validate.amount.$error">
                            <div
                                v-for="(error, index) in validate.amount
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <FormLabel class="form-label" for="notes"
                            >Notes</FormLabel
                        >
                        <FormTextarea
                            id="notes"
                            v-model="formData.notes"
                            :value="formData.notes"
                            class="form-controll w-full"
                            name="notes"
                            placeholder="Add your notes here..."
                        />
                        <div class="mt-2">
                            <template v-if="validate.notes.$error">
                                <div
                                    v-for="(error, index) in validate.notes
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    @click="closeForm()"
                >
                    Cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-36 mb-2 mr-1"
                    type="submit"
                    variant="customPrimary"
                >
                    Save
                </Button>
            </div>
        </div>
    </form>
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Client Subscription has been successfully added.
            </div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
