import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { Menu } from "@/stores/side-menu";
import { slideDown, slideUp } from "@/utils/helper";
import Icon from "@/base-components/Lucide/Lucide.vue";

interface Route extends RouteLocationNormalizedLoaded {
    forceActiveMenu?: string;
}

export interface FormattedMenu extends Menu {
    active?: boolean;
    activeDropdown?: boolean;
    subMenu?: FormattedMenu[];
    icon: string | typeof Icon;
}

// Setup side menu
const findActiveMenu = (subMenu: Menu[], route: Route): boolean => {
    let match = false;
    subMenu.forEach((item) => {
        if (
            (
                (
                    route.forceActiveMenu !== undefined &&
                    item.pageName === route.forceActiveMenu
                ) ||
                (
                    route.forceActiveMenu === undefined &&
                    item.pageName === route.name
                )
            ) &&
            !item.ignore
        ) {
            match = true;
        } else if (!match && item.subMenu) {
            match = findActiveMenu(item.subMenu, route);
        } else if (
            item.pageName === "clients" &&
            (
                route.name === "clientsDetails" ||
                route.name === "clientNotifications" ||
                route.name === "clientNotificationsAdd" ||
                route.name === "clientNotificationsPreview"
            )
        ) {
            match = true;
        } else if (
            item.pageName === "employees" &&
            route.name === "employeesDetails"
        ) {
            match = true;
        } else if (
            item.pageName === "shifts" &&
            route.name === "shiftsDetails"
        ) {
            match = true;
        } else if (
            item.pageName === "accountManagementView" &&
            route.name === "account-management"
        ) {
            match = true;
        } else if (
            item.pageName === "submitATicket" &&
            (
                route.name === "ticket" ||
                route.name === "ticketDetails"
            )
        ) {
            match = true;
        } else if (
            item.pageName === "invoices" &&
            (
                route.name === "invoice-details" ||
                route.name === "invoicesDashboard"
            )
        ) {
            match = true;
        }
    });
    return match;
};

const nestedMenu = (menu: Array<Menu | "divider">, route: Route) => {
    const formattedMenu: Array<FormattedMenu | "divider"> = [];
    menu.forEach((item) => {
        if (typeof item !== "string") {
            const menuItem: FormattedMenu = {
                icon: item.icon,
                title: item.title,
                pageName: item.pageName,
                subMenu: item.subMenu,
                ignore: item.ignore,
            };
            menuItem.active =
                ((route.forceActiveMenu !== undefined &&
                    menuItem.pageName === route.forceActiveMenu) ||
                    (route.forceActiveMenu === undefined &&
                        menuItem.pageName === route.name) ||
                    (menuItem.subMenu &&
                        findActiveMenu(menuItem.subMenu, route))) &&
                !menuItem.ignore;

            if (menuItem.subMenu) {
                menuItem.activeDropdown = findActiveMenu(
                    menuItem.subMenu,
                    route
                );

                // Nested menu
                const subMenu: Array<FormattedMenu> = [];
                nestedMenu(menuItem.subMenu, route).map(
                    (menu) => typeof menu !== "string" && subMenu.push(menu)
                );
                menuItem.subMenu = subMenu;
            }

            formattedMenu.push(menuItem);
        } else {
            formattedMenu.push(item);
        }
    });

    return formattedMenu;
};

const linkTo = (menu: FormattedMenu, router: Router) => {
    if (menu.subMenu) {
        menu.activeDropdown = !menu.activeDropdown;
    } else {
        if (menu.pageName !== undefined) {
            router.push({
                name: menu.pageName,
            });
        }
    }
};

const enter = (el: HTMLElement) => {
    slideDown(el, 300);
};

const leave = (el: HTMLElement) => {
    slideUp(el, 300);
};

export { nestedMenu, linkTo, enter, leave };
