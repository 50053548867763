<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import { useAuthStore } from "@/stores/authStore";
import { useHelperStore } from "@/stores/helperStore";
import { useTicketsStore } from "@/stores/ticketsStore";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import { FormInput } from "@/base-components/Form";
// import Button from "@/base-components/Button";
import Lucide from "@/base-components/Lucide";
import DataTableSkeleton from "@/pages/components/DataTableSkeleton.vue";

const AuthStore = useAuthStore();
const TicketStore = useTicketsStore();
const HelperStore = useHelperStore();
const route = useRoute();

const initialLazyParams = {
    first: 0,
    rows: 30,
    sortField: null,
    sortOrder: null,
    filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ticket_type_id: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        uname: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        tname: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        form_body: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    },
};

const filters = ref(initialLazyParams.filters);
const tickets = ref();
const selectedtickets = ref();
const selectAll = ref(false);
const loading = ref(true);
const mounting = ref(true);
const totalRecords = ref(0);
const lazyParams = ref<LazyParamsInterface>({ rows: initialLazyParams.rows });
const dt = ref();
const searchableCols = ref(["ticket_type_id", "user.name", "form_body"]);
const showClearFilter = ref(false);

const onPage = (event: { filters?: any }) => {
    console.log("onPage");
    lazyParams.value = event;
    console.log("New onPage");
    console.log(lazyParams);
    loadLazyData();
};

const onSort = (event: { filters?: any }) => {
    showClearFilter.value = true;
    console.log("onSort");
    lazyParams.value = event;
    loadLazyData();
};

const onFilter = () => {
    showClearFilter.value = filters.value.global.value !== "";
    console.log("onFilter");
    lazyParams.value.filters = filters.value;
    loadLazyData();
};

const onSelectAllChange = (event: { checked: any }) => {
    console.log("onSelectAllChange");
};

const onRowSelect = () => {
    console.log("onRowSelect");
    selectAll.value = selectedtickets.value.length === totalRecords.value;
};

const onRowUnselect = () => {
    console.log("onRowUnselect");
    selectAll.value = false;
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = initialLazyParams.filters;

    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };

    loadLazyData();
};

const loadLazyData = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
        employee_id: route?.params?.id,
    };

    await TicketStore.getMyTickets(params);
    tickets.value = TicketStore.myTickets.data;
    totalRecords.value = TicketStore.myTickets.total;

    loading.value = false;
};

onMounted(async () => {
    mounting.value = true;

    // await AuthStore.getLoggedInUserDetails();
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };
    await loadLazyData();

    mounting.value = false;
});

watch(
    () => route.params.id,
    async (newId) => {
        const id = Array.isArray(newId) ? newId[0] : newId;

        if (!isNaN(+id)) {
            mounting.value = true;

            const params = {
                dt_params: JSON.stringify(initialLazyParams),
                searchable_columns: JSON.stringify(searchableCols.value),
                employee_id: id,
            };
            await TicketStore.getMyTickets(params);
            tickets.value = TicketStore.myTickets.data;
            totalRecords.value = TicketStore.myTickets.total;

            mounting.value = false;
        }
    }
);
</script>

<template>
    <DataTableSkeleton v-if="mounting" />

    <template v-else>
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <div class="hidden md:block mx-auto text-slate-500"></div>
            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="w-56 relative text-slate-500">
                    <FormInput
                        v-model="filters['global'].value"
                        :value="filters['global'].value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter"
                    />
                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:filters="filters"
            v-model:selection="selectedtickets"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="30"
            :rowsPerPageOptions="[30, 50, 100]"
            :selectAll="selectAll"
            :totalRecords="totalRecords"
            :value="tickets"
            class="mt-4 p-datatable-sm rounded-lg text-xs"
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No tickets found.</template>
            <template #loading> Loading ticket data. Please wait.</template>
            <Column
                :sortable="true"
                field="ticket_type_id"
                header="Ticket Type"
            >
                <template #body="{ data }">
                    {{ HelperStore.getTicketType(data.ticket_type_id).name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Ticket Type"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="status.name" header="Status">
                <template #body="{ data }">
                    {{ data.status.name }}
                </template>
            </Column>
            <Column :sortable="true" field="created_at" header="Created At">
                <template #body="{ data }">
                    {{ moment(data.created_at).format("YYYY-MM-D h:mm") }}
                </template>
            </Column>
            <Column :exportable="false" style="width: 8rem; max-width: 8rem">
                <template #body="slotProps">
                    <div class="content-center flex justify-center">
                        <div class="">
                            <router-link
                                :to="{
                                    name: 'ticketDetails',
                                    params: { id: slotProps.data.id },
                                }"
                            >
                                <svg
                                    :id="'view_' + slotProps.data.id"
                                    class="icon icon-tabler icon-tabler-eye cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path
                                        d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"
                                    ></path>
                                    <path
                                        d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                                    ></path>
                                </svg>
                            </router-link>
                        </div>
                    </div>
                </template>
                <template #header="slotProps">
                    <div class="content-center flex justify-center">
                        <p></p>
                    </div>
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>
        </DataTable>
    </template>
</template>

<style scoped></style>
