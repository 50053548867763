<script lang="ts" setup>
import {
    defineEmits,
    defineProps,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue";
import { useTicketsStore } from "../../stores/ticketsStore";
import _ from "lodash";
import Button from "../../base-components/Button";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Lucide from "../../base-components/Lucide";

const TicketsStore = useTicketsStore();

const props = defineProps({
    data: Object,
    id: Number,
});
const ticket_id = ref();

watch(
    () => props.id,
    async (newValue, oldValue) => {
        console.log("watching teams " + newValue);
        console.log("watching teams " + oldValue);

        if (newValue !== 0) {
            console.log("id", newValue);
            ticket_id.value = newValue;
            //getEmployeeDetails(newValue);
            //EmployeesStore.updateState();
        } else {
            //EmployeesStore.addState();
            //resetForm();
        }
    }
);

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const errorNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});

const backendValidationErrors = ref<string[]>([]);

const form = reactive({
    ticket_id: props.id,
    attachments: [] as File[][],
});

const rules = {
    ticket_id: {
        required,
    },
    attachments: {
        required,
    },
};

const validate = useVuelidate(rules, toRefs(rules));

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});

const attachment_errors = ref<any>({});
const attachment_error = ref<any>(null);

const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        // attachments.data.push(Array.from(files));
        const fileArray = Array.from(files);
        attachments.data = [fileArray];
    }
    attachment_errors.value = ""
    attachment_error.value = ""
};

const sendFiles = async () => {
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = ["Incomplete or Missing required data"];
        errorNotificationToggle();
        return;
    }

    const formData = new FormData();
    formData.append("ticket_id", String(form.ticket_id));

    if (attachments.data !== null) {
        _.forEach(attachments.data, function (files) {
            _.forEach(files, function (file) {
                formData.append("attachments[]", file);
            });
        });
    }

    await TicketsStore.uploadAttachment(formData).then((response: any) => {
        console.log("ticket upload attachments", response);
        if (response.status === "success") {
            successNotificationToggle();
            emit("close", true);
        } else {
            console.log(response);
            if(response.error?.errors) {
                attachment_errors.value = response.error?.errors['attachments.0']
            }
            if(response.error.error) {
                attachment_error.value = response.error?.error
            }

            backendValidationErrors.value = [
                response.message.response.data.message,
            ];
            // errorNotificationToggle();
        }
    });
};

const emit = defineEmits(["status", "close"]);
const closeModal = () => {
    emit("close", true);
};
</script>

<template>
    <div>
        <div class="intro-y pt-5 pb-5 pl-5 pr-2">
            <div
                class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg text-left pb-14"
            >
                <div
                    class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                >
                    <h2 class="font-medium text-base mr-auto mb-4">
                        Select File
                    </h2>
                </div>
                <div class="mt-4">
                     <label class="form-label inline-block mb-2 text-gray-700"
                        >Upload File:
                        <span style="color: rgb(255, 0, 0)">*</span></label
                    >
                    <p>
                        <!-- :change="validate.attachments.$model" -->
                        <input
                            id="uploadFileBtn"
                            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            type="file"
                            @change="uploadFile"
                        />
                    </p>
                    <template v-if="attachment_error">
                        <div
                            id="error_reason"
                            class="text-danger mt-2"
                        >
                            {{ attachment_error }}
                        </div>
                    </template>
                    <template v-if="attachment_errors">
                        <div
                            v-for="(error, index) in attachment_errors"
                            id="error_reason"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error }}
                        </div>
                    </template>
                    <template v-else>
                        <div
                            v-for="errorBag in backendValidationErrors"
                            id="error_reason"
                            class="text-danger mt-2"
                        >
                            {{ errorBag }}
                        </div>
                    </template>
                   
                    <!-- <template v-if="validate.attachments.$error">
                        <div
                            v-for="(error, index) in validate.attachments
                                .$errors"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error.$message }}
                        </div>
                    </template> -->
                </div>


                
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    variant="warning"
                    @click="closeModal"
                >
                    Cancel
                </Button>

                <Button
                    id="btn_process"
                    class="w-32 mb-2 mr-1"
                    variant="customPrimary"
                    @click="sendFiles"
                >
                    Upload
                </Button>
            </div>
        </div>
    </div>

    <!-- BEGIN: Success Notification Content -->
    <div
        id="attachment-success-notification-content"
        class="toastify-content hidden flex"
    >
        <CheckCircleIcon class="text-success" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Success</div>
            <div class="text-slate-500 mt-1">
                Attachment was saved successfully
            </div>
        </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div
        id="attachment-failed-notification-content"
        class="toastify-content hidden flex"
    >
        <XCircleIcon class="text-danger" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Error occured!</div>
            <div class="text-slate-500 mt-1">
                Attachment was not saved successfully. Please try again.
            </div>
        </div>
    </div>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">Attachment Saved Successfully</div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
