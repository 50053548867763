<script lang="ts" setup>
import { onMounted, provide, ref } from "vue";
import WidgetOpenTickets from "../pages/components/dashboard/WidgetOpenTickets.vue";
import WidgetOverdueTickets from "../pages/components/dashboard/WidgetOverdueTickets.vue";
import MyTickets from "../pages/components/dashboard/MyTickets.vue";
import WidgetOnboardingClients from "../pages/components/dashboard/WidgetOnboardingClients.vue";
import WidgetOnboardingEmployees from "../pages/components/dashboard/WidgetOnboardingEmployees.vue";
import WidgetClockIns from "../pages/components/dashboard/WidgetClockIns.vue";
import WidgetClockOuts from "../pages/components/dashboard/WidgetClockOuts.vue";
import WidgetScheduledEmployeesToday from "./components/dashboard/WidgetScheduledEmployeesToday.vue";
import ChartPendingEngagement from "../pages/components/dashboard/ChartPendingEngagement.vue";
import { useDashboardStore } from "../stores/dashboardStore";
import PanelActivityHistory from "./components/PanelActivityHistory.vue";
import ReportDonutChart from "../components/DonutChart/DonutChart.vue";
import ReportBarChart1 from "../components/ReportBarChart1/ReportBarChart1.vue";
import { useFreskdeskStore } from "../stores/freshdeskStore";
import WidgetTime from "../pages/components/dashboard/WidgetTime.vue";
import PrimeCalendar from "../pages/components/dashboard/PrimeCalendar.vue";
import { useAuthStore } from "../stores/authStore";
import { useUiStore } from "../stores/uiStore";

const clientInfo = ref();
const timezone = ref();
const ticketsInfo = ref();
const importantNotesRef = ref();

const DashboardStore = useDashboardStore();
const FreskdeskStore = useFreskdeskStore();
const AuthStore = useAuthStore();
const UiStore = useUiStore();
onMounted(async () => {
    UiStore.setBreadcrumb("Dashboard");
    // await AuthStore.getLoggedInUserDetails();
    await FreskdeskStore.fill();
    await DashboardStore.hrDashboardfill();

    (window as any).Echo.channel("test-channel").listen("Hello", (e: any) => {
        console.log(e);
        alert("Websockets works");
    });
});

provide("bind[importantNotesRef]", (el: any) => {
    importantNotesRef.value = el;
});

const prevImportantNotes = () => {
    const el = importantNotesRef.value;
    el.tns.goTo("prev");
};

const nextImportantNotes = () => {
    const el = importantNotesRef.value;
    el.tns.goTo("next");
};
</script>
<template>
    <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 2xl:col-span-9">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 mt-2">
                    <div class="grid grid-cols-8 gap-4 mt-5">
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y"
                        >
                            <MyTickets />
                        </div>
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y"
                        >
                            <WidgetOnboardingClients
                                :count="DashboardStore.hrSlider.clients"
                            ></WidgetOnboardingClients>
                        </div>
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y"
                        >
                            <WidgetOnboardingEmployees
                                :count="DashboardStore.hrSlider.employees"
                            ></WidgetOnboardingEmployees>
                        </div>
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y"
                        >
                            <WidgetClockIns></WidgetClockIns>
                        </div>
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y mt-2"
                        >
                            <WidgetClockOuts></WidgetClockOuts>
                        </div>
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y mt-2"
                        >
                            <WidgetOpenTickets
                                :value="FreskdeskStore.open"
                            ></WidgetOpenTickets>
                        </div>
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y mt-2"
                        >
                            <WidgetOverdueTickets
                                :value="FreskdeskStore.overdue"
                            ></WidgetOverdueTickets>
                        </div>
                        <div
                            class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y mt-2"
                        >
                            <WidgetScheduledEmployeesToday />
                        </div>
                    </div>
                </div>

                <div
                    class="col-span-12 sm:col-span-6 lg:col-span-9 mt-8 opacity-50 blur-sm"
                >
                    <h2 class="text-lg font-medium truncate mr-auto">
                        Employees & Clients Growth Rate
                    </h2>
                    <div class="bg-white p-2 mt-8 rounded">
                        <!--                        <TabList-->
                        <!--                            class="w-1/3 nav-pills border border-slate-300 dark:border-darkmode-300 border-dashed rounded-md mx-auto p-1 mt-5"-->
                        <!--                        >-->
                        <!--                            <Tab class="w-full py-1.5 px-2 active" tag="button"-->
                        <!--                                >Weekly-->
                        <!--                            </Tab>-->
                        <!--                            <Tab class="w-full py-1.5 px-2" tag="button"-->
                        <!--                                >Monthly-->
                        <!--                            </Tab>-->
                        <!--                        </TabList>-->
                        <div class="ml-4 w-full flex">
                            <div class="flex items-center">
                                <div
                                    class="w-2 h-2 bg-success rounded-full mr-3"
                                ></div>
                                <span class="truncate">Client</span>
                                <span class="font-medium ml-auto"></span>
                            </div>
                            <div class="flex items-center ml-4">
                                <div
                                    class="w-2 h-2 bg-warning rounded-full mr-3"
                                ></div>
                                <span class="truncate">Employees</span>
                                <span class="font-medium ml-auto"></span>
                            </div>
                        </div>
                        <div class="mt-6">
                            <ReportBarChart1 :height="268" />
                        </div>
                    </div>
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
                    <ChartPendingEngagement></ChartPendingEngagement>
                </div>

                <div class="col-span-12 xl:col-span-8 mt-6 blur">
                    <div class="intro-y block sm:flex items-center h-10">
                        <h2 class="text-lg font-medium truncate mr-5">
                            Staff Locations
                        </h2>
                        <div
                            class="sm:ml-auto mt-3 sm:mt-0 relative text-slate-500"
                        >
                            <!--                            <MapPinIcon-->
                            <!--                                class="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-3 left-0"-->
                            <!--                            />-->
                            <input
                                class="form-control sm:w-56 box pl-10"
                                placeholder="Filter by city"
                                name="city"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="intro-y box p-5 mt-12 sm:mt-5">
                        <div>
                            250 Official stores in 21 countries, click the
                            marker to see location details.
                        </div>
                        <!--                        <ReportMap class="report-maps mt-5 bg-slate-200 rounded-md"/>-->
                    </div>
                </div>
                <div class="col-span-12 xl:col-span-4 mt-6 opacity-50 blur-sm">
                    <div class="intro-y flex items-center h-10 opacity-50">
                        <h2 class="text-lg font-medium truncate mr-5">
                            Pending Acknowledgments
                        </h2>
                    </div>
                    <div class="intro-y box p-5 mt-5">
                        <div class="mt-4">
                            <ReportDonutChart :height="213" />
                        </div>
                        <div class="w-52 sm:w-auto mx-auto mt-16">
                            <div class="flex items-center">
                                <div
                                    class="w-2 h-2 bg-primary rounded-full mr-3"
                                ></div>
                                <span class="truncate">Acknowledged</span>
                                <span class="font-medium ml-auto">62%</span>
                            </div>
                            <div class="flex items-center mt-4">
                                <div
                                    class="w-2 h-2 bg-warning rounded-full mr-3"
                                ></div>
                                <span class="truncate">Pending</span>
                                <span class="font-medium ml-auto">10%</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!--        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">-->
                <!--          <div class="intro-y flex items-center h-10">-->
                <!--            <h2 class="text-lg font-medium truncate mr-5">Pending Acknowledgments</h2>-->

                <!--          </div>-->
                <!--          <div class="intro-y box p-5 mt-5">-->
                <!--            <div class="mt-3">-->
                <!--              <ReportDonutChart :height="213" />-->
                <!--            </div>-->
                <!--            <div class="w-52 sm:w-auto mx-auto mt-8">-->
                <!--              <div class="flex items-center">-->
                <!--                <div class="w-2 h-2 bg-primary rounded-full mr-3"></div>-->
                <!--                <span class="truncate">Acknowledge</span>-->
                <!--                <span class="font-medium ml-auto">62%</span>-->
                <!--              </div>-->
                <!--              <div class="flex items-center mt-4">-->
                <!--                <div class="w-2 h-2 bg-warning rounded-full mr-3"></div>-->
                <!--                <span class="truncate">Pending</span>-->
                <!--                <span class="font-medium ml-auto">10%</span>-->
                <!--              </div>-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
            </div>
        </div>
        <div class="col-span-12 2xl:col-span-3">
            <div class="2xl:border-l -mb-10 pb-10">
                <div
                    class="2xl:pl-6 grid grid-cols-12 gap-x-6 2xl:gap-x-0 gap-y-6"
                >
                    <div
                        class="col-span-12 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 2xl:col-start-auto 2xl:row-start-auto -mt-1"
                    >
                        <div class="mt-8">
                            <WidgetTime
                                :timezone="'America/New_York'"
                            ></WidgetTime>
                        </div>
                        <div class="mt-8">
                            <WidgetTime :timezone="'Asia/Manila'"></WidgetTime>
                        </div>
                    </div>
                    <div
                        class="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 mt-3 2xl:mt-8"
                    >
                        <PrimeCalendar></PrimeCalendar>
                    </div>
                    <div
                        class="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-12 mt-3"
                    >
                        <PanelActivityHistory />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
