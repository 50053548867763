<script lang="ts" setup>
import { onMounted, PropType, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { isArray } from "lodash";
import moment from "moment";
// import Lucide from "@/base-components/Lucide/Lucide.vue";
import Button from "@/base-components/Button";
import { useAuthStore } from "@/stores/authStore";
import FormSlider from "@/pages/FormSlider.vue";
import FormEmployeeNonrefundable from "@/pages/forms/employees/FormEmployeeNonrefundable.vue";
import EmployeeInterface, {
    EmployeeNonrefundableHistoryInterface,
} from "@/pages/interfaces/employeesInterface";
import { useEmployeeNonrefundableHistoryStore } from "@/stores/employeeNonrefundableHistoryStore";
// import CustomIcon from "@/pages/Icons/CustomIcon.vue";
// import { Menu } from "@/base-components/Headless";
import BadgeIndicator from "@/pages/components/BadgeIndicator.vue";
import CardSkeleton from "@/pages/components/CardSkeleton.vue";
import useHelper from "@/composables/helper";

const { employee } = defineProps({
    employee: {
        type: Object as PropType<EmployeeInterface>,
        required: true,
    },
});

const emit = defineEmits(["close"]);

const loading = ref(false);
const slider = ref(false);
const selected = ref<EmployeeNonrefundableHistoryInterface>();
const AuthStore = useAuthStore();
const EmployeeNonrefundableHistoryStore =
    useEmployeeNonrefundableHistoryStore();
const route = useRoute();
const helper = useHelper();

let id: string | string[] = Array.isArray(route.params.id)
    ? route.params.id[0]
    : route.params.id;

watch(
    () => route.params.id,
    async (newId) => {
        id = Array.isArray(newId) ? newId[0] : newId;

        if (!isNaN(+id)) {
            loading.value = true;
            await EmployeeNonrefundableHistoryStore.getEmployeeNonrefundableHistories(+id);
            loading.value = false;
        }
    }
);

onMounted(async () => {
    loading.value = true;
    await EmployeeNonrefundableHistoryStore.getEmployeeNonrefundableHistories(
        +route.params.id
    );
    loading.value = false;
});

const onClickUpdate = (item: EmployeeNonrefundableHistoryInterface) => {
    selected.value = item;
    slider.value = !slider.value;
};

const onClose = () => {
    slider.value = !slider.value;
    selected.value = {} as EmployeeNonrefundableHistoryInterface;
    emit("close");
};

const isActive = (amount: EmployeeNonrefundableHistoryInterface) => {
    if (
        (!amount.effective_to && moment().isAfter(amount.effective_from)) ||
        (amount.effective_to &&
            moment().isBetween(
                amount.effective_from,
                moment(amount.effective_to).endOf("day")
            ))
    ) {
        return true;
    }
    return false;
};
</script>
<template>
    <CardSkeleton v-if="loading" />

    <div v-else class="intro-y box mt-5 lg:mt-0 mb-5">
        <div class="relative flex items-center py-3 px-4">
            <h2 class="font-medium text-base mr-auto">
                Non Refundable History
            </h2>

            <Button
                v-if="AuthStore.hasPermission('can create employee nonrefundable history')"
                id="addSalary"
                class="text-xs"
                variant="soft-secondary"
                @click="slider = !slider"
            >
                Add New
            </Button>
        </div>
        <div
            v-if="isArray(EmployeeNonrefundableHistoryStore.employeeNonrefundableHistories)"
            class="p-2 border-t border-slate-200/60 dark:border-darkmode-400"
        >
            <ul
                class="rounded-md divide-y divide-gray-200 mb-2 shadow"
                role="list"
            >
                <li
                    v-for="(
                        item, index
                    ) in EmployeeNonrefundableHistoryStore.employeeNonrefundableHistories"
                    :key="item.id"
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm relative"
                >
                    <div class="ml-2 flex-1 w-0 truncate">
                        <BadgeIndicator
                            v-if="isActive(item)"
                            title="Active"
                            class="absolute top-3 right-3"
                        />
                        <div
                            class="mt-5 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Amount</div>
                            <div class="font-bold">
                                <!-- {{ employee.client?.currency?.symbol ?? "USD" }} -->
                                {{ helper.currencyFormat(item.amount) }}
                            </div>
                        </div>

                        <div
                            class="mt-5 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Effective From:</div>
                            <div>
                                {{
                                    moment(item.effective_from).format(
                                        "YYYY-MM-DD"
                                    )
                                }}
                            </div>
                        </div>

                        <div class="flex justify-between text-gray-500 w-2/4">
                            <div>Effective To:</div>
                            <div>
                                {{
                                    item.effective_to
                                        ? moment(item.effective_to).format(
                                              "YYYY-MM-DD"
                                          )
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                    <!--
                        <Menu
                            v-if="
                                AuthStore.hasPermission(
                                    'can update employee nonrefundable history'
                                )
                            "
                            :id="`menu_${item.id}`"
                            class="top-2.5"
                        >
                            <Menu.Button :as="Button" variant="soft-secondary">
                                <CustomIcon
                                    :height="18"
                                    :icon="'CircleDots'"
                                    :width="18"
                                />
                            </Menu.Button>
                            <Menu.Items class="w-40">
                                <Menu.Item
                                    :id="`update_${index}`"
                                    @click="onClickUpdate(item)"
                                >
                                    Edit
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    -->
                </li>
            </ul>
        </div>
        <div
            v-if="
                EmployeeNonrefundableHistoryStore.employeeNonrefundableHistories
                    .length === 0
            "
            class="px-4 py-6 sm:px-6"
        >
            No non refundable history yet.
        </div>
    </div>
    <FormSlider
        :is-open="slider"
        header="Employee Non Refundable Form"
        size="md"
        @close="onClose"
    >
        <FormEmployeeNonrefundable
            :employee="employee"
            :data="selected"
            @close="onClose"
        />
    </FormSlider>
</template>
