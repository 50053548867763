import Dinero from "dinero.js";
import moment from "moment";
import { InvoiceInterface } from "@/pages/interfaces/invoiceInterface";

export default function useHelper() {
    const currencyFormat = (value: number, format: string = "0,0.00") => {
        if (!value) return Dinero({ amount: 0 }).toFormat(format);

        value = Math.round(value * 100);

        return Dinero({ amount: value }).toFormat(format);
    };

    const formatOrdinals = (n: number) => {
        const pr = new Intl.PluralRules("en-US", { type: "ordinal" });
        const suffixes = new Map([
            ["one", "st"],
            ["two", "nd"],
            ["few", "rd"],
            ["other", "th"],
        ]);
        const rule = pr.select(n);
        const suffix = suffixes.get(rule);

        return `${n}${suffix}`;
    };

    const getInvoiceStatusColor = (statusName: string): string => {
        let color = '';
        switch (statusName) {
            case 'Paid':
                color = 'green'; break;
            case 'Sent':
            case 'For Review':
                color = 'yellow'; break;
            case 'Approved':
                color = 'blue'; break;
    
            case 'Overdue':
            case 'Cancelled':
                color = 'red'; break;
    
            case 'Draft':
            default: color = 'slate'; break;
        }
    
        return color;
    };

    const isInvoiceLocked = (invoice: InvoiceInterface) => {
        let locked = false;
    
        if (invoice.invoice_status?.name == 'Paid') {
            locked = true;
        } else if (invoice.generated_via_cli) {
            const generatedDate = moment(invoice.start_date).day(import.meta.env.VITE_APP_INVOICE_BILL_DAY ?? 17);
            const restrictedDate = generatedDate.add(1, 'month');
            if (moment().isSameOrAfter(restrictedDate)) {
                locked = true;
            }
        }
    
        return locked;
    };

    return {
        currencyFormat,
        formatOrdinals,
        getInvoiceStatusColor,
        isInvoiceLocked,
    };
}
