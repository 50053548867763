<script lang="ts" setup>
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { debounce } from "lodash";
import moment from "moment-timezone";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { useEmployeeAttendanceStore } from "@/stores/employeeAttendanceStore";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import { EmployeeAttendanceInterface } from "@/pages/interfaces/employeeAttendanceInterface";
import { FormInput } from "@/base-components/Form";
import DataTableSkeleton from "@/pages/components/DataTableSkeleton.vue";

const AttendanceStore = useEmployeeAttendanceStore();
const route = useRoute();

const initialLazyParams = {
    first: 0,
    rows: 30,
    sortField: null,
    sortOrder: null,
    filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "employee.name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "employee.client.legal_business_name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        "shift.name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "employee.employee_teams": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
    },
};

const dt = ref();
const lazyParams = ref<LazyParamsInterface>({ rows: initialLazyParams.rows });
const filters = ref(initialLazyParams.filters);
const range = reactive({
    from: moment().startOf("month").tz("Asia/Manila").format("YYYY-MM-DD"),
    to: moment().endOf("month").tz("Asia/Manila").format("YYYY-MM-DD"),
});
const teamFilter = ref("");
const searchableColumns = ref([
    "employee.name",
    "employee.third_party_employee_id",
    "employee.client.legal_business_name",
    "employee.shift.name",
]);
const selectAll = ref(false);
const selectedAttendance = ref<EmployeeAttendanceInterface[]>();
const showClearFilter = ref(false);
const expandedRows = ref([]);

const loading = ref(false);
const mounting = ref(false);

const onFilter = debounce(async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
}, 500);

const onSort = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onSelectAllChange = (event: { checked: any }) => {
    selectAll.value = event.checked;

    selectedAttendance.value = selectAll.value
        ? AttendanceStore.attendances.data
        : [];
};

const onRowSelect = () => {
    selectAll.value =
        selectedAttendance.value?.length ===
        AttendanceStore.attendances.data.length;
};

const onRowUnselect = () => {
    selectAll.value = false;
};

const onRowCollapse = () => {
    console.log("onRowSelect");
};

const onRowExpand = () => {
    console.log("onRowExpand");
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableColumns.value),
        team_filter: teamFilter.value,
        employee_id: route?.params?.id,
        range,
    };

    await AttendanceStore.getAttendances(params);

    loading.value = false;
};

const resetGlobalFilter = async () => {
    showClearFilter.value = false;

    teamFilter.value = "";
    filters.value = initialLazyParams.filters;
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };

    await onDataFetch();
};

const setEndTime = (data: any) => {
    const start = moment(data.start_time);
    const end = data?.end_time ? moment(data.end_time) : null;
    const now = moment();
    if (data.shift === null) return "No Shift Assigned";
    const shift = {
        start: moment(start.format("YYYY-MM-DD") + " " + data.shift.start_time),
        end: moment(start.format("YYYY-MM-DD") + " " + data.shift.end_time),
    };

    if (end === null && now.isBefore(shift.end)) {
        return "On Shift";
    }

    if (end === null && now.isAfter(shift.end)) {
        return "No End Shift Logged";
    }

    return moment(data.end_time).format("MM-DD-YYYY HH:mm:ss");
};

const onClickTeamFilterClear = () => {
    teamFilter.value = "";

    onDataFetch();
};

onMounted(async () => {
    mounting.value = true;

    filters.value = initialLazyParams.filters;
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };

    await onDataFetch();

    mounting.value = false;
});

watch(
    () => route.params.id,
    async (newId) => {
        const id = Array.isArray(newId) ? newId[0] : newId;

        if (!isNaN(+id)) {
            mounting.value = true;

            const params = {
                dt_params: JSON.stringify(initialLazyParams),
                searchable_columns: JSON.stringify(searchableColumns.value),
                employee_id: id,
            };

            await AttendanceStore.getAttendances(params);

            mounting.value = false;
        }
    }
);
</script>

<template>
    <DataTableSkeleton v-if="mounting" />

    <template v-else>
        <div
            class="flex flex-col md:flex-row lg:flex-row w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 justify-end"
        >
            <div class="w-full md:mt-2 lg:mt-0 lg:w-56 relative text-slate-500">
                <FormInput
                    v-model="range.from"
                    :value="range.from"
                    class="w-full lg:w-56 !box"
                    type="date"
                    max="9999-12-31"
                    @change="onFilter($event)"
                />
            </div>

            <div
                class="mt-2 ml-0 md:ml-2 lg:ml-2 lg:mt-0 w-full lg:w-56 relative text-slate-500"
            >
                <FormInput
                    v-model="range.to"
                    :value="range.to"
                    class="w-full lg:w-56 !box"
                    type="date"
                    max="9999-12-31"
                    @change="onFilter($event)"
                />
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:expanded-rows="expandedRows"
            v-model:filters="filters"
            v-model:selection="selectedAttendance"
            :globalFilterFields="searchableColumns"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="initialLazyParams.rows"
            :rowsPerPageOptions="[30, 50, 100]"
            :selectAll="selectAll"
            :totalRecords="AttendanceStore.attendances.total"
            :value="AttendanceStore.attendances.data"
            class="mt-4 p-datatable-sm rounded-lg text-xs"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter($event)"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange($event)"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
            @row-collapse="onRowCollapse"
            @row-expand="onRowExpand"
        >
            <template #empty> No available data.</template>

            <template #loading> Loading data. Please wait.</template>

            <Column :expander="true" class="!min-w-0 w-0.5" />

            <Column field="employee.name" header="Employee" sortable>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by employee name"
                        type="text"
                    />
                </template>

                <template #body="{ data }">
                    {{ data.employee.name }} -
                    {{ data.employee.third_party_employee_id }}
                </template>
            </Column>
            <Column
                field="employee.client.legal_business_name"
                header="Client"
                sortable
            >
            </Column>

            <Column
                :show-add-button="false"
                :show-filter-match-modes="false"
                :show-filter-operator="false"
                field="employee.employee_teams"
                header="Teams"
                sortable
            >
                <template #body="{ data }">
                    <div v-if="data.employee.employee_teams.length > 0">
                        <div
                            v-for="team in data.employee.employee_teams"
                            :key="team.id"
                        >
                            <div v-for="t in team.team" :key="t.id">
                                {{ t.name }}
                            </div>
                        </div>
                    </div>
                    <div v-else>No Team Assigned</div>
                </template>
            </Column>

            <Column field="shift.name" header="Shift" sortable>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by shift name"
                        type="text"
                    />
                </template>
            </Column>
            <Column field="start_time" header="Start" sortable>
                <template #body="{ data }">
                    {{ moment(data.start_time).format("MM-DD-YYYY HH:mm:ss") }}
                </template>
            </Column>

            <Column field="end_time" header="End" sortable>
                <template #body="{ data }">
                    <div
                        v-if="setEndTime(data) === 'On Shift'"
                        class="text-green-800 text-center font-bold"
                    >
                        {{ setEndTime(data) }}
                    </div>

                    <div
                        v-else-if="setEndTime(data) === 'No End Shift Logged'"
                        class="text-red-800 text-center font-bold"
                    >
                        {{ setEndTime(data) }}
                    </div>

                    <div
                        v-else-if="setEndTime(data) === 'No Shift Assigned'"
                        class="text-red-800 text-center font-bold"
                    >
                        {{ setEndTime(data) }}
                    </div>

                    <div v-else>{{ setEndTime(data) }}</div>
                </template>
            </Column>

            <Column
                class="!text-center"
                field="total_hours"
                header="Total Hours"
            >
                <template #body="{ data }">
                    {{ data.total_hours }}
                </template>
            </Column>

            <template #expansion="slotProps">
                <h5 class="text-md">
                    <b class="text-md">Breaks</b>
                </h5>

                <DataTable
                    :value="slotProps.data.breaks"
                    class="mt-2 text-xs"
                    responsiveLayout="scroll"
                >
                    <Column
                        field="type.friendly_name"
                        header="Break Type"
                        sortable
                    />
                    <Column field="start_time" header="Start" sortable>
                        <template #body="{ data }">
                            {{
                                moment(data.start_time)
                                    .tz("Asia/Manila")
                                    .format("MM-DD-YYYY HH:mm:ss")
                            }}
                        </template>
                    </Column>
                    <Column field="end_time" header="End" sortable>
                        <template #body="{ data }">
                            {{
                                moment(data.end_time)
                                    .tz("Asia/Manila")
                                    .format("MM-DD-YYYY HH:mm:ss")
                            }}
                        </template>
                    </Column>
                    <Column
                        class="!text-center"
                        field="total_hours"
                        header="Total Hours"
                    >
                        <template #body="{ data }">
                            {{ data.total_hours }}
                        </template>
                    </Column>
                </DataTable>
            </template>
        </DataTable>
    </template>
</template>

<style scoped></style>
