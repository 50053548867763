<script lang="ts" setup>
import {
    defineEmits,
    defineProps,
    onMounted,
    provide,
    reactive,
    ref,
    toRefs,
    watch,
} from "vue";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { email, maxLength, minLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Contact, {
    usePointOfContactStore,
} from "../../stores/pointOfContactStore";
import { useHelperStore } from "../../stores/helperStore";
import { useRoute } from "vue-router";
import { useClientsStore } from "../../stores/clientsStore";
import _ from "lodash";
import TomSelect from "../../base-components/TomSelect";
import { FormInput, FormLabel, FormSwitch } from "../../base-components/Form";
import Button from "../../base-components/Button";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";

const ClientStore = useClientsStore();
const PocStore = usePointOfContactStore();
const HelperStore = useHelperStore();
const route = useRoute();

const id = Array.isArray(route.params.id)
    ? route.params.id[0]
    : route.params.id;

const props = defineProps({
    data: Object,
    id: Number,
});

const errorNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};

provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});

const showErrorsNotification = () => {
    errorNotificationToggle();
};

const emit = defineEmits(["status", "close"]);

onMounted(async () => {
    console.log("props.id", props.id);
    await PocStore.getRoles();

    if (HelperStore.timezones.length === 0) {
        await HelperStore.fillTimezones();
    }

    if (typeof props.id !== "undefined" && props.id !== 0) {
        getContactDetails(props.id);
        PocStore.updateState();
    } else {
        PocStore.addState();
        resetForm();
    }
});

watch(
    () => props.id,
    (value) => {
        console.log("watch form poc props");
        if (typeof props.id !== "undefined" && props.id !== 0) {
            getContactDetails(props.id);
            PocStore.updateState();
        } else {
            PocStore.addState();
            resetForm();
        }
    }
);

const formData = reactive({
    id: 0,
    first_name: "",
    last_name: "",
    title: "",
    email: "",
    phone_number: "",
    primary_poc_flag: false,
    client_notification_flag: true,
    timezone_id: "",
    points_of_contact_role_id: "1",
    client_id: 0,
});

const rules = {
    first_name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(100),
    },
    last_name: { required, minLength: minLength(1), maxLength: maxLength(100) },
    title: { required, minLength: minLength(1), maxLength: maxLength(50) },
    email: {
        required,
        email,
        minLength: minLength(1),
        maxLength: maxLength(50),
    },
    phone_number: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(50),
    },
    primary_poc_flag: {},
    client_notification_flag: { required },
    timezone_id: { required },
    points_of_contact_role_id: { required },
    client_id: { required },
};

const backendValidationErrors = ref<string[]>([]);

const getContactDetails = (id: number) => {
    const contact = PocStore.getClient(id);
    if (typeof contact !== "undefined") {
        formData.id = contact.id;
        formData.client_id = contact.client_id;
        formData.points_of_contact_role_id = String(
            contact.points_of_contact_role_id
        );
        formData.first_name = contact.first_name;
        formData.last_name = contact.last_name;
        formData.title = contact.title;
        formData.email = contact.email;
        formData.phone_number = contact.phone_number;
        formData.primary_poc_flag = contact.primary_poc_flag;
        formData.client_notification_flag = contact.client_notification_flag;
        formData.timezone_id = String(contact.timezone_id);
    }
};

const validate = useVuelidate(rules, toRefs(formData));

const submit = async (): Promise<void> => {
    validate.value.$touch();
    if (validate.value.$invalid) {
        backendValidationErrors.value = ["Incomplete or Missing required data"];
        showErrorsNotification();
    } else {
        const poc = await PocStore.save(formData as unknown as Contact).then(
            (res) => {
                console.log(" poc save response");
                console.log(res.status);
                //@ts-ignore
                if (res.status === "success") {
                    _.find(PocStore.contacts, (client) => {
                        if (client.id === formData.id) {
                            //console.log('find contact', client);
                            client.first_name = formData.first_name;
                            client.last_name = formData.last_name;
                            client.email = formData.email;
                            client.phone_number = formData.phone_number;
                            client.primary_poc_flag = formData.primary_poc_flag;
                            client.timezone_id = parseInt(formData.timezone_id);
                            client.points_of_contact_role_id = parseInt(
                                formData.points_of_contact_role_id
                            );
                        }
                    });

                    //showSuccessNotification();
                    validate.value.$reset();
                    resetForm();
                    emit("status", true);
                    closeModal();
                } else {
                    console.log(res.data);
                    backendValidationErrors.value = res.data;
                    showErrorsNotification();
                }
            }
        );
    }
};

const resetForm = () => {
    formData.id = 0;
    formData.first_name = "";
    formData.last_name = "";
    formData.title = "";
    formData.email = "";
    formData.phone_number = "";
    formData.primary_poc_flag = false;
    formData.timezone_id = "";
    formData.points_of_contact_role_id = "1";
    formData.client_id = parseInt(id);
    //
};

const closeModal = () => {
    emit("close", true);

    //emit('status', true);
};

const showErrorNotification = () => {
    console.log("showErrorNotification");
};
</script>
<template>
    <form @submit.prevent="submit">
        <div class="grid grid-cols-12 gap-2 mt-5 items-left text-left">
            <div class="intro-y col-span-12 lg:col-span-12">
                <div class="intro-y pt-5 pb-5 pl-5 pr-2">
                    <div
                        class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                    >
                        <div class="mt-3">
                            <FormLabel class="form-label" for="first_name"
                                >First Name
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="first_name"
                                v-model="formData.first_name"
                                :value="formData.first_name"
                                class="form-control w-full"
                                maxLength="100"
                                name="first_name"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.first_name.$error">
                                <div
                                    v-for="(error, index) in validate.first_name
                                        .$errors"
                                    id="error_first_name"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                        <div class="mt-3">
                            <FormLabel class="form-label" for="crud-form-1"
                                >Last Name
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >

                            <FormInput
                                id="last_name"
                                v-model="formData.last_name"
                                :value="formData.last_name"
                                class="form-control w-full"
                                maxLength="100"
                                name="last_name"
                                placeholder=""
                                type="text"
                            />

                            <template v-if="validate.last_name.$error">
                                <div
                                    v-for="(error, index) in validate.last_name
                                        .$errors"
                                    id="error_last_name"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                        <div class="mt-3">
                            <FormLabel class="form-label" for="crud-form-1"
                                >Job Title
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="title"
                                v-model="formData.title"
                                :value="formData.title"
                                class="form-control w-full"
                                maxLength="50"
                                name="title"
                                placeholder=""
                                type="text"
                            />

                            <template v-if="validate.title.$error">
                                <div
                                    v-for="(error, index) in validate.title
                                        .$errors"
                                    id="error_title"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                        <div class="mt-3">
                            <FormLabel class="form-label" for="crud-form-1"
                                >Email
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="email"
                                v-model="formData.email"
                                :value="formData.email"
                                class="form-control w-full"
                                maxLength="50"
                                name="email"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.email.$error">
                                <div
                                    v-for="(error, index) in validate.email
                                        .$errors"
                                    id="error_email"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                        <div class="mt-3">
                            <FormLabel class="form-label" for="crud-form-1"
                                >Phone Number
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="phone_number"
                                v-model="formData.phone_number"
                                :value="formData.phone_number"
                                class="form-control w-full"
                                maxLength="50"
                                name="phone_number"
                                placeholder=""
                                type="text"
                            />

                            <template v-if="validate.phone_number.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .phone_number.$errors"
                                    id="error_phone_number"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Timezone
                                <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <TomSelect
                                id="timezone_id"
                                v-model="formData.timezone_id"
                                class="w-full p-0"
                                name="timezone_id"
                            >
                                <option>Select Timezone</option>
                                <option
                                    v-for="timezone of HelperStore.timezones"
                                    :value="timezone.id"
                                >
                                    {{ timezone.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.timezone_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .timezone_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Role
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="points_of_contact_role_id"
                                v-model="formData.points_of_contact_role_id"
                                class="w-full p-0"
                                name="points_of_contact_role_id"
                            >
                                <option
                                    v-for="role of PocStore.roles"
                                    :value="role.id"
                                >
                                    {{ role.name }}
                                </option>
                            </TomSelect>
                            <template
                                v-if="validate.points_of_contact_role_id.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .points_of_contact_role_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="form-check mt-5">
                            <FormLabel
                                class="form-check-label"
                                for="vertical-form-3"
                                >Primary POC
                            </FormLabel>
                            <!-- Todo Tag: checkbox fix -->
                            <FormSwitch class="mt-2">
                                <FormSwitch.Input
                                    v-model="formData.primary_poc_flag"
                                    type="checkbox"
                                    v-bind:checked="
                                        Boolean(formData.primary_poc_flag)
                                    "
                                />
                            </FormSwitch>
                        </div>

                        <div class="form-check mt-5">
                            <FormLabel
                                class="form-check-label"
                                for="vertical-form-3"
                                >Client Notification
                            </FormLabel>
                            <!-- Todo Tag: checkbox fix -->
                            <FormSwitch class="mt-2">
                                <FormSwitch.Input
                                    v-model="formData.client_notification_flag"
                                    type="checkbox"
                                    v-bind:checked="
                                        Boolean(
                                            formData.client_notification_flag
                                        )
                                    "
                                />
                            </FormSwitch>
                        </div>

                        <FormInput
                            id="client_id"
                            v-model="formData.client_id"
                            class="form-control w-full"
                            name="client_id"
                            type="hidden"
                        />
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="">
        <div class="text-right p-2">
            <Button
                id="btn_cancel"
                class="w-32 mb-2 mr-1"
                variant="warning"
                @click="closeModal"
            >
                Cancel
            </Button>
            <Button
                id="btn_process"
                class="w-32 mb-2 mr-1"
                variant="customPrimary"
                @click="submit"
            >
                {{ PocStore.getMode }} POC
            </Button>
        </div>
    </div>

    <!-- BEGIN: Success Notification Content -->
    <div id="success-notification-content" class="toastify-content hidden flex">
        <!--        <CheckCircleIcon class="text-success"/>-->
        <div class="ml-4 mr-4">
            <div class="font-medium">Success</div>
            <div class="text-slate-500 mt-1">
                Contact was saved successfully
            </div>
        </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
    <!-- BEGIN: Failed Notification Content -->
</template>
