<script lang="ts" setup>
import { useRouter } from "vue-router";
import Button from "@/base-components/Button";

const router = useRouter();

const props = defineProps({
    btnText: {
        type: String,
        default: "Go back",
    },
    btnRoute: {
        type: String,
        default: "dashboard",
    },
});

const redirect = () => {
    router.push({ name: props.btnRoute });
};
</script>
<template>
    <div>
        <div class="container">
            <div
                class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
            >
                <div class="-intro-x lg:mr-20">
                    <img
                        alt="Remote Employee"
                        class="h-48 lg:h-auto"
                        src="../../../images/error-illustration.svg"
                    />
                </div>
                <div class="text-bg-custom-color-1000 mt-10 lg:mt-0">
                    <div class="intro-x text-8xl font-medium">404</div>
                    <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
                        Oops. This page has gone missing.
                    </div>
                    <div class="intro-x text-lg mt-3">
                        You may have mistyped the address or the page may have
                        moved.
                    </div>
                    <Button
                        class="mt-5"
                        size="lg"
                        variant="customPrimary"
                        @click="redirect"
                    >
                        {{ props.btnText }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>
