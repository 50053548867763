<script lang="ts" setup>
import {useAuthStore} from "../../stores/authStore";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import moment from "moment";
import {useRoute} from "vue-router";

import {useHelperStore} from "../../stores/helperStore";
import {useUiStore} from "../../stores/uiStore";
import {defineEmits, onUpdated, provide, reactive, ref, toRefs} from "vue";
import {useFreskdeskStore} from "../../stores/freshdeskStore";
import {useTicketsStore} from "../../stores/ticketsStore";

import router from "../../router";
import Litepicker from "../../base-components/Litepicker";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import {ClassicEditor} from "../../base-components/Ckeditor";
import {FormInput, FormLabel} from "../../base-components/Form";
import ReadOnlyFieldTeamLeaders from "../components/ReadOnlyFieldTeamLeaders.vue";
import FieldApprover from "../components/FieldApprover.vue";
import Notification, {NotificationElement,} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import _ from "lodash";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});

const TicketsStore = useTicketsStore();

const AuthStore = useAuthStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const FreshdeskStore = useFreskdeskStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);
const emit = defineEmits(["output", "ticketType"]);

const ticketForm = reactive({
    supervisor: "",
    department: "",
    hours: "",
    overtimeType: "Holiday OT",
    dateFiled: "",
    employeeId: "",
    name: "",
    datesRequested: "",
    reason: "",
    requestStatus: "Pending",
    type: "Request",
    teamLeaders: "",
    approver: "",
});

const rules = {
    //supervisor: { required },
    department: {required},
    hours: {required},
    overtimeType: {required},
    //dateFiled: { required },
    //employeeId: { required },
    //name: { required },
    datesRequested: {required},
    reason: {required},
    requestStatus: {required},
    approver: {required},
    type: {required},
};

const attachment_files = reactive({
    data: [],
});

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const setTeamLeaders = (event: any) => {
    ticketForm.teamLeaders = event;
};

const setApprover = (event: any) => {
    ticketForm.approver = event;
};

const approverName = ref("");
const setApproverName = (event: any) => {
    console.log("setApproverName");
    console.log(event);
    approverName.value = event;
};

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.employeeId = "";
    ticketForm.supervisor = "";
    ticketForm.department = "";
    ticketForm.hours = "";
    ticketForm.overtimeType = "";
    ticketForm.name = "";
    ticketForm.reason = "";
    ticketForm.datesRequested = "";
    ticketForm.requestStatus = "";
    ticketForm.type = "";
};

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notifications");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Approver", approverName.value);
        desc_data.append("approver_id", ticketForm.approver);
        desc_data.append("Team Leaders", ticketForm.teamLeaders);
        desc_data.append("Department", ticketForm.department);
        desc_data.append("Number of hours", ticketForm.hours);
        desc_data.append("Type of overtime", ticketForm.overtimeType);
        desc_data.append("Dates Requested", ticketForm.datesRequested);
        desc_data.append("Reason", ticketForm.reason);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        form.append("subject", "Overtime Request:" + AuthStore.authUser.name);
        form.append("priority", ticketType?.f_priority_id?.toString() ?? '');
        form.append("status", ticketType?.f_status_id?.toString() ?? '');
        form.append("type", ticketType?.f_type?.toString() ?? '');
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? '');
        form.append("approver_id", ticketForm.approver);

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        await TicketsStore.save(form).then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", {render: true, type: "success"});
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: {id: response.data.id},
                });
            } else {
                if(response.error?.errors) {
                    attachment_errors.value = response.error?.errors['attachments.0']
                }
                if(response.error.error) {
                    attachment_error.value = response.error?.error
                }
                console.log("response not success:", response);
                // emit("output", { render: true, type: "danger" });
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});

interface FileEvent extends Event {
    target: HTMLInputElement & EventTarget & { files: FileList };
}

const attachment_errors = ref<any>({});
const attachment_error = ref<any>(null);
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        // attachments.data.push(Array.from(files));
        const fileArray = Array.from(files);
        attachments.data = [fileArray];
    }
    attachment_errors.value = ""
    attachment_error.value = ""
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <ReadOnlyFieldTeamLeaders @item-value="setTeamLeaders"/>
        <FieldApprover
            :validate="validate"
            @itemName="setApproverName"
            @itemValue="setApprover"
        />
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Department
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="department"
                v-model="ticketForm.department"
                class="form-control w-full"
                name="department"
                placeholder=""
                type="text"
            />
            <template v-if="validate.department.$error">
                <div
                    v-for="(error, index) in validate.department.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Number of hours
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <FormInput
                id="hours"
                v-model="ticketForm.hours"
                class="form-control w-full"
                name="hours"
                placeholder=""
                type="number"
            />
            <template v-if="validate.hours.$error">
                <div
                    v-for="(error, index) in validate.hours.$errors"
                    id="error_fiscalYear"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-2"
            >Type of Overtime
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="overtimeType"
                v-model="ticketForm.overtimeType"
                class="w-full"
                name="overtimeType"
            >
                <option value="Holiday OT">Holiday OT</option>
                <option value="Rest Day OT (RDOT)">Rest Day OT (RDOT)</option>
                <option value="Regular Shift OT">Regular Shift OT</option>
            </TomSelect>
            <template v-if="validate.overtimeType.$error">
                <div
                    v-for="(error, index) in validate.overtimeType.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
            >Dates Requested
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <Litepicker
                id="datesRequested"
                v-model="ticketForm.datesRequested"
                :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                class="form-control w-full"
                name="datesRequested"
            />
            <template v-if="validate.datesRequested.$error">
                <div
                    v-for="(error, index) in validate.datesRequested.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel
            >Reason <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="reason"
                    v-model="ticketForm.reason"
                    :config="editorConfig"
                    name="reason"
                />
                <template v-if="validate.reason.$error">
                    <div
                        v-for="(error, index) in validate.reason.$errors"
                        id="error_reason"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3 sm:col-span-2">
            <FormLabel
                class="form-label inline-block mb-2 text-gray-700"
                for="message"
            >Attachment
            </FormLabel>
            <div class="mt-1">
                <input
                    class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    multiple
                    type="file"
                    @change="uploadFile($event)"
                />
            </div>
            <template v-if="attachment_error">
                <div
                    id="error_reason"
                    class="text-danger mt-2"
                >
                    {{ attachment_error }}
                </div>
            </template>
            <template v-if="attachment_errors">
                <div
                    v-for="(error, index) in attachment_errors"
                    id="error_reason"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error }}
                </div>
            </template>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning shadow-sm py-2 px-3
                    inline-flex items-center justify-center
                    rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
            >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle"/>
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
