import { defineStore } from "pinia";
import Http from "@/services/Http";
import moment from "moment";
import { EmployeeCostHistoryInterface } from "@/pages/interfaces/employeesInterface";
import ApiResponseInterface from "@/pages/interfaces/apiResponseInterface";

export const useEmployeeCostHistoryStore = defineStore(
    "EmployeeCostHistoryStore",
    {
        state: (): {
            employeeCostHistories: EmployeeCostHistoryInterface[];
            employeeCostHistory: EmployeeCostHistoryInterface;
        } => {
            return {
                employeeCostHistories: [] as EmployeeCostHistoryInterface[],
                employeeCostHistory: {} as EmployeeCostHistoryInterface,
            };
        },

        actions: {
            async getEmployeeCostHistories(employeeId: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<EmployeeCostHistoryInterface[]>
                >(`/employee-cost-histories?employee_id=${employeeId}`);
                this.employeeCostHistories = data;
            },

            async getEmployeeCostHistory(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).get<
                    ApiResponseInterface<EmployeeCostHistoryInterface>
                >(`/employee-cost-histories/${id}`);

                this.employeeCostHistory = data;
            },

            async addEmployeeCostHistory(
                payload: EmployeeCostHistoryInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).post<
                    ApiResponseInterface<EmployeeCostHistoryInterface>
                >("/employee-cost-histories", payload);

                let inOrder = true;
                const hasItems = this.employeeCostHistories.length > 0;
                const newEffectiveFrom = moment(data.effective_from);
                if (hasItems) {
                    const latestAmount = this.employeeCostHistories[0];
                    if (latestAmount.effective_to == null && newEffectiveFrom.isAfter(latestAmount.effective_from)) {
                        latestAmount.effective_to = newEffectiveFrom.subtract(1, 'day').format('YYYY-MM-DD');
                        this.employeeCostHistories.splice(0, 1, latestAmount);
                    } else if (newEffectiveFrom.isBefore(latestAmount.effective_from)) {
                        inOrder = false;
                        this.employeeCostHistories.splice(0, 1, data);
                        this.employeeCostHistories.unshift(latestAmount);
                    }
                }
                if (inOrder) {
                    this.employeeCostHistories.unshift(data);
                }
            },

            async updateEmployeeCostHistory(
                payload: EmployeeCostHistoryInterface
            ): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                const { data } = await new Http(baseUrl).put<
                    ApiResponseInterface<EmployeeCostHistoryInterface>
                >(`/employee-cost-histories/${payload.id}`, payload);

                const index = this.employeeCostHistories.findIndex(
                    (item) => item.id === data.id
                );
                this.employeeCostHistories.splice(index, 1, data);
            },

            async deleteEmployeeCostHistory(id: number): Promise<void> {
                const baseUrl = import.meta.env.VITE_API_URL;

                await new Http(baseUrl).delete(
                    `/employee-cost-histories/${id}`
                );

                const index = this.employeeCostHistories.findIndex(
                    (item) => item.id === id
                );

                this.employeeCostHistories.splice(index, 1);
            },
        },
    }
);
