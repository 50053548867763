<script lang="ts" setup>
import { useRoute } from "vue-router";
import {
    defineEmits,
    onMounted,
    PropType,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import dayjs from "dayjs";
import { useVuelidate } from "@vuelidate/core";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import {
    FormInput,
    FormLabel,
    FormSwitch,
    InputGroup,
} from "@/base-components/Form";
import { required, requiredIf } from "@vuelidate/validators";
import TomSelect from "@/base-components/TomSelect";
import Button from "@/base-components/Button";
import Lucide from "@/base-components/Lucide";
import { useClientInvoiceConfigStore } from "@/stores/clientInvoiceConfigStore";
import { ClientInvoiceConfigInterface } from "@/pages/interfaces/clientInvoiceConfigInterface";
import ClientInterface from "@/pages/interfaces/clientsInterface";
import SliderFormSkeleton from "@/pages/components/SliderFormSkeleton.vue";
import SubmitButton from "@/pages/components/SubmitButton.vue";

const ClientInvoiceConfigStore = useClientInvoiceConfigStore();

const loading = ref(true);
const processing = ref(false);
const route = useRoute();
const emit = defineEmits(["close"]);
const props = defineProps({
    config: {
        type: Object,
        default: {} as ClientInvoiceConfigInterface,
    },
    client: {
        type: Object as PropType<ClientInterface>,
        required: true,
    },
});

const form = reactive({
    id: 0,
    client_id: +route?.params?.id ?? 0,
    setup_fee: 0,
    re_fee: 100,
    start_date: dayjs().format("YYYY-MM-DD"),
    end_date: "",
    bill_period: "",
    bill_day: 1,
    bill_type: "",
    bill_hour: "00:00:00",
    invoice_day: "",
    net_terms: 0,
    seats: 1,
    auto_generate_next_invoice: false,
    is_active: false,
    email_to: "",
});
const externalServerValidation = () => true;
const rules = {
    client_id: { externalServerValidation },
    setup_fee: { externalServerValidation },
    re_fee: { externalServerValidation },
    start_date: { externalServerValidation },
    end_date: { externalServerValidation },
    bill_period: { externalServerValidation },
    bill_day: { externalServerValidation },
    bill_hour: { externalServerValidation },
    bill_type: { externalServerValidation },
    invoice_day: { externalServerValidation },
    net_terms: { externalServerValidation },
    seats: { externalServerValidation },
    email_to: { externalServerValidation },
    is_active: { externalServerValidation },
};

const backendValidationErrors = ref({});
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();

const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const close = () => {
    form.client_id = 0;
    form.setup_fee = 100;
    form.re_fee = 100;
    form.start_date = dayjs().format("YYYY-MM-DD");
    form.end_date = "";
    form.bill_period = "";
    form.bill_day = 1;
    form.bill_hour = "00:00:00";
    form.bill_type = "";
    form.invoice_day = "";
    form.net_terms = 1;
    form.seats = 1;
    form.auto_generate_next_invoice = false;
    form.is_active = false;
    form.email_to = "";

    emit("close");
};

const onChangeStartDate = (event: Event) => {
    form.start_date = (event.target as HTMLInputElement).value;
};

const onChangeEndDate = (event: Event) => {
    form.end_date = (event.target as HTMLInputElement).value;
};

const onSubmit = async () => {
    processing.value = true;
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        processing.value = false;
        return;
    }

    try {
        props?.config?.id
            ? await ClientInvoiceConfigStore.updateClientInvoiceConfig(form)
            : await ClientInvoiceConfigStore.addClientInvoiceConfig(form);

        close();
        showSuccessNotification();
    } catch (error: any) {
        backendValidationErrors.value = error?.response?.data?.errors;
        $externalResults.value = error?.response?.data?.errors;
        showErrorsNotification();
    } finally {
        processing.value = false;
    }
};

onMounted(() => {
    const { config } = toRefs(props);

    if (config?.value?.id) {
        form.id = config.value.id;
        form.client_id = config.value.client_id;
        form.setup_fee = config.value.setup_fee;
        form.re_fee = config.value.re_fee;
        form.start_date = dayjs(config.value.start_date).format("YYYY-MM-DD");
        form.end_date =
            config.value.end_date !== null
                ? dayjs(config.value.end_date).format("YYYY-MM-DD")
                : "";
        form.bill_period = config.value.bill_period;
        form.bill_day = config.value.bill_day;
        form.bill_hour = config.value.bill_hour;
        form.bill_type = config.value.bill_type ?? "";
        form.invoice_day = config.value.invoice_day ?? "";
        form.net_terms = config.value.net_terms ?? 0;
        form.seats = config.value.seats ?? 0;
        form.auto_generate_next_invoice =
            config.value.auto_generate_next_invoice;
        form.is_active = config.value.is_active;
        form.email_to = config.value.email_to ?? "";
    }

    loading.value = false;
});
</script>

<template>
    <SliderFormSkeleton v-if="loading" />
    <form v-else @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y p-5">
                    <div>
                        <FormLabel class="form-label" for="setup_fee">
                            Setup Fee <span class="text-red-500">*</span>
                        </FormLabel>

                        <InputGroup>
                            <FormInput
                                id="setup_fee"
                                v-model.number="form.setup_fee"
                                :value="form.setup_fee"
                                class="form-control w-full"
                                type="number"
                            />
                            <InputGroup.Text v-if="client?.currency">
                                {{ client?.currency?.code }}
                            </InputGroup.Text>
                        </InputGroup>
                        <template v-if="validate.setup_fee.$error">
                            <div
                                v-for="(error, index) in validate.setup_fee
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="re_fee">
                            RE Fee <span class="text-red-500">*</span>
                        </FormLabel>

                        <InputGroup>
                            <FormInput
                                id="re_fee"
                                v-model.number="form.re_fee"
                                :value="form.re_fee"
                                class="form-control w-full"
                                type="number"
                            />
                            <InputGroup.Text v-if="client?.currency">
                                {{ client?.currency?.code }}
                            </InputGroup.Text>
                        </InputGroup>
                        <template v-if="validate.re_fee.$error">
                            <div
                                v-for="(error, index) in validate.re_fee
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="start_date">
                            Start Date <span class="text-red-500">*</span>
                        </FormLabel>

                        <FormInput
                            id="start_date"
                            v-model="form.start_date"
                            :value="form.start_date"
                            class="form-control w-full"
                            name="start_date"
                            placeholder=""
                            type="date"
                            max="9999-12-31"
                            @change="onChangeStartDate($event)"
                        />
                        <template v-if="validate.start_date.$error">
                            <div
                                v-for="(error, index) in validate.start_date
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="end_date">
                            End Date
                        </FormLabel>

                        <FormInput
                            id="end_date"
                            v-model="form.end_date"
                            :min="
                                dayjs(form.start_date)
                                    .add(1, 'days')
                                    .format('YYYY-MM-DD')
                            "
                            :value="form.end_date"
                            class="form-control w-full"
                            name="end_date"
                            placeholder=""
                            type="date"
                            max="9999-12-31"
                            @change="onChangeEndDate($event)"
                        />
                        <template v-if="validate.end_date.$error">
                            <div
                                v-for="(error, index) in validate.end_date
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label">
                            Bill Type <span class="text-red-600">*</span>
                        </FormLabel>
                        <div>
                            <TomSelect
                                id="bill_type"
                                v-model="form.bill_type"
                                :value="form.bill_type"
                                class="w-full p-0"
                                name="bill_type"
                            >
                                <option selected>Select Bill Type</option>
                                <option value="adjustment">
                                    Adjustment Only
                                </option>
                                <option value="monthly">Monthly Cycle</option>
                                <option value="combined">
                                    Monthly w/ Adjustments
                                </option>
                            </TomSelect>
                        </div>
                        <template v-if="validate.bill_type.$error">
                            <div
                                v-for="(error, index) in validate.bill_type
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label"
                            >Bill Period
                            <span class="text-red-600">*</span></FormLabel
                        >
                        <div>
                            <TomSelect
                                id="bill_period"
                                v-model="form.bill_period"
                                :value="form.bill_period"
                                class="w-full p-0"
                                name="bill_period"
                            >
                                <option selected>Select Bill Period</option>
                                <option value="current_month">
                                    Current Month
                                </option>
                                <option value="future_month">
                                    Future Month
                                </option>
                            </TomSelect>
                        </div>
                        <template v-if="validate.bill_period.$error">
                            <div
                                v-for="(error, index) in validate.bill_period
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="bill_day">
                            Bill Day <span class="text-red-500">*</span>
                        </FormLabel>

                        <FormInput
                            id="bill_day"
                            v-model.number="form.bill_day"
                            :value="form.bill_day"
                            class="form-control w-full"
                            max="31"
                            min="1"
                            name="bill_day"
                            placeholder=""
                            type="number"
                        />
                        <template v-if="validate.bill_day.$error">
                            <div
                                v-for="(error, index) in validate.bill_day
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="invoice_day">
                            Invoice Day
                        </FormLabel>

                        <FormInput
                            id="invoice_day"
                            v-model.number="form.invoice_day"
                            :value="form.invoice_day"
                            class="form-control w-full"
                            max="31"
                            min="1"
                            name="invoice_day"
                            placeholder=""
                            type="number"
                        />
                        <template v-if="validate.invoice_day.$error">
                            <div
                                v-for="(error, index) in validate.invoice_day.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="net_terms">
                            Net Terms <span class="text-red-500">*</span>
                        </FormLabel>

                        <FormInput
                            id="net_terms"
                            v-model.number="form.net_terms"
                            :value="form.net_terms"
                            name="net_terms"
                            placeholder=""
                            type="number"
                            min="0"
                        />
                        <template v-if="validate.net_terms.$error">
                            <div
                                v-for="(error, index) in validate.net_terms.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="seats">
                            Seats <span class="text-red-500">*</span>
                        </FormLabel>

                        <FormInput
                            id="seats"
                            v-model.number="form.seats"
                            :value="form.seats"
                            class="form-control w-full"
                            min="1"
                            name="seats"
                            placeholder=""
                            type="number"
                        />
                        <template v-if="validate.seats.$error">
                            <div
                                v-for="(error, index) in validate.seats.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="email_to"
                            >Email To <span class="text-red-500">*</span>
                        </FormLabel>

                        <FormInput
                            id="email_to"
                            v-model.number="form.email_to"
                            :value="form.email_to"
                            class="form-control w-full"
                            name="email_to"
                            type="text"
                        />
                        <template v-if="validate.email_to.$error">
                            <div
                                v-for="(error, index) in validate.email_to.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-4">
                        <div
                            class="mt-4 flex flex-row align-center items-center"
                        >
                            <!-- <input
                                id="auto_generate_next_invoice"
                                type="checkbox"
                                v-model="form.auto_generate_next_invoice"
                                :checked="form.auto_generate_next_invoice"
                            /> -->
                            <FormSwitch>
                                <FormSwitch.Input
                                    v-model="form.auto_generate_next_invoice"
                                    type="checkbox"
                                    v-bind:checked="form.auto_generate_next_invoice"
                                />
                            </FormSwitch>

                            <label
                                class="ml-2"
                                for="auto_generate_next_invoice"
                            >
                                Auto Generate Next Invoice?
                            </label>
                        </div>
                    </div>

                    <div class="mt-4">
                        <div
                            class="mt-4 flex flex-row align-center items-center"
                        >
                            <!-- <input
                                id="is_active"
                                type="checkbox"
                                v-model="form.is_active"
                                :checked="form.is_active"
                            /> -->
                            <FormSwitch>
                                <FormSwitch.Input
                                    v-model="form.is_active"
                                    type="checkbox"
                                    v-bind:checked="form.is_active"
                                />
                            </FormSwitch>
                            <label class="ml-2" for="is_active">Active?</label>
                        </div>
                        <template v-if="validate.is_active.$error">
                            <div
                                v-for="(error, index) in validate.is_active.$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    @click="close()"
                >
                    Cancel
                </Button>
                <SubmitButton
                    id="btn_process"
                    class="w-36 mb-2 mr-1"
                    type="submit"
                    variant="customPrimary"
                    :label="form.id ? 'Update' : 'Create'"
                    :processingLabel="form.id ? 'Updating...' : 'Creating...'"
                    :isProcessing="processing"
                />
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Invoice config has been successfully {{ form.id ? "updated" : "added" }}.
            </div>
        </div>
    </Notification>

    <Notification
        v-if="
            backendValidationErrors &&
            Object.keys(backendValidationErrors).length
        "
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
