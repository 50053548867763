<script lang="ts" setup>
import router from "../../../router";
import CustomIcon from "../../Icons/CustomIcon.vue";
import { useFreskdeskStore } from "../../../stores/freshdeskStore";

const FreshdeskStore = useFreskdeskStore();
const routeClick = () => {
    router.push({ name: "myTickets" });
};
</script>
<template>
    <div class="report-box zoom-in">
        <div class="box p-5" @click="routeClick()">
            <div class="flex">
                <CustomIcon :height="24" :icon="'Bell'" :width="24" />
                <div class="ml-auto"></div>
            </div>
            <div class="text-3xl text-red-500 font-medium leading-8 mt-6">
                {{ FreshdeskStore.overdue }}
            </div>
            <div class="text-md text-slate-500 mt-1">Overdue Tickets</div>
        </div>
    </div>
</template>
