<script lang="ts" setup>
import { onMounted, provide, reactive, ref } from "vue";
import moment from "moment-timezone";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { useAuthStore } from "@/stores/authStore";
import { useClientsStore } from "@/stores/clientsStore";
import { useEmployeeAttendanceStore } from "@/stores/employeeAttendanceStore";
import { usePayrollConfigurationStore } from "@/stores/payrollConfigurationStore";
import { useTeamStore } from "@/stores/teamStore";
import { EmployeeAttendanceInterface } from "@/pages/interfaces/employeeAttendanceInterface";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import Button from "@/base-components/Button";
import { FormInput } from "@/base-components/Form";
import Lucide from "@/base-components/Lucide";
import Notification, { NotificationElement } from "@/base-components/Notification";
import FormSlider from "@/pages/FormSlider.vue";
import FormAttendance from "@/pages/forms/attendance/FormAttendance.vue";
import ConfirmModal from "@/pages/components/ConfirmModal.vue";
import PageHeaderStandard from "@/pages/components/PageHeaderStandard.vue";

const AuthStore = useAuthStore();
const AttendanceStore = useEmployeeAttendanceStore();
const ClientsStore = useClientsStore();
const TeamStore = useTeamStore();
const PayrollConfigurationStore = usePayrollConfigurationStore();

const initialLazyParams = {
    first: 0,
    rows: 30,
    sortField: null,
    sortOrder: null,
    filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "employee.name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "employee.client.legal_business_name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        "shift.name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "employee.employee_teams": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
    }
};

const attendance = ref<EmployeeAttendanceInterface>();
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();

const slider = ref(false);
const onClickButton = (data?: EmployeeAttendanceInterface) => {
    console.log("onClickButton");
    slider.value = !slider.value;

    if (data && Object.keys(data).length > 0) {
        attendance.value = data;
    } else {
        attendance.value = {} as EmployeeAttendanceInterface;
    }
    console.log(attendance.value);
};

const showConfirmDialog = ref(false);
const deleteId = ref(0);
const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};
const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};
const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        AttendanceStore.deleteAttendance(deleteId.value).then(() => {
            deleteId.value = 0;
        });
    }
};
const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const dt = ref();
const lazyParams = ref<LazyParamsInterface>({ rows: initialLazyParams.rows });
const filters = ref(initialLazyParams.filters);
const range = reactive({
    from: moment().startOf("month").tz("Asia/Manila").format("YYYY-MM-DD"),
    to: moment().endOf("month").tz("Asia/Manila").format("YYYY-MM-DD"),
});
const teamFilter = ref("");
const searchableColumns = ref([
    "employee.name",
    "employee.third_party_employee_id",
    "employee.client.legal_business_name",
    "employee.shift.name",
]);
const selectAll = ref(false);
const selectedAttendance = ref<EmployeeAttendanceInterface[]>();
const showClearFilter = ref(false);
const expandedRows = ref([]);

const loading = ref(false);
const exporting = ref(false);
const sproutExporting = ref(false);

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onSelectAllChange = (event: { checked: any }) => {
    selectAll.value = event.checked;

    selectedAttendance.value = selectAll.value
        ? AttendanceStore.attendances.data
        : [];
};

const onRowSelect = () => {
    selectAll.value =
        selectedAttendance.value?.length ===
        AttendanceStore.attendances.data.length;
};

const onRowUnselect = () => {
    selectAll.value = false;
};

const onRowCollapse = () => {
    console.log("onRowSelect");
};

const onRowExpand = () => {
    console.log("onRowExpand");
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableColumns.value),
        range,
        team_filter: teamFilter.value,
    };

    await AttendanceStore.getAttendances(params);

    loading.value = false;
};

const resetGlobalFilter = async () => {
    showClearFilter.value = false;

    range.from = moment().startOf("month").tz("Asia/Manila").format("YYYY-MM-DD");
    range.to = moment().endOf("month").tz("Asia/Manila").format("YYYY-MM-DD");

    teamFilter.value = "";
    filters.value = initialLazyParams.filters;
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };

    await onDataFetch();
};

const setEndTime = (data: any) => {
    const start = moment(data.start_time);
    const end = data?.end_time ? moment(data.end_time) : null;
    const now = moment();
    const shift = {
        start: moment(start.format("YYYY-MM-DD") + " " + data.shift.start_time),
        end: moment(start.format("YYYY-MM-DD") + " " + data.shift.end_time),
    };

    if (end === null && now.isBefore(shift.end)) {
        return "On Shift";
    }

    if (end === null && now.isAfter(shift.end)) {
        return "No End Shift Logged";
    }

    return moment(data.end_time).format("MM-DD-YYYY HH:mm:ss");
};

const onClickTeamFilterClear = () => {
    teamFilter.value = "";

    onDataFetch();
};

onMounted(async () => {
    (window as any).Echo.channel("employee-attendance-export-channel").listen(
        "EmployeeAttendanceExportComplete",
        async (e: any) => {
            console.log(e);

            try {
                await AttendanceStore.downloadAttendance({ range });
            } catch (e) {
                showErrorsNotification();
            } finally {
                exporting.value = false;
            }
        }
    );

    filters.value = initialLazyParams.filters;
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };

    await ClientsStore.getAllClients();
    await TeamStore.getAllTeams();
    await PayrollConfigurationStore.getCurrentPayrollConfiguration({
        current: true,
    });

    range.from = moment(
        PayrollConfigurationStore.payrollConfiguration.start_date
    )
        .startOf("day")
        .tz("Asia/Manila")
        .format("YYYY-MM-DD");
    range.to = moment(PayrollConfigurationStore.payrollConfiguration.end_date)
        .endOf("day")
        .tz("Asia/Manila")
        .format("YYYY-MM-DD");

    await onDataFetch();
});

const exportAttendance = async (type?: string) => {
    if (type === "sprout") {
        sproutExporting.value = true;
    } else {
        exporting.value = true;
    }

    try {
        await AttendanceStore.exportAttendance({
            range,
            type,
        });

        showSuccessNotification();
    } catch (e) {
        showErrorsNotification();
    } finally {
        exporting.value = false;
        sproutExporting.value = false;
    }
};

const withinPayrollPeriod = (start: string): boolean => {
    if (!PayrollConfigurationStore.payrollConfiguration.lock_in_date) {
        return false;
    }

    const payrollStartDate = moment(
        PayrollConfigurationStore.payrollConfiguration.start_date
    ).tz("Asia/Manila");
    const lockInDate = moment(
        PayrollConfigurationStore.payrollConfiguration.lock_in_date
    ).tz("Asia/Manila");

    const startDate = moment(start).tz("Asia/Manila");

    return startDate.isBetween(payrollStartDate, lockInDate);
};
</script>

<template>
    <div class="w-full">
        <PageHeaderStandard title="Employees Attendance" />

        <div class="intro-y col-span-12 flex flex-wrap items-center mt-2">
            <Button
                v-if="AuthStore.hasPermission('can create attendance')"
                id="createAttendanceButton"
                class="flex-auto lg:flex-none mr-2 shadow-sm"
                variant="customPrimary"
                @click="onClickButton()"
            >
                New Attendance
            </Button>
            <Button
                v-if="AuthStore.hasPermission('can create attendance')"
                :disabled="exporting"
                class="flex-auto lg:flex-none mr-2 shadow-sm"
                variant="customPrimary"
                @click="exportAttendance()"
            >
                <template v-if="exporting">
                    <div>
                        <svg
                            aria-hidden="true"
                            class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            fill="none"
                            viewBox="0 0 100 101"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
                    Exporting...
                </template>

                <span v-else>Export</span>
            </Button>

            <Button
                v-if="AuthStore.hasPermission('can create attendance')"
                :disabled="sproutExporting"
                class="flex-auto lg:flex-none mr-2 shadow-sm"
                variant="customPrimary"
                @click="exportAttendance('sprout')"
            >
                <template v-if="sproutExporting">
                    <div>
                        <svg
                            aria-hidden="true"
                            class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            fill="none"
                            viewBox="0 0 100 101"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                    </div>
                    Exporting for Sprout...
                </template>

                <span v-else>Export for Sprout</span>
            </Button>

            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div
                class="flex flex-col md:flex-row lg:flex-row w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0"
            >
                <template v-if="showClearFilter">
                    <Button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </Button>
                </template>

                <div
                    class="w-full md:mt-2 lg:mt-0 lg:w-56 relative text-slate-500"
                >
                    <FormInput
                        v-model="range.from"
                        :value="range.from"
                        class="w-full lg:w-56 !box"
                        type="date"
                        max="9999-12-31"
                        @change="onFilter($event)"
                    />
                </div>

                <div
                    class="mt-2 ml-0 md:ml-2 lg:ml-2 lg:mt-0 w-full lg:w-56 relative text-slate-500"
                >
                    <FormInput
                        v-model="range.to"
                        :value="range.to"
                        class="w-full lg:w-56 !box"
                        type="date"
                        max="9999-12-31"
                        @change="onFilter($event)"
                    />
                </div>

                <div
                    class="mt-2 ml-0 md:ml-2 lg:mt-0 lg:ml-2 w-full lg:w-56 relative text-slate-500"
                >
                    <FormInput
                        id="global-search"
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-full lg:w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter($event)"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:expanded-rows="expandedRows"
            v-model:filters="filters"
            v-model:selection="selectedAttendance"
            :globalFilterFields="searchableColumns"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="initialLazyParams.rows"
            :rowsPerPageOptions="[30, 50, 100]"
            :selectAll="selectAll"
            :totalRecords="AttendanceStore.attendances.total"
            :value="AttendanceStore.attendances.data"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter($event)"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange($event)"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
            @row-collapse="onRowCollapse"
            @row-expand="onRowExpand"
        >
            <template #empty> No available data.</template>

            <template #loading> Loading data. Please wait.</template>

            <Column :expander="true" class="!min-w-0 w-0.5" />

            <Column class="w-0.5 text-center" selectionMode="multiple"></Column>

            <Column field="employee.name" header="Employee" sortable>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by employee name"
                        type="text"
                    />
                </template>

                <template #body="{ data }">
                    {{ data.employee.name }} -
                    {{ data.employee.third_party_employee_id }}
                </template>
            </Column>
            <Column
                field="employee.client.legal_business_name"
                header="Client"
                sortable
            >
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="ClientsStore.clients"
                        option-label="legal_business_name"
                        option-value="legal_business_name"
                        placeholder="Select Client Business Name"
                    />
                </template>
            </Column>

            <Column
                :show-add-button="false"
                :show-filter-match-modes="false"
                :show-filter-operator="false"
                field="employee.employee_teams"
                header="Teams"
                sortable
            >
                <template #filter>
                    <Dropdown
                        v-model="teamFilter"
                        :options="TeamStore.teams"
                        option-label="name"
                        option-value="name"
                        placeholder="Select Team"
                    />
                </template>

                <template #filterclear>
                    <Button
                        class="border border-[#2196f3] text-[#2196f3] py-1.5"
                        @click="onClickTeamFilterClear"
                        >Clear
                    </Button>
                </template>

                <template #body="{ data }">
                    <div v-if="data.employee.employee_teams.length > 0">
                        <div
                            v-for="team in data.employee.employee_teams"
                            :key="team.id"
                        >
                            <div v-for="t in team.team" :key="t.id">
                                {{ t.name }}
                            </div>
                        </div>
                    </div>
                    <div v-else>No Team Assigned</div>
                </template>
            </Column>

            <Column field="shift.name" header="Shift" sortable>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by shift name"
                        type="text"
                    />
                </template>
            </Column>
            <Column field="start_time" header="Start" sortable>
                <template #body="{ data }">
                    {{ moment(data.start_time).format("MM-DD-YYYY HH:mm:ss") }}
                </template>
            </Column>

            <Column field="end_time" header="End" sortable>
                <template #body="{ data }">
                    <div v-if="data.end_time === null">
                        <span
                            :class="{
                                'text-green-600':
                                    data.attendance_status === 'Clocked In',
                                'text-orange-600':
                                    data.attendance_status === 'Working Late',
                                'text-yellow-600':
                                    data.attendance_status === 'On break',
                            }"
                            class="font-bold"
                        >
                            {{ data.attendance_status }}
                        </span>
                    </div>

                    <div v-else>{{ setEndTime(data) }}</div>
                </template>
            </Column>

            <Column
                class="!text-center"
                field="total_hours"
                header="Total Hours"
            >
                <template #body="{ data }">
                    {{ data.total_hours }}
                </template>
            </Column>

            <Column>
                <template #body="{ data }">
                    <div class="flex justify-center items-center">
                        <div v-if="AuthStore.hasPermission('can update attendance')">
                            <svg
                                :id="'update_' + data.id"
                                class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="onClickButton(data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path
                                    d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                ></path>
                                <path
                                    d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                ></path>
                                <path d="M16 5l3 3"></path>
                            </svg>
                        </div>

                        <div
                            v-if="AuthStore.hasPermission('can delete attendance')"
                            class="ml-2"
                        >
                            <svg
                                :id="'delete_' + data.id"
                                class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                fill="none"
                                height="18"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="confirmDelete($event, data)"
                            >
                                <path
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                    stroke="none"
                                ></path>
                                <path d="M4 7l16 0"></path>
                                <path d="M10 11l0 6"></path>
                                <path d="M14 11l0 6"></path>
                                <path
                                    d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                ></path>
                                <path
                                    d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </template>
            </Column>

            <template #expansion="slotProps">
                <h5 class="text-md">
                    <b class="text-md">Breaks</b>
                </h5>

                <DataTable
                    :value="slotProps.data.breaks"
                    class="mt-2 text-xs"
                    responsiveLayout="scroll"
                >
                    <Column
                        field="type.friendly_name"
                        header="Break Type"
                        sortable
                    />
                    <Column field="start_time" header="Start" sortable>
                        <template #body="{ data }">
                            {{
                                moment(data.start_time)
                                    .tz("Asia/Manila")
                                    .format("MM-DD-YYYY HH:mm:ss")
                            }}
                        </template>
                    </Column>
                    <Column field="end_time" header="End" sortable>
                        <template #body="{ data }">
                            <div v-if="data.end_time !== null">
                                {{
                                    moment(data.end_time)
                                        .tz("Asia/Manila")
                                        .format("MM-DD-YYYY HH:mm:ss")
                                }}
                            </div>
                        </template>
                    </Column>
                    <Column
                        class="!text-center"
                        field="total_hours"
                        header="Total Hours"
                    >
                        <template #body="{ data }">
                            {{ data.total_hours }}
                        </template>
                    </Column>
                </DataTable>
            </template>
        </DataTable>

        <FormSlider
            :is-open="slider"
            header="Employee Attendance Form"
            size="lg"
            @close="onClickButton"
        >
            <FormAttendance :attendance="attendance" @close="onClickButton" />
        </FormSlider>

        <ConfirmModal
            :displayConfirmModal="showConfirmDialog"
            second-description="Employee attendance has been successfully deleted."
            @closeConfirmModal="setConfirmDialog(false)"
            @proceedProcess="processDelete($event)"
        />
    </div>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <li>Please check if the selected date range is valid.</li>
                </ul>
            </div>
        </div>
    </Notification>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Employee attendance has been exported successfully
            </div>
        </div>
    </Notification>
</template>
