<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import Button from "@/base-components/Button";
import Lucide from "@/base-components/Lucide";
import moment from "moment";
import { useShiftStore } from "@/stores/shiftStore";
import { FormInput } from "@/base-components/Form";
import { useTeamStore } from "@/stores/teamStore";
import TomSelect from "@/base-components/TomSelect";
import { useClientsStore } from "@/stores/clientsStore";
import { WorkSchedule } from "@/pages/interfaces/teamsInterface";
import { useAuthStore } from "@/stores/authStore";

const AuthStore = useAuthStore();

const props = defineProps({
    teamView: {
        type: Boolean,
        default: false,
    },
});

const days = ref([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
]);
const hours = ref([
    "12:00AM",
    "01:00AM",
    "02:00AM",
    "03:00AM",
    "04:00AM",
    "05:00AM",
    "06:00AM",
    "07:00AM",
    "08:00AM",
    "09:00AM",
    "10:00AM",
    "11:00AM",
    "12:00PM",
    "01:00PM",
    "02:00PM",
    "03:00PM",
    "04:00PM",
    "05:00PM",
    "06:00PM",
    "07:00PM",
    "08:00PM",
    "09:00PM",
    "10:00PM",
    "11:00PM",
]);

interface User {
    id: number;
    name: string;
    shift_description: string;
    work_days: WorkSchedule | null;
    availability: {
        [key: string]: {
            isAvailable: boolean;
            start: string;
            end: string;
            total: number;
        };
    };
}

const users = ref<User[]>([]);
const itemsPerPage = ref(5);
const currentPage = ref(1);
const lastPage = ref(1);
const showWeekView = ref(false);
const showDayView = ref(true);
const search = ref("");
const clientId = ref("");
const dayOff = ref(["Saturday", "Sunday"]);
const showClearFilter = ref(false);

const ShiftStore = useShiftStore();
const TeamStore = useTeamStore();
const ClientStore = useClientsStore();

onMounted(async () => {
    const allowedRoles = ["admin", "hr"];
    await getCurrentWeek();
    if (allowedRoles.includes(AuthStore.role[0])) {
        await ClientStore.getClientsForDropdown();
    }
});

const displayedUsers = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage.value;
    const endIndex = startIndex + itemsPerPage.value;
    return users.value.slice(startIndex, endIndex);
});

const totalPages = computed(() => {
    return Math.ceil(users.value.length / itemsPerPage.value);
});

async function nextPage() {
    if (currentPage.value < lastPage.value) {
        currentPage.value += 1;
    }

    await onDataFetch();
}

async function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value -= 1;
    }

    await onDataFetch();
}

const currentWeek = ref("");
const currentWeekFirstDay = ref("");
const currentDay = ref();
const currentDayOfTheWeek = ref("");
const getCurrentWeek = async () => {
    const today = moment();
    const firstDay = today.clone().startOf("week").format("MMMM Do YYYY");
    const lastDay = today.clone().endOf("week").format("MMMM Do YYYY");
    currentWeek.value = `${firstDay} - ${lastDay}`;
    currentDay.value = today.format("MMMM Do YYYY");
    currentDayOfTheWeek.value = today.format("dddd");
    currentWeekFirstDay.value = today
        .clone()
        .startOf("week")
        .format("YYYY-MM-DD");

    await onDataFetch();
};

const getPreviousWeek = async () => {
    const currentFirstDay = moment(
        currentWeek.value.split(" - ")[0],
        "MMMM Do YYYY"
    );
    const previousFirstDay = currentFirstDay.clone().subtract(7, "days");
    const previousLastDay = previousFirstDay.clone().add(6, "days");
    const firstDayStr = previousFirstDay.format("MMMM Do YYYY");
    const lastDayStr = previousLastDay.format("MMMM Do YYYY");
    currentWeek.value = `${firstDayStr} - ${lastDayStr}`;

    currentWeekFirstDay.value = previousFirstDay
        .clone()
        .startOf("week")
        .format("YYYY-MM-DD");

    await onDataFetch();
};

const getNextWeek = async () => {
    const currentLastDay = moment(
        currentWeek.value.split(" - ")[1],
        "MMMM Do YYYY"
    );
    const nextFirstDay = currentLastDay.clone().add(1, "days");
    const nextLastDay = nextFirstDay.clone().add(6, "days");
    const firstDayStr = nextFirstDay.format("MMMM Do YYYY");
    const lastDayStr = nextLastDay.format("MMMM Do YYYY");
    currentWeek.value = `${firstDayStr} - ${lastDayStr}`;

    currentWeekFirstDay.value = nextFirstDay
        .clone()
        .startOf("week")
        .format("YYYY-MM-DD");

    await onDataFetch();
};

const showWeekDetails = () => {
    showWeekView.value = false;
    showDayView.value = true;
};

const showDayDetails = () => {
    showWeekView.value = true;
    showDayView.value = false;
};
const processDayViewMark = (hour: any, availability: any, slot: any) => {
    console.log(hour, availability);
};

const isAvailable = (user: any, hour: any, minute: any) => {
    const day = moment(hour, "h:mmA").format("dddd");
    if (!user.availability[day]) {
        return false;
    }

    const start = moment(user.availability[day].start, "h:mmA");
    const end = moment(user.availability[day].end, "h:mmA");
    const current = moment(hour, "h:mmA").set("minute", minute);

    if (current.isSameOrAfter(start) && current.isBefore(end)) {
        return true;
    }

    if (end.isBefore(start)) {
        if (current.isAfter(start) || current.isBefore(end)) {
            return true;
        }
    }

    return false;
};
const isAvailableCounter = () => {};

const getTimeSlot = (user: any, hour: any) => {
    const day = moment(hour, "h:mmA").format("dddd");
    if (!user.availability[day]) {
        return "Not Available";
    }
    const start = moment(user.availability[day].start, "h:mmA");
    const end = moment(user.availability[day].end, "h:mmA");
    const current = moment(hour, "h:mmA");

    if (current.isBefore(start) || current.isAfter(end)) {
        return " ";
    }

    return " ";
};
const getPreviousDay = () => {
    const currentDayMoment = moment(currentDay.value, "MMMM Do YYYY");
    const previousDayMoment = currentDayMoment.clone().subtract(1, "day");
    currentDay.value = previousDayMoment.format("MMMM Do YYYY");
    currentDayOfTheWeek.value = previousDayMoment.format("dddd");
};

const getNextDay = () => {
    const currentDayMoment = moment(currentDay.value, "MMMM Do YYYY");
    const nextDayMoment = currentDayMoment.clone().add(1, "day");
    currentDay.value = nextDayMoment.format("MMMM Do YYYY");
    currentDayOfTheWeek.value = nextDayMoment.format("dddd");
};

const onDataFetch = async () => {
    if (props.teamView) {
        await getTeamSchedule();
    } else {
        await getEmployees();
    }
};

const getTeamSchedule = async () => {
    const payload = {
        page: currentPage.value,
        per_page: 20,
        include: "employee,employee.shift.shift",
    };

    const params = new URLSearchParams(JSON.parse(JSON.stringify(payload)));

    if (search.value) {
        params.append("filter[name]", search.value);
    }

    if (currentWeekFirstDay.value) {
        params.append("date", currentWeekFirstDay.value);
    }

    await TeamStore.getTeamSchedule(params);

    lastPage.value = TeamStore.teamSchedule?.meta?.last_page ?? 1;

    users.value = [];
    TeamStore.teamSchedule.data
        .filter((team) => team.employee.shift !== null)
        .forEach((team) => {
            users.value.push({
                work_days: team.employee.weekly_schedule,
                id: team.employee.id,
                name: team.employee.name,
                shift_description: team.employee.shift.shift.name,
                availability: {
                    Sunday: {
                        isAvailable:
                            team.employee.shift.shift.work_days.includes(
                                "Sunday"
                            ),
                        start: moment(
                            team.employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            team.employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Monday: {
                        isAvailable:
                            team.employee.shift.shift.work_days.includes(
                                "Monday"
                            ),
                        start: moment(
                            team.employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            team.employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Tuesday: {
                        isAvailable:
                            team.employee.shift.shift.work_days.includes(
                                "Tuesday"
                            ),
                        start: moment(
                            team.employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            team.employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Wednesday: {
                        isAvailable:
                            team.employee.shift.shift.work_days.includes(
                                "Wednesday"
                            ),
                        start: moment(
                            team.employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            team.employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Thursday: {
                        isAvailable:
                            team.employee.shift.shift.work_days.includes(
                                "Thursday"
                            ),
                        start: moment(
                            team.employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            team.employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Friday: {
                        isAvailable:
                            team.employee.shift.shift.work_days.includes(
                                "Friday"
                            ),
                        start: moment(
                            team.employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            team.employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Saturday: {
                        isAvailable:
                            team.employee.shift.shift.work_days.includes(
                                "Saturday"
                            ),
                        start: moment(
                            team.employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            team.employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                },
            });
        });
};

const getEmployees = async () => {
    const payload = {
        page: currentPage.value,
        per_page: 20,
        include: ["shift.shift", "shifts.shift"],
    };

    const params = new URLSearchParams(JSON.parse(JSON.stringify(payload)));

    if (search.value) {
        params.append("filter[name]", search.value);
    }

    if (clientId.value) {
        params.append("filter[client_id]", clientId.value);
    }

    if (currentWeekFirstDay.value) {
        params.append("date", currentWeekFirstDay.value);
    }

    await ShiftStore.getShiftEmployees(params);

    lastPage.value = ShiftStore.shiftEmployees?.meta?.last_page ?? 1;

    users.value = [];

    console.log(ShiftStore.shiftEmployees.data);
    ShiftStore.shiftEmployees.data
        .filter((employee) => employee.shift !== null)
        .forEach((employee) => {
            users.value.push({
                id: employee.id,
                name: employee.name,
                shift_description: employee.shift.shift.name,
                work_days: employee.weekly_schedule,
                availability: {
                    Sunday: {
                        isAvailable:
                            employee.shift.shift.work_days.includes("Sunday"),
                        start: moment(
                            employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Monday: {
                        isAvailable:
                            employee.shift.shift.work_days.includes("Monday"),
                        start: moment(
                            employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Tuesday: {
                        isAvailable:
                            employee.shift.shift.work_days.includes("Tuesday"),
                        start: moment(
                            employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Wednesday: {
                        isAvailable:
                            employee.shift.shift.work_days.includes(
                                "Wednesday"
                            ),
                        start: moment(
                            employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Thursday: {
                        isAvailable:
                            employee.shift.shift.work_days.includes("Thursday"),
                        start: moment(
                            employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Friday: {
                        isAvailable:
                            employee.shift.shift.work_days.includes("Friday"),
                        start: moment(
                            employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                    Saturday: {
                        isAvailable:
                            employee.shift.shift.work_days.includes("Saturday"),
                        start: moment(
                            employee.shift.shift.start_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        end: moment(
                            employee.shift.shift.end_time,
                            "h:mmA"
                        ).format("h:mmA"),
                        total: 9,
                    },
                },
            });
        });
};

const searchUsers = async () => {
    showClearFilter.value = true;
    await onDataFetch();
};

const onSelectClient = async () => {
    showClearFilter.value = true;
    await onDataFetch();
};

const resetGlobalFilter = async () => {
    search.value = "";
    clientId.value = "";
    showClearFilter.value = false;
    await onDataFetch();
};
</script>
<template>
    <div class="mt-4">
        <div class="flex py-1 hidden lg:block">
            <Button variant="customPrimary" @click="showWeekDetails"
                >Day
            </Button>
            <Button class="ml-1" variant="customPrimary" @click="showDayDetails"
                >Week
            </Button>
        </div>
        <div id="week-details" class="text-center align-center w-full">
            <Button
                v-if="showWeekView"
                variant="soft-secondary"
                @click="getPreviousWeek"
            >
                <Lucide class="w-3 h-3" icon="ChevronsLeft" />
                <span class>Previous</span>
            </Button>
            <span v-if="showWeekView" class="mx-4">{{ currentWeek }}</span>
            <Button
                v-if="showWeekView"
                variant="soft-secondary"
                @click="getNextWeek"
                ><span>Next</span>
                <Lucide class="w-3 h-3" icon="ChevronsRight" />
            </Button>
            <Button
                v-if="showDayView"
                variant="soft-secondary"
                @click="getPreviousDay"
            >
                <Lucide class="w-3 h-3" icon="ChevronsLeft" />
                <span class="hidden lg:block">Previous</span>
            </Button>
            <span v-if="showDayView" class="mx-4">{{ currentDay }}</span>
            <Button
                v-if="showDayView"
                variant="soft-secondary"
                @click="getNextDay"
                ><span class="hidden lg:block">Next</span>
                <Lucide class="w-3 h-3" icon="ChevronsRight" />
            </Button>
        </div>
        <!-- main -->

        <div class="flex justify-end items-center mt-5">
            <div
                :class="props.teamView ? 'w-2/4 justify-end' : 'w-1/2'"
                class="flex gap gap-x-2"
            >
                <template v-if="showClearFilter">
                    <button
                        :class="props.teamView ? 'w-32' : 'w-64'"
                        class="btn bg-custom-color-1000 text-white mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <TomSelect
                    v-if="!props.teamView"
                    id="clientId"
                    v-model="clientId"
                    :value="clientId"
                    class="bg-white w-full px-2 border border-gray-300"
                    name="clientId"
                    @update:modelValue="onSelectClient()"
                >
                    <option selected>Select Client</option>
                    <option
                        v-for="client in ClientStore.clientsForDropdown"
                        :value="client.id"
                    >
                        {{ client.legal_business_name }}
                    </option>
                </TomSelect>

                <FormInput
                    v-model="search"
                    :class="{ 'h-8 w-full lg:w-1/4': props.teamView }"
                    class="px-2 border border-gray-300"
                    placeholder="Search employee"
                    @keyup.enter="searchUsers"
                />
            </div>
        </div>
        <div class="lg:hidden mt-4">
            <div
                v-for="user in users"
                :key="user.id"
                class="col-span-12 intro-y md:col-span-6"
            >
                <div class="box">
                    <div class="flex flex-col items-center p-5 lg:flex-row">
                        <!--                        <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">-->
                        <!--                            <img-->
                        <!--                                alt="Midone Tailwind HTML Admin Template"-->
                        <!--                                class="rounded-full"-->
                        <!--                                :src="faker.photos[0]"-->
                        <!--                            />-->
                        <!--                        </div>-->
                        <div
                            class="mt-3 text-center lg:ml-2 lg:mr-auto lg:text-left lg:mt-0"
                        >
                            <a class="font-medium" href=""> {{ user.name }} </a>
                            <div class="text-slate-500 text-xs mt-0.5">
                                {{
                                    user.availability[currentDayOfTheWeek].start
                                }}
                                -
                                {{ user.availability[currentDayOfTheWeek].end }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="showWeekView"
            class="mt-4 grid grid-cols-8 gap-0 border border-gray-500/50 bg-white shadow-lg rounded-lg"
        >
            <div class="row-span-1"></div>
            <div
                v-for="day in days"
                :key="day"
                class="p-2 col-span-1 text-center border-r border-b border-gray-500/50"
            >
                {{ day }}
            </div>
            <template v-for="user in users" :key="user.id">
                <div
                    class="text-gray-500 px-2 py-2 row-span-1 border-r border-b border-gray-500/50 truncate"
                >
                    {{ user.name }}
                </div>
                <div v-for="day in days" :key="day">
                    <div
                        class="p-2 col-span-1 border-r border-b border-gray-500/50 text-center align-center justify-center items-center"
                    >
                        <div
                            v-if="user.work_days"
                            :class="{
                                'bg-green-500/50 border-green-500/50 shadow-lg':
                                    user.work_days[day]['is_available'],
                                'bg-red-500/50 border-red-500/50 shadow-lg':
                                    !user.work_days[day]['is_available'],
                            }"
                            class="border rounded text-center truncate p-1"
                        >
                            {{ user.work_days[day]["shift"] }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div v-if="showDayView" class="hidden lg:block table-container">
            <table id="dayView" class="table text-xs mt-4">
                <thead>
                    <tr>
                        <th class="py-2">Users</th>
                        <th
                            v-for="hour in hours"
                            :key="hour"
                            class="px-2 bg-gray-400"
                        >
                            {{ hour }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td class="py-2">{{ user.name }}</td>
                        <td v-for="hour in hours" :key="hour" class="p-0">
                            <div class="flex flex-row">
                                <div
                                    id="first"
                                    :class="{
                                        'basis-1/4 bg-green-500': isAvailable(
                                            user,
                                            hour,
                                            0
                                        ),
                                        'basis-1/4 bg-gray-100': !isAvailable(
                                            user,
                                            hour,
                                            0
                                        ),
                                    }"
                                >
                                    &nbsp;
                                </div>
                                <div
                                    id="second"
                                    :class="{
                                        'basis-1/4 bg-green-500': isAvailable(
                                            user,
                                            hour,
                                            15
                                        ),
                                        'basis-1/4 bg-gray-100': !isAvailable(
                                            user,
                                            hour,
                                            15
                                        ),
                                    }"
                                >
                                    &nbsp;
                                </div>
                                <div
                                    id="third"
                                    :class="{
                                        'basis-1/4 bg-green-500': isAvailable(
                                            user,
                                            hour,
                                            30
                                        ),
                                        'basis-1/4 bg-gray-100': !isAvailable(
                                            user,
                                            hour,
                                            30
                                        ),
                                    }"
                                >
                                    &nbsp;
                                </div>
                                <div
                                    id="fourth"
                                    :class="{
                                        'basis-1/4 bg-green-500': isAvailable(
                                            user,
                                            hour,
                                            45
                                        ),
                                        'basis-1/4 bg-gray-100': !isAvailable(
                                            user,
                                            hour,
                                            45
                                        ),
                                    }"
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- {{ user.availability && user.availability[hour] ? user.availability[hour] : '&nbsp;' }} -->

        <!-- main -->
        <div class="mt-4 pagination-container">
            <Button
                :disabled="currentPage === 1"
                variant="customPrimary"
                @click="previousPage"
                >Previous
            </Button>
            <div class="page-info">
                Page {{ currentPage }} of {{ lastPage }}
            </div>
            <Button
                :disabled="currentPage === lastPage"
                variant="customPrimary"
                @click="nextPage"
                >Next
            </Button>
        </div>
    </div>
</template>
<style scoped>
/* Add black grid lines */

/* Style for pagination container */
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.page-info {
    font-size: 1.2rem;
}

.table-container {
    overflow-x: auto; /* Enable horizontal scroll */
    width: 100%;
}

.table {
    width: max-content; /* Adjust width based on content */
    border-collapse: collapse;
}

th,
td {
    text-align: left;
    border-bottom: 1px solid #ddd;
    /* border-right: 1px solid #ddd; */
}

th {
    background-color: #f2f2f2;
}

td {
    vertical-align: middle;
}

th:first-child,
td:first-child {
    position: sticky; /* Freeze the first column */
    left: 0;
    z-index: 1;
    background-color: #fff;
    padding-left: 5px;
}

.bg-green-200 {
    background-color: #9ae6b4;
}

.bg-available {
    background-color: green;
}

.table th:first-child,
.table td:first-child {
    width: 150px;
}

th:first-child {
    min-width: 150px;
}
</style>
