<script lang="ts" setup>
import { provide, reactive, ref, toRefs } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useEmployeesStore } from "../../../stores/employeesStore";
import Button from "@/base-components/Button";
import Lucide from "@/base-components/Lucide";
import Modal from "@/pages/components/Modal.vue";
import Notification, { NotificationElement } from "@/base-components/Notification";
import SubmitButton from "@/pages/components/SubmitButton.vue";

const emit = defineEmits(["fetchEmployees"]);

const uploading = ref(false);
const dialog = ref(false);
const EmployeeStore = useEmployeesStore();
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
//@ts-ignore
const $externalResults = ref<Record<string, any> | []>({});
const inputFile = ref<HTMLInputElement>();

const uploadFile = (e: Event) => {
    const target = e.target as HTMLInputElement;

    if (!target.files) {
        return;
    }

    form.attachment = target.files[0];
    import_error.value = "";
    import_errors.value = [];
};

const form = reactive({
    attachment: null as File | null,
});

const rules = {
    attachment: {
        required,
    },
};
//@ts-ignore
const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const open = () => {
    dialog.value = true;
};

const close = () => {
    dialog.value = false;
    $externalResults.value = {};
    import_error.value = "";
    import_errors.value = [];
};

const download = async () => {
    await EmployeeStore.downloadImportTemplate();
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const import_errors = ref<string[]>([]);
const import_error = ref<string | null>(null);

const onClickUpload = async () => {
    uploading.value = true;
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        uploading.value = false;
        return;
    }

    if (!validate.value.$invalid) {
        try {
            const fd = new FormData();
            fd.append("attachment", form.attachment as File);
            console.log('asdasdasd')
            await EmployeeStore.importEmployees(fd).then(async (response: any) => {
                if (response.status === "success") {
                    close();
                    showSuccessNotification();
                    emit("fetchEmployees");
                } else {
                    // @ts-ignore
                    inputFile.value.value = null;
                    if (response.error?.errors.file) {
                        import_errors.value = response.error.errors.file;
                    } else if (response.error?.errors) {
                        // if (response.error?.errors['attachment'][0]) {
                        //     import_errors.value = response.error?.errors['attachment'];
                        // } 
                        if (response.error.errors.errors) {
                            import_errors.value = response.error.errors.errors;
                        } else {
                            import_errors.value = response.error?.errors['attachment'];
                        }
                        
                    } else if (response.error.error) {
                        import_error.value = response.error.error;
                    }
                    uploading.value = false;
                }
            });

        } catch (error: any) {
            // @ts-ignore
            inputFile.value.value = null;
            form.attachment = null;
            $externalResults.value = error?.response?.data?.errors;
            validate.value.$reset();
        } finally {
            uploading.value = false;
        }
    }
};
</script>

<template>
    <Modal :open="dialog" size="xl">
        <template #trigger>
            <Button
                id="importEmployee"
                class="shadow-md mr-2"
                variant="customPrimary"
                @click="open"
            >
                Import Employee
            </Button>
        </template>

        <template #content>
            <div
                class="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-100 dark:bg-gray-800 dark:text-blue-400"
                role="alert"
            >
                <svg
                    class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
                    />
                </svg>
                <span class="sr-only">Info</span>
                <div class="ml-2">
                    <span class="font-medium"
                        >Please be informed that certain fields are not updated
                        through the import process. These fields include:</span
                    >
                    <ul class="mt-2 list-disc list-inside">
                        <li>Employee Status</li>
                        <li>Employee Cost</li>
                        <li>RE Fees</li>
                        <li>Nonrefundable Amount</li>
                        <li>Import process does not accomodate terminations and suspension at the moment</li>
                    </ul>
                    <div class="mt-2 mb-2">
                        These values are set only during the creation process
                        and are not modified by imports. To ensure accurate
                        data, kindly review and update these fields manually
                        when necessary.
                    </div>
                    <span class="font-medium mt-2"
                        >The following fields should follow the validations below:</span
                    >
                    <ul class="mt-2 list-disc list-inside">
                        <li>Phone numbers must be numeric</li>
                        <li>Amounts such as Base Pay must be numeric</li>
                        <li>Shift should be set as <span class="font-medium">Mid Shift, Day Shift, Night Shift</span></li>
                        <li>Timezone must be in <span class="font-medium">Asia/Manila</span> format.</li>
                        <!-- <li>Dates must be in <span class="font-medium">mm/dd/YYYY</span> format (e.g. 09/18/2024).</li> -->
                        <li>Primary location, secondary location and work location must be <span class="font-medium">wfo, wfh or hybrid</span>.</li>
                    </ul>
                    <div class="mt-2">
                        Thank you for your attention to this matter.
                    </div>
                </div>
            </div>

            <div
                class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
            >
                <h2 class="font-medium text-base mr-auto mb-4">Select File</h2>
            </div>

            <div v-if="import_error"
                class="mt-4 px-4 bg-red-200 text-red-600 rounded">
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li>
                        {{ import_error }}
                    </li>
                </ul>
            </div>

            <div v-if="import_errors.length > 0" class="mt-4 px-4 bg-red-200 text-red-600 rounded">
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li v-for="(error, index) in import_errors" :key="index">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <!-- <div
                v-if="$externalResults?.length > 0"
                class="mt-4 px-4 bg-red-200 text-red-600 rounded"
            >
                <h5 class="pt-4 font-semibold">Something went wrong.</h5>
                <ul class="list-disc p-4">
                    <li v-for="error in $externalResults">
                        {{ error[0] }}
                    </li>
                </ul>
            </div> -->



            <div class="mt-4">
                <p>
                    <input
                        ref="inputFile"
                        id="uploadFileBtn"
                        class="btn shadow-md mr-2"
                        type="file"
                        @change="uploadFile"
                    />
                </p>
                <template v-if="validate.attachment.$error">
                    <div
                        v-for="(error, index) in validate.attachment.$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </template>

        <template #action>
            <div class="flex justify-between">
                <div>
                    <Button
                        class=""
                        type="button"
                        variant="outline-secondary"
                        @click="download"
                    >
                        Download Template
                    </Button>
                </div>

                <div>
                    <Button
                        class="w-24 mr-1"
                        type="button"
                        variant="outline-secondary"
                        @click="close"
                    >
                        Close
                    </Button>

                    <SubmitButton
                        id="btn_process"
                        class="w-32 mb-2 mr-1"
                        variant="customPrimary"
                        processing-label="Importing..."
                        :is-processing="uploading"
                        @click="onClickUpload"
                    >
                        Upload
                    </SubmitButton>
                </div>
            </div>
        </template>
    </Modal>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">Employees has been imported.</div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>

<style scoped></style>
