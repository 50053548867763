<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { useAuthStore } from "@/stores/authStore";
import { useUserStore } from "@/stores/userStore";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import FormInput from "@/base-components/Form/FormInput.vue";
import Lucide from "@/base-components/Lucide/Lucide.vue";
import Tippy from "@/base-components/Tippy";
import PageHeaderStandard from "@/pages/components/PageHeaderStandard.vue";

const AuthStore = useAuthStore();
const UserStore = useUserStore();

const initialLazyParams = {
    first: 0,
    rows: 30,
    sortField: null,
    sortOrder: null,
    filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        email: {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "roles.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
        "permissions.name": {
            operator: FilterOperator.AND,
            constraints: [
                { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            ],
        },
    },
};

//@ts-ignore
const impersonator = window.Laravel.impersonator || 0;

const filters = ref(initialLazyParams.filters);

const expandedRows = ref([]);

const users = ref();

const selectedusers = ref();

const selectAll = ref(false);

const loading = ref(true);

const totalRecords = ref(0);

const lazyParams = ref<LazyParamsInterface>({ rows: initialLazyParams.rows });

const dt = ref();

const showClearFilter = ref(false);

const showImpersonateIcon = ref(impersonator === 0);

const searchableCols = ref(["name", "email", "roles.name", "permissions.name"]);

onMounted(async () => {
    filters.value = initialLazyParams.filters;
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };
    await loadLazyData();
});
const loadLazyData = async () => {
    loading.value = true;
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await UserStore.fill(params).then((response: any) => {
        users.value = UserStore.users;
        totalRecords.value = UserStore.users?.total ?? 0;
        loading.value = false;
    });
};

const onPage = (event: any) => {
    console.log("onPage");
    lazyParams.value = event;
    console.log("New onPage");
    console.log(lazyParams);
    loadLazyData();
};

const onSort = (event: any) => {
    showClearFilter.value = true;
    console.log("onSort");
    lazyParams.value = event;
    loadLazyData();
};

const onFilter = () => {
    showClearFilter.value = true;
    console.log("onFilter");
    lazyParams.value.filters = filters.value;
    loadLazyData();
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = initialLazyParams.filters;
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };
    loadLazyData();
};

const onRowUnselect = () => {
    console.log("onRowUnselect");
    selectAll.value = false;
};

const onRowExpand = () => {
    console.log("onRowSelect");
};
const onRowCollapse = () => {
    console.log("onRowSelect");
};
const expandAll = () => {
    console.log("expandAll");
};
const collapseAll = () => {
    console.log("collapseAll");
};

const impersonate = (id: any) => {
    AuthStore.impersonate(id).then((data) => {
        window.location.href = '/';
    });
};
const leaveImpersonate = () => {
    AuthStore.leaveImpersonate();
};
</script>
<template>
    <div class="w-full">
        <PageHeaderStandard :title="'User Manual Override Permission'" />
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </button>
                </template>

                <div class="ml-2 w-56 relative text-slate-500">
                    <FormInput
                        id="global-filter"
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>
        <DataTable
            ref="dt"
            v-model:expandedRows="expandedRows"
            v-model:filters="filters"
            v-model:selection="selectedusers"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="initialLazyParams.rows"
            :rowsPerPageOptions="[30, 50, 100]"
            :totalRecords="totalRecords"
            :value="users?.data"
            class="mt-4 p-datatable-sm text-xs"
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter"
            @page="onPage($event)"
            @rowCollapse="onRowCollapse"
            @rowExpand="onRowExpand"
            @sort="onSort($event)"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No User found.</template>
            <template #loading> Loading User Data. Please wait.</template>
            <Column :sortable="true" field="id" header="User ID" bodyClass="!pl-3">
                <template #body="{ data }">
                    {{ data.id }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by User ID"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="name" header="User Name">
                <template #body="{ data }">
                    {{ data.name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by User Name"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="email" header="User Email">
                <template #body="{ data }">
                    {{ data.email }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by User Email"
                        type="text"
                    />
                </template>
            </Column>
            <Column header="User Roles">
                <template #body="{ data }">
                    <div v-for="roles in data.roles">
                        {{ roles.name }}
                    </div>
                </template>
            </Column>
            <Column field="permissions.name" header="User Permissions">
                <template #body="{ data }">
                    <div v-for="permissions in data.permissions">
                        {{ permissions.name }}
                    </div>
                </template>
            </Column>
            <Column :exportable="false" style="width: 8rem; max-width: 8rem">
                <template #body="slotProps">
                    <div class="content-center flex justify-center">
                        <div class="ml-0">
                            <router-link
                                :to="{
                                    name: 'account-management',
                                    params: { id: slotProps.data.id },
                                }"
                            >
                                <Tippy content="View">
                                    <svg
                                        :id="'view_' + slotProps.data.id"
                                        class="icon icon-tabler icon-tabler-eye cursor-pointer"
                                        fill="none"
                                        height="18"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        width="18"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M0 0h24v24H0z" fill="none" stroke="none"></path>
                                        <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                        <path
                                            d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                                        ></path>
                                    </svg>
                                </Tippy>
                            </router-link>
                        </div>

                        <div
                            class="ml-2"
                            v-if="
                                showImpersonateIcon &&
                                slotProps.data.id != AuthStore.authUser.id
                            "
                            @click="impersonate(slotProps.data.id)"
                        >
                            <Tippy content="Impersonate">
                                <Lucide icon="Users" class="h-4 w-4 stroke-2" />
                            </Tippy>
                        </div>
                        <div
                            class="ml-2"
                            v-if="
                                impersonator === slotProps.data.id
                            "
                        >
                            <Tippy content="Impersonator">
                                <Lucide
                                    icon="Ghost"
                                    class="h-4 w-4 stroke-2 text-gray-400 cursor-default"
                                />
                            </Tippy>
                        </div>

                        <div
                            class="ml-2"
                            v-if="AuthStore.authUser.id === slotProps.data.id && impersonator != 0"
                            @click="leaveImpersonate()"
                        >
                            <Tippy content="Leave Impersonate">
                                <Lucide
                                    icon="UserX"
                                    class="h-4 w-4 stroke-2 text-red-400"
                                />
                            </Tippy>
                        </div>
                    </div>
                </template>
                <template #header="slotProps">
                    <div class="content-center flex justify-center">
                        <p v-if="showClearFilter">
                            <button
                                class="btn bg-custom-color-1000 text-white w-full mx-2 p-2 rounded"
                                @click="resetGlobalFilter"
                            >
                                clear &nbsp;<span
                                    class="pi pi-filter-slash p-component"
                                ></span>
                            </button>
                        </p>
                    </div>
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>
        </DataTable>
    </div>
</template>
