<script lang="ts" setup>
import router from "../../../router";
import { onMounted, ref } from "vue";
import { useTicketsStore } from "../../../stores/ticketsStore";
import CustomIcon from "../../Icons/CustomIcon.vue";

const TicketsStore = useTicketsStore();

const totalTickets = ref(0);

onMounted(async () => {
    await TicketsStore.getTotalTicketsForLoggedInUser();
    totalTickets.value = TicketsStore.myTotalTickets;
});

const viewEmployee = () => {
    router.push({ name: "myTickets" });
};
</script>
<template>
    <div class="report-box zoom-in" @click="viewEmployee">
        <div class="box p-5">
            <div class="flex">
                <CustomIcon :height="28" :icon="'Bookmarks'" :width="28" />
                <div class="ml-auto"></div>
            </div>
            <div class="text-3xl font-medium leading-8 mt-6">
                {{ totalTickets }}
            </div>
            <div class="text-base text-slate-500 mt-1">My Open Tickets</div>
        </div>
    </div>
</template>
