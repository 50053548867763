<script lang="ts" setup>
import { onMounted, provide, reactive, ref, toRefs } from "vue";
import PageHeaderStandard from "../../components/PageHeaderStandard.vue";
import { useClientsStore } from "../../../stores/clientsStore";
import { useUserStore } from "../../../stores/userStore";
import { useAuthStore } from "../../../stores/authStore";
import { useClientNotificationsStore } from "../../../stores/clientNotificationsStore";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import router from "../../../router";
import Lucide from "../../../base-components/Lucide";
import Button from "../../../base-components/Button";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";

const ClientStore = useClientsStore();
const ClientNotificationsStore = useClientNotificationsStore();

const UserStore = useUserStore();

const AuthStore = useAuthStore();

const editorData = ref("<p>Content of the editor.</p>");

const editorConfig = {
    toolbar: {
        items: ["bold", "italic"],
    },
};

onMounted(async () => {
    // await AuthStore.getLoggedInUserDetails();
    await ClientStore.getAllClients();
    await UserStore.getUsersForNotifications();
});

const addFormData = reactive({
    poc: true,
    bcc: true,
    recipients: ClientNotificationsStore.temp.recipients,
    subject: ClientNotificationsStore.temp.subject,
    body: ClientNotificationsStore.temp.body,
    attachment: [],
    bcc_id: [],
    poc_id: [],
});

const rules = {
    poc: { required },
    bcc: { required },
    recipients: { required },
    subject: { required },
    body: { required },
};

const attachments = reactive({
    data: [],
});
const backendValidationErrors = ref<any | undefined>([]);

const validate = useVuelidate(rules, toRefs(addFormData));
const submit = () => {
    ClientNotificationsStore.sendNotification().then((response) => {
        //@ts-ignore
        if (response?.status === "success") {
            successNotificationToggle();
            resetForm();
            setTimeout(() => router.push({ name: "clientNotifications" }), 100);
        } else {
            backendValidationErrors.value = response?.data[0];
            errorNotificationToggle();
        }
    });
};
const resetForm = () => {
    addFormData.poc_id = [];
    addFormData.bcc_id = [];
    addFormData.recipients = "";
    addFormData.subject = "";
    addFormData.body = "";
};

const backToAdd = () => {
    router.push({ name: "clientNotificationsAdd" });
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const errorNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
</script>

<template>
    <div>
        <PageHeaderStandard :title="'Client Notification Preview'" />

        <form @submit.prevent="submit">
            <div class="mt-4">
                <span class="text-md font-bold">Client Contacts</span>
            </div>
            <div
                class="grid grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-1 mt-4"
            >
                <template v-for="client in ClientStore.clients">
                    <div
                        class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-sm mr-auto mb-4">
                                {{ client.legal_business_name }}
                            </h2>
                        </div>
                        <div class="mt-3">
                            <div class="mt-4">
                                <template
                                    v-for="poc in client.point_of_contacts"
                                >
                                    <div class="form-check form-switch mt-2">
                                        <label
                                            v-if="
                                                Object.values(
                                                    ClientNotificationsStore
                                                        .temp?.poc_id || []
                                                ).includes(poc.user_id)
                                            "
                                            class="form-check-label text-sm"
                                        >
                                            {{ poc.full_name }}
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div class="mt-4">
                <div
                    class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                >
                    <div
                        class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                    >
                        <h2 class="font-medium text-base mr-auto mb-4">
                            Internal users to be bcc'd
                        </h2>
                    </div>
                    <div class="mt-3 flex">
                        <template
                            v-for="user in UserStore.usersForNotifications"
                        >
                            <div class="">
                                <div class="form-check form-switch mt-2 ml-2">
                                    <label
                                        class="py-1 px-2 rounded-full text-xs bg-success text-white font-medium"
                                        >{{ user.name }}
                                    </label>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <div
                    class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                >
                    <div
                        class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                    >
                        <h2 class="font-medium text-base mr-auto mb-4">
                            Additional Information
                        </h2>
                    </div>
                    <div class="mt-4">
                        <label class="form-label" for="legal_business_name">
                            Additional Recipients:
                            {{ ClientNotificationsStore.temp.recipients }}
                        </label>
                    </div>
                    <div class="mt-4">
                        <label class="form-label" for="legal_business_name">
                            Subject:
                            {{ ClientNotificationsStore.temp.subject }}
                        </label>
                    </div>
                    <div class="mt-4">
                        <label class="form-label" for="body">
                            Body / Message :
                            <span
                                v-html="ClientNotificationsStore.temp.body"
                            ></span>
                        </label>
                    </div>
                    <div
                        class="grid grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-5 mt-4"
                    >
                        <template
                            v-for="uploads in ClientNotificationsStore.temp
                                .attachment"
                        >
                            <div
                                class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg box"
                            >
                                <div class="flex items-center">
                                    <div class="file">
                                        <Lucide
                                            icon="Paperclip"
                                            class="w-6 h-6 text-custom-color-1000"
                                        />
                                    </div>
                                    <div class="ml-4">
                                        <a class="font-medium cursor-pointer">{{
                                            uploads.name
                                        }}</a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="mt-4 flex flex-row-reverse">
                <Button
                    :disabled="!addFormData.subject"
                    class="ml-2 btn bg-custom-color-1000 text-white w-42"
                    type="submit"
                >
                    Send Notification
                    <Lucide class="w-4 h-4 mr-2" icon="ChevronRight" />
                </Button>
                <Button class="btn w-42" type="button" @click="backToAdd()">
                    <Lucide class="w-4 h-4 mr-2" icon="ChevronsLeft" />
                    Back
                </Button>
            </div>
        </form>
    </div>
    <!-- BEGIN: Success Notification Content -->
    <!-- <div
        id=" success-notification-content"
        class="toastify-content hidden flex"
    >
        <Lucide icon="CheckCircle" class="text-success" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Success</div>
            <div class="text-slate-500 mt-1">
                Notification was saved successfully
            </div>
        </div>
    </div> -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">Client Saved Successfully</div>
        </div>
    </Notification>

    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
