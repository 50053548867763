<script lang="ts" setup>
import moment from "moment";
import {defineEmits, onMounted, onUpdated, provide, reactive, ref, toRefs,} from "vue";
import {required,} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useRoute} from "vue-router";
import {useEmployeesStore} from "../../stores/employeesStore";
import {useAuthStore} from "../../stores/authStore";
import {useHelperStore} from "../../stores/helperStore";
import {useUiStore} from "../../stores/uiStore";
import {useFreskdeskStore} from "../../stores/freshdeskStore";
import {useTicketsStore} from "../../stores/ticketsStore";
import router from "../../router";
import SelectEmployee from "../components/SelectEmployee.vue";
import Button from "../../base-components/Button";
import {ClassicEditor} from "../../base-components/Ckeditor";
import Notification, {NotificationElement} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import EmployeeInterface from "../interfaces/employeesInterface";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});
const TicketsStore = useTicketsStore();

const AuthStore = useAuthStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const FreshdeskStore = useFreskdeskStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);
const emit = defineEmits(["output", "ticketType"]);

const EmployeesStore = useEmployeesStore();

const ticketForm = reactive({
    dateFiled: "",
    employeeId: "",
    name: "",
    reason: "",
    requestStatus: "Pending",
    approver: "Team Lead",
    type: "Request",
});

const rules = {
    //dateFiled: { required },
    employeeId: {required},
    //name: { required },
    reason: {required},
    requestStatus: {required},
    approver: {required},
    type: {required},
};

const attachment_files = reactive({
    data: [],
});

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.employeeId = "";
    ticketForm.name = "";
    ticketForm.reason = "";
    ticketForm.requestStatus = "";
    ticketForm.approver = "";
    ticketForm.type = "";
};

const employee = reactive({
    data: null as EmployeeInterface | null,
});
onMounted(async () => {
    // console.log('populateEmployeeDetails', window.Laravel.user.id);
    //
    // await EmployeesStore.getUserEmployeeData(parseInt(window.Laravel.user.id)).then(response => {
    //     employee.data = EmployeesStore.userEmployee;
    //     console.log('AU', employee.data);
    // });
});

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";

        console.log('show error toast');
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const form_body = {
            "Date Filed": currentDate,
            "Employee Name": ticketForm.name,
            Reason: ticketForm.reason,
        };

        const form = new FormData();
        form.append("id", String(id));
        form.append("subject", "Salary Increase Request: " + ticketForm.name);
        form.append("priority", ticketType?.f_priority_id?.toString() ?? '');
        form.append("status", ticketType?.f_status_id?.toString() ?? '');
        form.append("type", ticketType?.f_type?.toString() ?? '');
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? '');

        if (attachments.data) {
            for (const files of attachments.data) {
                for (const file of files) {
                    form.append("attachments[]", file);
                }
            }
        }
        const response = await TicketsStore.save(form);
        if (response.status === "success") {
            validate.value.$reset();
            emit("output", {render: true, type: "success"});
            if (id !== null) {
                emit("ticketType", HelperStore.getTicketType(parseInt(id)));
            }
            showSuccessNotification();
            formReset();
            router.push({
                name: "ticketDetails",
                params: {id: response.data.id},
            });
        } else {
            console.log("response not success:", response);
            emit("output", {render: true, type: "danger"});
        }
    }
};

const setEmployee = (event: any) => {
    ticketForm.employeeId = event;
};

const employeeName = ref("");
const setEmployeeName = (event: any) => {
    ticketForm.name = event;
    employeeName.value = event;
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        attachments.data.push(Array.from(files));
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <SelectEmployee
            :validate="validate"
            @itemName="setEmployeeName"
            @itemValue="setEmployee"
        />
        <div class="mt-3">
            <label>Reason <span style="color: rgb(255, 0, 0)">*</span></label>
            <div class="mt-2">
                <ClassicEditor
                    id="reason"
                    v-model="ticketForm.reason"
                    :config="editorConfig"
                    name="reason"
                />
                <template v-if="validate.reason.$error">
                    <div
                        v-for="(error, index) in validate.reason.$errors"
                        id="error_reason"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning shadow-sm py-2 px-3
                    inline-flex items-center justify-center
                    rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
            >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle"/>
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
