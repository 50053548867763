<script lang="ts" setup>
import Button from "@/base-components/Button";
import { defineEmits, defineProps, ref, watch } from "vue";
import { EmployeeScheduleInterface } from "@/pages/interfaces/employeesInterface";
import CustomIcon from "@/pages/Icons/CustomIcon.vue";
import { Menu } from "@/base-components/Headless";
import FormSlider from "@/pages/FormSlider.vue";
import FormEmployeeShifts from "@/pages/forms/employees/FormEmployeeShifts.vue";
import { useEmployeeShiftStore } from "@/stores/employeeShiftStore";
import moment from "moment";
import { useAuthStore } from "@/stores/authStore";
import BadgeIndicator from "@/pages/components/BadgeIndicator.vue";
import CardSkeleton from "@/pages/components/CardSkeleton.vue";

const props = defineProps({
    shifts: {
        type: Object,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

const EmployeeShiftStore = useEmployeeShiftStore();
const AuthStore = useAuthStore();

const emit = defineEmits(["refresh"]);
const selectedShift = ref();

const isEmployeeShiftFormSliderOut = ref(false);

const shiftsData = ref<any>([]);

const closeSlider = () => {
    isEmployeeShiftFormSliderOut.value = false;
    selectedShift.value = {};
    emit("refresh", true);
};

watch(
    () => props.shifts,
    (newValue) => {
        shiftsData.value = newValue;
    }
);

const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
};

const openEmployeeShiftFormSlider = (item: any) => {
    isEmployeeShiftFormSliderOut.value = true;

    if (!isEmptyObject(item)) {
        selectedShift.value = item;
    }
};

const onClickDelete = async (id: number) => {
    await EmployeeShiftStore.delete(id);
    emit("refresh", true);
};

const isActive = (shift: EmployeeScheduleInterface) => {
    if (
        (!shift.effdt_to && moment().isAfter(shift.effdt)) ||
        (shift.effdt_to &&
            moment().isBetween(
                shift.effdt,
                moment(shift.effdt_to).endOf("day")
            ))
    ) {
        return true;
    }
    return false;
};
</script>

<template>
    <CardSkeleton v-if="loading" />

    <div v-else class="intro-y box mt-5 lg:mt-0 mb-5">
        <div class="relative flex items-center py-3.5 px-4">
            <h2 class="font-medium text-base mr-auto">Assigned Shift(s)</h2>

            <Button
                v-if="AuthStore.hasPermission('can create employee shifts')"
                class="text-xs"
                variant="soft-secondary"
                @click="openEmployeeShiftFormSlider({})"
            >
                Assign Shift
            </Button>
        </div>
        <div class="p-2 border-t border-slate-200/60 dark:border-darkmode-400">
            <template v-if="shiftsData.length > 0">
                <ul
                    class="border border-gray-200 rounded-md divide-y divide-gray-200 mb-2 shadow"
                    role="list"
                >
                    <li
                        v-for="item in shiftsData"
                        :key="item.id"
                        class="pl-3 pr-4 py-3 flex items-center justify-between text-sm relative"
                    >
                        <div class="ml-2 flex-1 w-0 truncate">
                            <BadgeIndicator
                                v-if="isActive(item)"
                                title="Active"
                                class="absolute top-3 right-3"
                            />
                            <div class="font-bold">
                                {{ item.shift.name }}
                            </div>

                            <div
                                class="mt-5 flex justify-between text-gray-500 w-2/4"
                            >
                                <div>Effective Date:</div>
                                <div>
                                    {{
                                        moment(item.effdt).format("YYYY-MM-DD")
                                    }}
                                </div>
                            </div>

                            <div
                                class="flex justify-between text-gray-500 w-2/4"
                            >
                                <div>Effective Until:</div>
                                <div>
                                    {{
                                        item.effdt_to
                                            ? moment(item.effdt_to).format(
                                                  "YYYY-MM-DD"
                                              )
                                            : "-"
                                    }}
                                </div>
                            </div>

                            <div
                                class="flex justify-between text-gray-500 w-2/4"
                            >
                                <div>Start Time:</div>
                                <div>{{ item.shift.start_time }}</div>
                            </div>

                            <div
                                class="flex justify-between text-gray-500 w-2/4"
                            >
                                <div>End Time:</div>
                                <div>{{ item.shift.end_time }}</div>
                            </div>
                        </div>
                        <Menu
                            v-if="
                                AuthStore.hasPermission(
                                    'can update employee shifts'
                                )
                            "
                            :id="`menu_${item.id}`"
                            class="top-2.5"
                        >
                            <Menu.Button :as="Button" variant="soft-secondary">
                                <CustomIcon
                                    :height="18"
                                    :icon="'CircleDots'"
                                    :width="18"
                                />
                            </Menu.Button>
                            <Menu.Items class="w-40">
                                <Menu.Item
                                    @click="openEmployeeShiftFormSlider(item)"
                                >
                                    Edit
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    </li>
                </ul>
            </template>
            <template v-else>
                <p>No assigned shifts.</p>
            </template>
        </div>
        <FormSlider
            :header="'Employee Shift Form Details'"
            :isOpen="isEmployeeShiftFormSliderOut"
            :size="'md'"
            @close="closeSlider()"
        >
            <FormEmployeeShifts
                :data="selectedShift"
                @close="closeSlider()"
            ></FormEmployeeShifts>
        </FormSlider>
    </div>
</template>
