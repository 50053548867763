<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import moment from "moment/moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { useAuthStore } from "@/stores/authStore";
import { useClientNotificationsStore } from "@/stores/clientNotificationsStore";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import Button from "@/base-components/Button";
import { FormInput } from "@/base-components/Form";
import Lucide from "@/base-components/Lucide";
import CustomIcon from "@/pages/Icons/CustomIcon.vue";
import PageHeaderStandard from "@/pages/components/PageHeaderStandard.vue";

const router = useRouter();
const AuthStore = useAuthStore();
const ClientNotificationsStore = useClientNotificationsStore();

const initialLazyParams = {
    first: 0,
    rows: 30,
    sortField: null,
    sortOrder: null,
    filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        subject: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        from: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
        body: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    },
}

const filters = ref(initialLazyParams.filters);

const expandedRows = ref([]);

const notifications = ref();

const selectednotifications = ref();

const selectAll = ref(false);

const loading = ref(true);

const totalRecords = ref(0);

const lazyParams = ref<LazyParamsInterface>({ rows: initialLazyParams.rows });

const dt = ref();

const showClearFilter = ref(false);

const searchableCols = ref(["subject", "from", "user.name"]);

onMounted(async () => {
    filters.value = initialLazyParams.filters;
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };
    await ClientNotificationsStore.fill({});
    await loadLazyData();
});

const createNew = () => {
    router.push({ name: "clientNotificationsAdd" });
};

const loadLazyData = async () => {
    //Todo add try catch when final

    loading.value = true;
    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await ClientNotificationsStore.fill(params).then((response) => {
        //@ts-ignore Todo fix this
        notifications.value = ClientNotificationsStore.notifications.data;
        //@ts-ignore Todo fix this
        totalRecords.value = ClientNotificationsStore.notifications.total;
        loading.value = false;
    });
};

const onPage = (event: {}) => {
    lazyParams.value = event;
    loadLazyData();
};

const onSort = (event: {}) => {
    showClearFilter.value = true;
    lazyParams.value = event;
    loadLazyData();
};

const onFilter = () => {
    showClearFilter.value = true;
    lazyParams.value.filters = filters.value;
    loadLazyData();
};

const resetGlobalFilter = () => {
    showClearFilter.value = false;

    filters.value = initialLazyParams.filters;
    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };
    loadLazyData();
};

const onSelectAllChange = (event: { checked: any }) => {
    selectAll.value = event.checked;
};

const onRowSelect = () => {
    selectAll.value = selectednotifications.value.length === totalRecords.value;
};

const onRowUnselect = () => {
    selectAll.value = false;
};

const downloadAttachment = async (id: any, file_name: any) => {
    await ClientNotificationsStore.downloadAttachment({
        client_notification_id: id,
        file_name: file_name,
    });
};
</script>
<template>
    <div>
        <PageHeaderStandard :title="'Client Notifications'" />
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <Button
                v-if="
                    Object.values(AuthStore.activatedPermissionsName).includes(
                        'can create client notifications'
                    )
                "
                id="add_new"
                class="mr-2 shadow-md"
                variant="customPrimary"
                @click="createNew"
            >
                Add New Client Notification
            </Button>
            <div class="hidden md:block mx-auto text-slate-500"></div>
            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <div class="w-56 relative text-slate-500">
                    <div class="p-inputgroup">
                        <FormInput
                            id="global_search"
                            v-model="filters.global.value"
                            :value="filters.global.value"
                            class="form-control"
                            placeholder="Search..."
                            type="text"
                            @keyup.enter="onFilter"
                        />
                        <Lucide
                            class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                            icon="Search"
                        />
                    </div>
                </div>
                <button
                    id="btn_search"
                    class="ml-1 btn bg-custom-color-1000 text-white mr-1 mb-2"
                    @click="onFilter"
                ></button>
            </div>
        </div>
        <DataTable
            ref="dt"
            v-model:expandedRows="expandedRows"
            v-model:filters="filters"
            v-model:selection="selectednotifications"
            :globalFilterFields="searchableCols"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="initialLazyParams.rows"
            :rowsPerPageOptions="[30, 50, 100]"
            :totalRecords="totalRecords"
            :value="notifications"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            dataKey="id"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
        >
            <template #empty> No Notifications found.</template>
            <template #loading>
                Loading Notifications data. Please wait.
            </template>
            <Column :expander="true" headerStyle="min-width: 16px" />
            <Column :sortable="true" field="subject" header="Subject">
                <template #body="{ data }">
                    {{ data.subject }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Subject"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="from" header="Sent By">
                <template #body="{ data }">
                    {{ data.from }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by from"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="user.name" header="Created By">
                <template #body="{ data }">
                    {{ data.user.name }}
                </template>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by Created By"
                        type="text"
                    />
                </template>
            </Column>
            <Column :sortable="true" field="created_at" header="Created">
                <template #body="{ data }">
                    {{ moment(data.created_at).format("MMMM D, YYYY") }}
                </template>
            </Column>
            <template #paginatorstart>
                <!--                <Button type="button" icon="pi pi-refresh" class="p-button-text"/>-->
            </template>
            <template #paginatorend>
                <!--                <Button type="button" icon="pi pi-cloud" class="p-button-text"/>-->
            </template>
            <template #expansion="slotProps">
                <div class="orders-subtable p-10">
                    <h5>
                        <b>Email Body {{ slotProps.data.subject }}</b>
                    </h5>
                    <DataTable :value="[slotProps]" responsiveLayout="scroll">
                        <Column field="body" header="Email Body" sortable>
                            <template #body="{ data }">
                                <span v-html="data.data.body"></span>
                            </template>
                        </Column>
                    </DataTable>
                    <p class="mt-5"></p>
                    <h5>
                        <b>Recipent for {{ slotProps.data.subject }}</b>
                    </h5>
                    <DataTable
                        :value="slotProps.data.recipient"
                        responsiveLayout="scroll"
                    >
                        <Column field="type" header="type" sortable>
                            <template #body="{ data }">
                                {{ data.type }}
                            </template>
                        </Column>
                        <Column field="email_to" header="email_to" sortable>
                            <template #body="{ data }">
                                {{ data.email_to }}
                            </template>
                        </Column>
                        <Column
                            field="internal_user_flag"
                            header="internal_user_flag"
                            sortable
                        >
                            <template #body="{ data }">
                                {{ data.internal_user_flag ? "Yes" : "No" }}
                            </template>
                        </Column>
                    </DataTable>
                    <p class="mt-5"></p>
                    <h5>
                        <b>Attachment for {{ slotProps.data.subject }}</b>
                    </h5>
                    <DataTable
                        :value="slotProps.data.attachment"
                        responsiveLayout="scroll"
                    >
                        <Column field="file_name" header="file_name" sortable>
                            <template #body="{ data }">
                                {{ data.file_name }}
                            </template>
                        </Column>
                        <Column
                            field="file_path"
                            header="Download File"
                            sortable
                        >
                            <template #body="{ data }">
                                <CustomIcon
                                    :height="20"
                                    :icon="'Download'"
                                    :width="20"
                                    @click="
                                        downloadAttachment(
                                            data.client_notification_id,
                                            data.file_name
                                        )
                                    "
                                />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
</template>
