<script lang="ts" setup>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { useRoute } from "vue-router";

import { useHelperStore } from "../../../stores/helperStore";
import { useUiStore } from "../../../stores/uiStore";
import {
    defineEmits,
    onMounted,
    onUpdated,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { useTicketsStore } from "../../../stores/ticketsStore";
import Litepicker from "../../../base-components/Litepicker";
import TomSelect from "../../../base-components/TomSelect";
import Button from "../../../base-components/Button";
import { ClassicEditor } from "../../../base-components/Ckeditor";
import { FormInput, FormLabel } from "../../../base-components/Form";
import _, { orderBy, trim } from "lodash";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";

import router from "../../../router";
import { useEmployeesStore } from "../../../stores/employeesStore";
import ReadOnlyFieldTeamLeaders from "../../components/ReadOnlyFieldTeamLeaders.vue";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});

const TicketsStore = useTicketsStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const EmployeeStore = useEmployeesStore();

const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const emit = defineEmits(["output", "ticketType"]);

const ticketForm = reactive({
    employee_id: "",
    full_name: "",
    work_email: "",
    personal_email: "",
    personal_phone: "",
    client_name: "",
    client_poc_name: "",
    client_poc_email: "",
    sales_executive: "",
    team_leader: "",
    end_date: "",
    render_days: "",
    replacement_needed: "",
    reason: "",
    detailed_reason: "",
    notes: "",
});

const rules = {
    employee_id: { required },
    full_name: { required },
    work_email: { required },
    personal_email: { required },
    personal_phone: { required },
    client_name: { required },
    client_poc_name: { required },
    client_poc_email: { required },
    sales_executive: { required },
    team_leader: { required },
    end_date: { required },
    render_days: { required },
    replacement_needed: { required },
    reason: { required },
    detailed_reason: { required },
    notes: { required },
};

const attachment_files = reactive({
    data: [],
});

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

onMounted(async () => {
    await EmployeeStore.getAllEmployees();
});

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.employee_id = "";
    ticketForm.full_name = "";
    ticketForm.work_email = "";
    ticketForm.personal_email = "";
    ticketForm.personal_phone = "";
    ticketForm.client_name = "";
    ticketForm.client_poc_name = "";
    ticketForm.client_poc_email = "";
    ticketForm.sales_executive = "";
    ticketForm.team_leader = "";
    ticketForm.end_date = "";
    ticketForm.render_days = "";
    ticketForm.replacement_needed = "";
    ticketForm.reason = "";
    ticketForm.detailed_reason = "";
    ticketForm.notes = "";
};

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");
    validate.value.$reset();
    validate.value.$touch();

    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));
        const ticketFormData = new FormData();

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Employee", ticketForm.full_name);
        desc_data.append("Work Email", ticketForm.work_email);
        desc_data.append("Personal Email", ticketForm.personal_email);
        desc_data.append("Personal Phone", ticketForm.personal_phone);
        desc_data.append("Client", ticketForm.client_name);
        desc_data.append("Client POC Email", ticketForm.client_poc_email);
        desc_data.append("Client POC Name", ticketForm.client_poc_name);
        desc_data.append("Sales Executive", ticketForm.sales_executive);
        desc_data.append("Team Leader", ticketForm.team_leader);
        desc_data.append("End Date", ticketForm.end_date);
        desc_data.append("No of days to render", ticketForm.render_days);
        desc_data.append("Replacement needed?", ticketForm.replacement_needed);
        desc_data.append("Reason", ticketForm.reason);
        desc_data.append("Detailed reason", ticketForm.detailed_reason);
        desc_data.append("Notes", ticketForm.notes);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        //@ts-ignore
        const userName = window.Laravel.user.name;
        form.append("subject", "Deactivation: " + ticketForm.full_name);
        form.append("priority", ticketType?.f_priority_id?.toString() ?? "");
        form.append("status", ticketType?.f_status_id?.toString() ?? "");
        form.append("type", ticketType?.f_type?.toString() ?? "");
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? "");

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
            //console.log('all attachments', attachments);
        }

        await TicketsStore.save(form).then((response) => {
            console.log("responsxx:", response);
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", { render: true, type: "success" });
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: { id: response.data.id },
                });
            } else {
                console.log("response not success:", response);
                emit("output", { render: true, type: "danger" });
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        attachments.data.push(Array.from(files));
    }
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};

const onSelectEmployee = (event: string | string[]) => {
    const employee = EmployeeStore.employees.find((a) => a.id === +event);

    ticketForm.full_name = String(employee?.name ?? "");
    ticketForm.work_email = String(employee?.work_email ?? "");
    ticketForm.personal_email = String(employee?.personal_email ?? "");
    ticketForm.personal_phone = String(employee?.personal_phone ?? "");
    ticketForm.client_name = String(
        employee?.client?.legal_business_name ?? ""
    );
    ticketForm.client_poc_email = String(
        employee?.client?.point_of_contact?.email ?? ""
    );

    if (
        employee?.client?.point_of_contact?.first_name &&
        employee?.client?.point_of_contact?.last_name
    ) {
        ticketForm.client_poc_name = trim(
            String(employee?.client?.point_of_contact?.first_name) +
                " " +
                String(employee?.client?.point_of_contact?.last_name)
        );
    } else {
        ticketForm.client_poc_name = "";
    }
};

const salesExecutives = orderBy(
    [
        "Jenna Capili",
        "Moh Feliciano",
        "Joey Zuardo",
        "Joseph Falletti",
        "Phil Dillon",
    ],
    [(a) => a.toLowerCase()],
    ["asc"]
);

const renderDays = orderBy(
    ["15 days", "30 days", "60 days", "90 days"],
    [(a) => a.toLowerCase()],
    ["asc"]
);

const detailedReasons = orderBy(
    [
        "Personal Reasons",
        "Attendance Issue",
        "Attitude Issue",
        "Client Pull Out",
        "AWOL",
    ],
    [(a) => a.toLowerCase()],
    ["asc"]
);

const setTeamLeaders = (event: any) => {
    ticketForm.team_leader = event;
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Employee
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="employee_id"
                v-model="ticketForm.employee_id"
                class="w-full"
                name="employee_id"
                @update:modelValue="onSelectEmployee($event)"
            >
                <option value="">Select Employee</option>
                <option
                    v-for="employee in EmployeeStore.employees"
                    :value="employee.id"
                >
                    {{ employee.name }}
                </option>
            </TomSelect>
        </div>
        <template v-if="validate.employee_id.$error">
            <div
                v-for="(error, index) in validate.employee_id.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="mt-4">
            <FormLabel class="form-label" for="work_email"
                >Work Email
                <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <FormInput
                id="work_email"
                v-model="ticketForm.work_email"
                :value="ticketForm.work_email"
                class="form-control w-full"
                disabled
                maxlength="50"
                name="work_email"
                placeholder=""
                type="text"
            />
            <template v-if="validate.work_email.$error">
                <div
                    v-for="(error, index) in validate.work_email.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-4">
            <FormLabel class="form-label" for="personal_email"
                >Personal Email
                <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <FormInput
                id="personal_email"
                v-model="ticketForm.personal_email"
                :value="ticketForm.personal_email"
                class="form-control w-full"
                disabled
                maxlength="50"
                name="personal_email"
                placeholder=""
                type="text"
            />
            <template v-if="validate.personal_email.$error">
                <div
                    v-for="(error, index) in validate.personal_email.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-4">
            <FormLabel class="form-label" for="personal_phone"
                >Personal Phone
                <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <FormInput
                id="personal_phone"
                v-model="ticketForm.personal_phone"
                :value="ticketForm.personal_phone"
                class="form-control w-full"
                disabled
                maxlength="50"
                name="personal_phone"
                placeholder=""
                type="text"
            />
            <template v-if="validate.personal_phone.$error">
                <div
                    v-for="(error, index) in validate.personal_phone.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-4">
            <FormLabel class="form-label" for="client_name"
                >Client
                <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <FormInput
                id="client_name"
                v-model="ticketForm.client_name"
                :value="ticketForm.client_name"
                class="form-control w-full"
                disabled
                maxlength="50"
                name="client_name"
                placeholder=""
                type="text"
            />
            <template v-if="validate.client_name.$error">
                <div
                    v-for="(error, index) in validate.client_name.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-4">
            <FormLabel class="form-label" for="client_poc_email"
                >Client POC Email
                <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <FormInput
                id="client_poc_email"
                v-model="ticketForm.client_poc_email"
                :value="ticketForm.client_poc_email"
                class="form-control w-full"
                disabled
                maxlength="50"
                name="client_poc_email"
                placeholder=""
                type="text"
            />
            <template v-if="validate.client_poc_email.$error">
                <div
                    v-for="(error, index) in validate.client_poc_email.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-4">
            <FormLabel class="form-label" for="client_poc_name"
                >Client POC Name
                <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <FormInput
                id="client_poc_name"
                v-model="ticketForm.client_poc_name"
                :value="ticketForm.client_poc_name"
                class="form-control w-full"
                disabled
                maxlength="50"
                name="client_poc_name"
                placeholder=""
                type="text"
            />
            <template v-if="validate.client_poc_name.$error">
                <div
                    v-for="(error, index) in validate.client_poc_name.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Sales Executive
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="sales_executive"
                v-model="ticketForm.sales_executive"
                class="w-full"
                name="sales_executive"
            >
                <option value="">Select Sales Executive</option>
                <option value="N/A">N/A</option>
                <option v-for="name in salesExecutives" :value="name">
                    {{ name }}
                </option>
            </TomSelect>
        </div>
        <template v-if="validate.sales_executive.$error">
            <div
                v-for="(error, index) in validate.sales_executive.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <ReadOnlyFieldTeamLeaders
            :employee="ticketForm.employee_id"
            @item-value="setTeamLeaders($event)"
        />

        <div class="mt-4">
            <FormLabel class="form-label"
                >End Date
                <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <Litepicker
                id="end_date"
                v-model="ticketForm.end_date"
                :options="{
                    autoApply: true,
                    format: 'YYYY-MM-DD',
                    showWeekNumbers: true,
                    autoRefresh: true,
                    resetButton: true,
                    dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                    },
                }"
                class="form-control w-full block mx-auto"
                name="end_date"
            />
            <template v-if="validate.end_date.$error">
                <div
                    v-for="(error, index) in validate.end_date.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >No of days to render
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="render_days"
                v-model="ticketForm.render_days"
                class="w-full"
                name="render_days"
            >
                <option value="">Select render days</option>
                <option value="N/A">N/A</option>
                <option v-for="name in renderDays" :value="name">
                    {{ name }}
                </option>
            </TomSelect>
        </div>
        <template v-if="validate.render_days.$error">
            <div
                v-for="(error, index) in validate.render_days.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Replacement Needed?
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="replacement_needed"
                v-model="ticketForm.replacement_needed"
                class="w-full"
                name="replacement_needed"
            >
                <option value="">Select option</option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
            </TomSelect>
        </div>
        <template v-if="validate.replacement_needed.$error">
            <div
                v-for="(error, index) in validate.replacement_needed.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Reason <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="reason"
                v-model="ticketForm.reason"
                class="w-full"
                name="reason"
            >
                <option value="">Select reason</option>
                <option value="Disengaged">Disengaged</option>
                <option value="Resigned">Resigned</option>
            </TomSelect>
        </div>
        <template v-if="validate.reason.$error">
            <div
                v-for="(error, index) in validate.reason.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Detailed Reason
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="detailed_reason"
                v-model="ticketForm.detailed_reason"
                class="w-full"
                name="detailed_reason"
            >
                <option value="">Select detailed reason</option>
                <option v-for="name in detailedReasons" :value="name">
                    {{ name }}
                </option>
                <option value="Others">Others</option>
            </TomSelect>
        </div>
        <template v-if="validate.detailed_reason.$error">
            <div
                v-for="(error, index) in validate.detailed_reason.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>

        <div class="mt-3">
            <FormLabel
                >Notes <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <div class="mt-2">
                <ClassicEditor
                    id="notes"
                    v-model="ticketForm.notes"
                    :config="editorConfig"
                    name="notes"
                />
                <template v-if="validate.notes.$error">
                    <div
                        v-for="(error, index) in validate.notes.$errors"
                        id="error_notes"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning shadow-sm py-2 px-3
                    inline-flex items-center justify-center
                    rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
                >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
