<script lang="ts" setup>
import {
    defineEmits,
    defineProps,
    onMounted,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { FormLabel } from "@/base-components/Form";
import TomSelect from "@/base-components/TomSelect";
import { useShiftStore } from "@/stores/shiftStore";
// import Litepicker from "@/base-components/Litepicker";
import Button from "@/base-components/Button";
import { useRoute } from "vue-router";
import { useEmployeeShiftStore } from "@/stores/employeeShiftStore";
import moment from "moment";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import Lucide from "@/base-components/Lucide";
import { FormInput } from "@/base-components/Form";
import SliderFormSkeleton from "@/pages/components/SliderFormSkeleton.vue";
import SubmitButton from "@/pages/components/SubmitButton.vue";

const ShiftStore = useShiftStore();
const EmployeeShiftStore = useEmployeeShiftStore();

const props = defineProps({
    id: {
        type: Number,
        default: 0,
    },
    data: {
        type: Object,
        default: () => ({}),
    },
});

const loading = ref(true);
const processing = ref(false);
const route = useRoute();

const emit = defineEmits(["status", "close"]);

const closeModal = () => {
    const event = window.event; // Get the event object from the window
    if (event) {
        event.preventDefault();
    }

    console.log("close modal");
    emit("status", true);
    emit("close", true);
};

onMounted(async () => {
    await getShiftSchedules();

    if (!isEmptyObject(props.data)) {
        formData.id = props.data.id;
        formData.employee_id = String(props.data.employee_id);
        formData.shift_id = String(props.data.shift_id);
        formData.effdt = moment(props.data.effdt).format("YYYY-MM-DD");
        formData.effdt_to = props.data.effdt_to
            ? moment(props.data.effdt_to).format("YYYY-MM-DD")
            : "";
    }

    loading.value = false;
});

const formData = reactive({
    id: props.id,
    employee_id: route.params.id ?? 0,
    shift_id: "",
    effdt: "",
    effdt_to: "",
});

const externalServerValidation = () => true;

const rules = {
    shift_id: { externalServerValidation },
    effdt: { externalServerValidation },
    effdt_to: { externalServerValidation },
};
const $externalResults = ref({});
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();

const getShiftSchedules = async () => {
    await ShiftStore.getAllShifts({ dropdown: true });
};

const validate = useVuelidate(rules, toRefs(formData), { $externalResults });

const submit = async () => {
    processing.value = true;
    backendValidationErrors.value = {};
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    try {
        isEmptyObject(props.data)
            ? await save()
            : await EmployeeShiftStore.update(formData);

        closeModal();
        showSuccessNotification();
        resetForm();
    } catch (error: any) {
        backendValidationErrors.value = error?.response?.data?.errors;
        $externalResults.value = error?.response?.data?.errors;
        showErrorsNotification();
    } finally {
        processing.value = false;
    }
};

const save = async () => {
    const payload = {
        employee_id: [formData.employee_id],
        shift_id: formData.shift_id,
        effdt: formData.effdt,
        effdt_to: formData.effdt_to,
    };

    await EmployeeShiftStore.save(payload);
};

const resetForm = () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    formData.id = 0;
    formData.employee_id = route.params.id ?? 0;
    formData.shift_id = "";
    formData.effdt = "";
    formData.effdt_to = "";
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
};

const onSelectShift = (event: any) => {
    if (event === "Select Shift") {
        formData.shift_id = "";

        return;
    }

    formData.shift_id = event;
};
</script>
<template>
    <SliderFormSkeleton v-if="loading" />
    <form v-else @submit.prevent="submit">
        <div class="grid grid-cols-12 gap-2 mt-5 items-left text-left">
            <div class="intro-y col-span-12 lg:col-span-12">
                <div class="intro-y pt-5 pb-5 pl-5 pr-2">
                    <div
                        class="border border-gray-200 px-4 py-6 rounded shadow-lg"
                    >
                        <div class="mt-3">
                            <FormLabel class="form-label" for="location">
                                Shift Schedule <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <TomSelect
                                id="shift_id"
                                v-model="formData.shift_id"
                                :value="formData.shift_id"
                                class="w-full p-0"
                                name="shift_id"
                                @update:model-value="onSelectShift($event)"
                            >
                                <option selected>Select Shift</option>
                                <option
                                    v-for="schedule in ShiftStore.allShifts"
                                    :value="schedule.id"
                                >
                                    {{ schedule.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.shift_id.$error">
                                <div
                                    v-for="(error, index) in validate.shift_id.$errors"
                                    id="error_wlocation"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label">
                                Start Date <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <!-- <Litepicker
                                id="effdt"
                                v-model="formData.effdt"
                                :options="{
                                    autoApply: true,
                                    format: 'YYYY-MM-DD',
                                    showWeekNumbers: true,
                                    autoRefresh: true,
                                    resetButton: true,
                                    dropdowns: {
                                        minYear: 1990,
                                        maxYear: 2100,
                                        months: true,
                                        years: true,
                                    },
                                }"
                                class="form-control w-full block mx-auto"
                                name="effdt"
                                @change="changeEffdt($event)"
                            /> -->
                            <FormInput
                                id="effdt"
                                v-model="formData.effdt"
                                :value="formData.effdt"
                                class="form-control w-full"
                                name="effdt"
                                type="date"
                                :min="moment().format('YYYY-MM-DD')"
                                max="9999-12-31"
                                :disabled="
                                    formData.id !== 0 &&
                                    formData.effdt !== null &&
                                    moment(formData.effdt).isBefore(
                                        moment().format('YYYY-MM-DD')
                                    )
                                "
                            />
                            <template v-if="validate.effdt.$error">
                                <div
                                    v-for="(error, index) in validate.effdt.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label">
                                End Date
                            </FormLabel>
                            <!-- <Litepicker
                                id="effdt_to"
                                v-model="formData.effdt_to"
                                :options="{
                                    autoApply: true,
                                    format: 'YYYY-MM-DD',
                                    showWeekNumbers: true,
                                    autoRefresh: true,
                                    resetButton: true,
                                    dropdowns: {
                                        minYear: 2000,
                                        maxYear: 2100,
                                        months: true,
                                        years: true,
                                    },
                                }"
                                class="form-control w-full block mx-auto"
                                name="effdt_to"
                                @change="changeEffdtTo($event)"
                            /> -->
                            <FormInput
                                id="effdt_to"
                                v-model="formData.effdt_to"
                                :value="formData.effdt_to"
                                class="form-control w-full"
                                name="effdt_to"
                                type="date"
                                :min="
                                    formData.effdt ??
                                    moment().format('YYYY-MM-DD')
                                "
                                max="9999-12-31"
                            />
                            <template v-if="validate.effdt_to.$error">
                                <div
                                    v-for="(error, index) in validate.effdt_to.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="flex items-center justify-end p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    variant="warning"
                    @click="closeModal"
                >
                    Cancel
                </Button>

                <SubmitButton
                    class="w-36 mb-2 mr-1"
                    variant="customPrimary"
                    :label="formData.id ? 'Update Shift' : 'Assign Shift'"
                    :processingLabel="formData.id ? 'Updating...' : 'Assigning...'"
                    :isProcessing="processing"
                />
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Shift has been successfully {{ formData.id ? 'updated' : 'added' }}.
            </div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
