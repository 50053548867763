<script lang="ts" setup>
import { onMounted, PropType, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { isArray } from "lodash";
import moment from "moment/moment";
import { useAuthStore } from "@/stores/authStore";
import EmployeeInterface, {
    EmployeeClientAssignmentInterface,
} from "@/pages/interfaces/employeesInterface";
import { useEmployeeClientAssignmentStore } from "@/stores/employeeClientAssignmentStore";
import Button from "@/base-components/Button";
import { Menu } from "@/base-components/Headless";
// import Lucide from "@/base-components/Lucide/Lucide.vue";
import FormSlider from "@/pages/FormSlider.vue";
import CustomIcon from "@/pages/Icons/CustomIcon.vue";
import BadgeIndicator from "@/pages/components/BadgeIndicator.vue";
import CardSkeleton from "@/pages/components/CardSkeleton.vue";
import ConfirmModal from "@/pages/components/ConfirmModal.vue";
import FormEmployeeClientAssignment from "@/pages/forms/employees/FormEmployeeClientAssignment.vue";

const { employee } = defineProps({
    employee: {
        type: Object as PropType<EmployeeInterface>,
        required: true,
    },
});

const emit = defineEmits(["refresh"]);

const loading = ref(false);
const AuthStore = useAuthStore();
const slider = ref(false);
const selected = ref<EmployeeClientAssignmentInterface>();
const EmployeeClientAssignment = useEmployeeClientAssignmentStore();
const route = useRoute();
const deleteId = ref<number | null>(null);
const deleted = ref();
const showConfirmDialog = ref(false);

watch(
    () => route.params.id,
    async (newId) => {
        const id = Array.isArray(newId) ? newId[0] : newId;

        if (!isNaN(+id)) {
            loading.value = true;

            await EmployeeClientAssignment.getEmployeeClientAssignments(+id);

            loading.value = false;
        }
    }
);

onMounted(async () => {
    loading.value = true;
    await EmployeeClientAssignment.getEmployeeClientAssignments(
        +route.params.id
    );
    loading.value = false;
});

const onClickUpdate = (item: EmployeeClientAssignmentInterface) => {
    selected.value = item;
    slider.value = !slider.value;
};

const onClickDelete = (item: EmployeeClientAssignmentInterface) => {
    deleteId.value = Number(item.id);
    setConfirmDialog(true);
};

const onClickConfirm = async (event: any) => {
    if (event === null) {
        return;
    }

    if (event === false) {
        deleteId.value = null;
        return;
    }

    if (deleteId.value === null) {
        return;
    }

    await EmployeeClientAssignment.deleteEmployeeClientAssignment(
        deleteId.value
    );
    deleteId.value = null;
    deleted.value = true;
    // emit("status", true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const onClose = () => {
    slider.value = !slider.value;
    selected.value = {} as EmployeeClientAssignmentInterface;
};

const onClientUpdated = () => {
    emit('refresh', true);
};

const isActive = (assignment: EmployeeClientAssignmentInterface) => {
    if (
        (!assignment.effective_to &&
            moment().isAfter(assignment.effective_from)) ||
        (assignment.effective_to &&
            moment().isBetween(
                assignment.effective_from,
                moment(assignment.effective_to).endOf("day")
            ))
    ) {
        return true;
    }
    return false;
};
</script>
<template>
    <CardSkeleton v-if="loading" />

    <div v-else class="intro-y box mt-5 lg:mt-0 mb-5">
        <div class="relative flex items-center py-3 px-4">
            <h2 class="font-medium text-base mr-auto">Client Assignment(s)</h2>

            <Button
                v-if="
                    AuthStore.hasPermission(
                        'can create employee client assignment'
                    )
                "
                id="add-client"
                class="text-xs"
                variant="soft-secondary"
                @click="slider = !slider"
            >
                Assign Client
            </Button>
        </div>
        <div
            v-if="isArray(EmployeeClientAssignment.employeeClientAssignments)"
            class="p-2 border-t border-slate-200/60 dark:border-darkmode-400"
        >
            <ul
                class="rounded-md divide-y divide-gray-200 mb-2 shadow"
                role="list"
            >
                <li
                    v-for="(
                        item, index
                    ) in EmployeeClientAssignment.employeeClientAssignments"
                    :key="item.id"
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm relative"
                >
                    <div class="ml-2 flex-1 w-0 truncate">
                        <BadgeIndicator
                            v-if="isActive(item)"
                            title="Active"
                            class="absolute top-3 right-3"
                        />
                        <div class="font-bold">
                            {{ item.client?.legal_business_name }}
                        </div>

                        <div
                            class="mt-5 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Effective From:</div>
                            <div>
                                {{
                                    moment(item.effective_from).format(
                                        "YYYY-MM-DD"
                                    )
                                }}
                            </div>
                        </div>

                        <div class="flex justify-between text-gray-500 w-2/4">
                            <div>Effective To:</div>
                            <div>
                                {{
                                    item.effective_to
                                        ? moment(item.effective_to).format(
                                              "YYYY-MM-DD"
                                          )
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                    <Menu
                        v-if="AuthStore.hasPermission('can update employee client assignment')"
                        :id="`menu_${index}`"
                        class="top-2.5"
                    >
                        <Menu.Button :as="Button" variant="soft-secondary">
                            <CustomIcon
                                :height="18"
                                :icon="'CircleDots'"
                                :width="18"
                            />
                        </Menu.Button>
                        <Menu.Items class="w-40">
                            <Menu.Item
                                :id="`update_${index}`"
                                @click="onClickUpdate(item)"
                            >
                                Edit
                            </Menu.Item>
                            <!--
                                <Menu.Item
                                    v-if="AuthStore.hasPermission('can delete employee client assignment')"
                                    :id="`delete_${index}`"
                                    @click="onClickDelete(item)"
                                >
                                    Delete
                                </Menu.Item>
                            -->
                        </Menu.Items>
                    </Menu>
                </li>
            </ul>
        </div>
        <div
            v-if="
                EmployeeClientAssignment.employeeClientAssignments.length === 0
            "
            class="px-4 py-6 sm:px-6"
        >
            No client assignment yet.
        </div>
    </div>
    <FormSlider
        :is-open="slider"
        header="Employee Client Assignment"
        size="md"
        @close="onClose"
    >
        <FormEmployeeClientAssignment
            :employee="employee"
            :data="selected"
            @close="onClose"
            @clientUpdated="onClientUpdated"
        />
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Client assignment has been deleted successfully."
        @closeConfirmModal="setConfirmDialog(false)"
        @proceedProcess="onClickConfirm($event)"
        @deleted="deleted = $event"
    />
</template>
