<script lang="ts" setup>
import { defineProps, onMounted, provide, reactive, ref, toRefs } from "vue";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { email, maxLength, minLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useClientsStore } from "../../stores/clientsStore";
import { useHelperStore } from "../../stores/helperStore";
import moment from "moment";
import Litepicker from "../../base-components/Litepicker";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import { FormInput, FormLabel, FormSwitch } from "../../base-components/Form";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";
import ClientInterface from "../interfaces/clientsInterface";
import { useShiftStore } from "../../stores/shiftStore";
import FormTextarea from "../../base-components/Form/FormTextarea.vue";
import { useCurrencyStore } from "../../stores/currencyStore";

interface FormData {
    id: number;
    legal_business_name: string;
    trade_name: string;
    local_business_identifier: string;
    company_general_email: string;
    primary_company_number: string;
    address_line_1: string;
    address_line_2: string | null;
    city: string | null;
    state_id: string;
    zip: string | null;
    timezone: number;
    website: string | null;
    start_date: string;
    end_date: string;
    initial_staff_shift: string | null;
    shift_id: string | null;
    notes: string | null;
    default_pc_setup: string | null;
    billing_address_line_1: string;
    billing_address_line_2: string | null;
    billing_city: string | null;
    billing_state_id: string;
    billing_zip: string | null;
    currency_id: string | null;
    active: boolean;

    [key: string]: any;
}

const ClientStore = useClientsStore();
const HelperStore = useHelperStore();
const ShiftStore = useShiftStore();
const CurrencyStore = useCurrencyStore();

const props = defineProps({
    data: Object,
    id: {
        type: Number,
        required: false,
    },
});
const errors = reactive({
    message: "Client was not saved successfully. Please try again.",
});

const emit = defineEmits(["status", "close"]);

onMounted(async () => {
    console.log("On Mounted");
    console.log(props.id);

    HelperStore.states = [];
    HelperStore.billingStates = [];
    await CurrencyStore.getCurrencies();
    if (typeof props.id === "number" && props.id !== 0) {
        await getClientDetails(props.id);
        ClientStore.updateState();
    } else {
        ClientStore.addState();
        //Todo - reset form
        //@ts-ignore
        resetForm(formData as unknown as ClientInterface);
    }

    if (HelperStore.timezones.length === 0) {
        await HelperStore.fillTimezones();
    }

    await getShiftSchedules();
});

const getShiftSchedules = async () => {
    await ShiftStore.getAllShifts({ dropdown: true });
};

const formData = reactive({
    id: 0,
    legal_business_name: "",
    trade_name: "",
    local_business_identifier: "",
    company_general_email: "",
    primary_company_number: "",
    address_line_1: "",
    address_line_2: null,
    city: null,
    country_id: "",
    state_id: "",
    zip: null,
    timezone_id: "",
    website: null,
    start_date: moment(Date.now()).format("YYYY-MM-DD"),
    end_date: "",
    initial_staff_shift: null,
    shift_id: "",
    notes: null,
    default_pc_setup: "",
    billing_address_line_1: "",
    billing_address_line_2: null,
    billing_city: null,
    billing_country_id: "",
    billing_state_id: "",
    billing_zip: null,
    currency_id: "",
    active: true,
});
const $externalResults = ref({});
const rules = {
    legal_business_name: { required, minLength: minLength(2) },
    trade_name: "",
    local_business_identifier: { required },
    company_general_email: { required, email },
    primary_company_number: { required },
    address_line_1: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255),
    },
    address_line_2: "",
    city: { required, minLength: minLength(2), maxLength: maxLength(255) },
    state_id: { required },
    zip: { required, minLength: minLength(2), maxLength: maxLength(255) },
    timezone_id: { required },
    website: { required, minLength: minLength(2), maxLength: maxLength(100) },
    start_date: { required },
    shift_id: { required },
    notes: { required },
    default_pc_setup: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    billing_address_line_1: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    billing_address_line_2: "",
    billing_city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    billing_state_id: { required },
    billing_zip: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    billing_country_id: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(10),
    },
    country_id: { required, minLength: minLength(1), maxLength: maxLength(10) },
    currency_id: { required },
    active: { required },
};

const getClientDetails = async (id: number) => {
    console.log("Get Client Details");

    const client = props.data;
    if (typeof client !== "undefined") {
        formData.id = client.id;
        formData.legal_business_name = client.legal_business_name;
        formData.trade_name = client.trade_name;
        formData.local_business_identifier = client.local_business_identifier;
        formData.company_general_email = client.company_general_email;
        formData.primary_company_number = client.primary_company_number;
        formData.address_line_1 = client.address_line_1;
        formData.address_line_2 = client.address_line_2;
        formData.city = client.city;
        formData.state_id = String(client.state_id);
        formData.zip = client.zip;
        formData.timezone_id = String(client.timezone_id);
        formData.website = client.website;
        formData.start_date = client.start_date;
        //formData.end_date = client.end_date;
        formData.initial_staff_shift = client.initial_staff_shift;
        formData.shift_id = String(client?.shift_id ?? "");
        formData.notes = client.notes;
        formData.default_pc_setup = client.default_pc_setup;
        formData.billing_address_line_1 = client.billing_address_line_1;
        formData.billing_address_line_2 = client.billing_address_line_2;
        formData.billing_city = client.billing_city;
        formData.billing_state_id =
            client.billing_state !== null
                ? String(client.billing_state_id)
                : "1";
        formData.billing_zip = client.billing_zip;
        formData.billing_country_id = String(client.billing_country_id);
        formData.country_id = String(client.country_id);
        formData.currency_id = String(client.currency_id);
        formData.active = client.end_date === null;

        // await ShiftStore.getAllShifts({
        //     dropdown: true,
        //     timezone_id: formData.timezone_id,
        // });

        if (formData.country_id === formData.billing_country_id) {
            await onChange(formData.country_id, false);

            HelperStore.billingStates = HelperStore.states;
        } else {
            await onChange(formData.country_id, false);
            await onChange(formData.billing_country_id, true);
        }
    }
    console.log("formData");
    console.log(formData);
};
const validate = useVuelidate(rules, toRefs(formData), { $externalResults });

const backendValidationErrors = ref<string[]>([]);
const submit = async (): Promise<void> => {
    console.log("submit");
    validate.value.$touch();
    if (validate.value.$invalid) {
        backendValidationErrors.value = ["Incomplete or Missing required data"];
        showErrorNotification();
        return;
    }
    try {
        const response =
            formData.id === 0
                ? await ClientStore.save(formData as unknown as ClientInterface)
                : await ClientStore.update(
                      formData as unknown as ClientInterface
                  );
        if (response.status === "success") {
            showSuccessNotification();
            //resetForm(formData as unknown as Client);
            //validate.value.$reset();
            emit("status", true);
            emit("close", true);
        } else {
            console.log(response);
            backendValidationErrors.value = response.data;
            showErrorNotification();
            if (response.data == "validation.unique") {
                formData.legal_business_name = "";
            }
        }
    } catch (error: any) {
        console.log("submit error");
        console.error(error);

        if (error.response.data.status === "error") {
            backendValidationErrors.value = [error.response.data.message];
        } else {
            backendValidationErrors.value = ["Something went wrong."];
            $externalResults.value = error.response.data.errors;
        }

        showErrorNotification();
    }
};
const resetForm = (formData: FormData) => {
    console.log("Reset Form");
    const defaultFormData: FormData = {
        id: 0,
        legal_business_name: "",
        trade_name: "",
        local_business_identifier: "",
        company_general_email: "",
        primary_company_number: "",
        address_line_1: "",
        address_line_2: null,
        city: null,
        zip: null,
        timezone_id: "",
        website: null,
        start_date: moment(Date.now()).format("YYYY-MM-DD"),
        initial_staff_shift: null,
        shift_id: "",
        notes: null,
        default_pc_setup: "",
        billing_address_line_1: "",
        billing_address_line_2: null,
        billing_city: null,
        billing_zip: null,
        billing_country_id: "",
        country_id: "",
        timezone: 0,
        end_date: "",
        state_id: "",
        billing_state_id: "",
        currency_id: "",
        active: true,
    };

    for (const prop in formData) {
        formData[prop] = defaultFormData[prop];
    }
};

const closeModal = () => {
    emit("close", true);
};

const onChange = async (id: any, forBilling = false) => {
    console.log("onChange");

    if (typeof id !== undefined && id !== "") {
        await HelperStore.fillStatesById({ country_id: id }, forBilling);
    }
};

const onChangeCountry = (value: number) => {
    console.log("onChangeCountry", value);
    // if (typeof value !== undefined && value !== "") {
    //     HelperStore.fillStatesById({ country_id: value }, false);
    // }
};

const onChangeTimezone = async (event: any) => {
    ShiftStore.getAllShifts({
        dropdown: true
    });
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};

const errorNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorNotification = () => {
    errorNotificationToggle();
};
</script>
<template>
    <form @submit.prevent="submit">
        <div class="grid grid-cols-12 gap-2 mt-5 items-left text-left">
            <div class="intro-y col-span-12 lg:col-span-6">
                <!-- BEGIN: Form Layout -->
                <div class="intro-y pt-5 pb-5 pl-5 pr-2">
                    <div
                        class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Client Information
                            </h2>
                        </div>
                        <div class="mt-4">
                            <FormLabel
                                class="form-label"
                                for="legal_business_name"
                                >Legal Business Name
                                <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <!--validate.legal_business_name.$model-->
                            <FormInput
                                id="legal_business_name"
                                v-model="formData.legal_business_name"
                                :rounded="false"
                                :value="formData.legal_business_name"
                                class="form-control w-full"
                                maxlength="255"
                                name="legal_business_name"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.legal_business_name.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .legal_business_name.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="trade_name"
                                >Trade Name
                            </FormLabel>
                            <FormInput
                                id="trade_name"
                                v-model="formData.trade_name"
                                :value="formData.trade_name"
                                class="form-control w-full"
                                name="trade_name"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.trade_name.$error">
                                <div
                                    v-for="(error, index) in validate.trade_name
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                        <div class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="local_business_identifier"
                                >Local Business Identifier
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="local_business_identifier"
                                v-model="formData.local_business_identifier"
                                :value="formData.local_business_identifier"
                                class="form-control w-full"
                                name="local_business_identifier"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.local_business_identifier.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .local_business_identifier.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="company_general_email"
                                >General Email
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="company_general_email"
                                v-model="formData.company_general_email"
                                :value="formData.company_general_email"
                                class="form-control w-full"
                                name="company_general_email"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.company_general_email.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .company_general_email.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel
                                class="form-label"
                                for="primary_company_number"
                                >Primary Company Number
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="primary_company_number"
                                v-model="formData.primary_company_number"
                                :value="formData.primary_company_number"
                                class="form-control w-full"
                                name="primary_company_number"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.primary_company_number.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .primary_company_number.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div
                        class="mt-4 border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Client Primary Address
                            </h2>
                        </div>
                        <div class="mt-4">
                            <FormLabel class="form-label" for="address_line_1"
                                >Address Line 1
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="address_line_1"
                                v-model="formData.address_line_1"
                                :value="formData.address_line_1"
                                class="form-control w-full"
                                maxlength="255"
                                name="address_line_1"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.address_line_1.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .address_line_1.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="address_line_2"
                                >Address Line 2
                            </FormLabel>
                            <FormInput
                                id="address_line_2"
                                v-model="formData.address_line_2"
                                :value="formData.address_line_2"
                                class="form-control w-full"
                                name="address_line_2"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.address_line_2.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .address_line_2.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Country
                                <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <TomSelect
                                id="country_id"
                                v-model="formData.country_id"
                                :value="formData.country_id"
                                class="w-full"
                                name="country_id"
                                @update:modelValue="
                                    onChange(formData.country_id, false)
                                "
                            >
                                <option>Select Country</option>
                                <option
                                    v-for="country of HelperStore.countries"
                                    :value="country.id"
                                >
                                    {{ country.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.country_id.$error">
                                <div
                                    v-for="(error, index) in validate.country_id
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                        <div class="mt-4">
                            <FormLabel class="form-label" for="state_id"
                                >State
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="state_id"
                                v-model="formData.state_id"
                                :value="formData.state_id"
                                class="w-full"
                                name="state_id"
                            >
                                <option>Select State</option>
                                <option
                                    v-for="state of HelperStore.states"
                                    :value="state.id"
                                >
                                    {{ state.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.state_id.$error">
                                <div
                                    v-for="(error, index) in validate.state_id
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="city"
                                >City
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="city"
                                v-model="formData.city"
                                :value="formData.city"
                                class="form-control w-full"
                                maxlength="255"
                                name="city"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.city.$error">
                                <div
                                    v-for="(error, index) in validate.city
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="zip"
                                >Zipcode
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="zip"
                                v-model="formData.zip"
                                :value="formData.zip"
                                class="form-control w-full"
                                maxlength="255"
                                name="zip"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.zip.$error">
                                <div
                                    v-for="(error, index) in validate.zip
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <label for="is_active">Active</label>
                            <FormSwitch class="mt-3">
                                <FormSwitch.Input
                                    id="is_active"
                                    v-model="formData.active"
                                    :checked="formData.active"
                                    type="checkbox"
                                />
                            </FormSwitch>
                        </div>
                    </div>
                </div>
                <!-- END: Form Layout -->
            </div>
            <div class="intro-y col-span-12 lg:col-span-6">
                <!-- BEGIN: Form Layout -->
                <div class="intro-y pt-5 pb-5 pl-2 pr-5">
                    <div
                        class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Other Client Information
                            </h2>
                        </div>
                        <div class="mt-4">
                            <FormLabel class="form-label" for="website"
                                >Website
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="website"
                                v-model="formData.website"
                                :value="formData.website"
                                class="form-control w-full"
                                maxlength="100"
                                name="website"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.website.$error">
                                <div
                                    v-for="(error, index) in validate.website
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Start Date
                                <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <Litepicker
                                id="start_date"
                                v-model="formData.start_date"
                                :options="{
                                    autoApply: true,
                                    format: 'YYYY-MM-DD',
                                    showWeekNumbers: true,
                                    dropdowns: {
                                        minYear: 1990,
                                        maxYear: null,
                                        months: true,
                                        years: true,
                                    },
                                }"
                                class="form-control w-full block mx-auto"
                                name="start_date"
                            />
                            <template v-if="validate.start_date.$error">
                                <div
                                    v-for="(error, index) in validate.start_date
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Timezone
                                <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <TomSelect
                                id="timezone_id"
                                v-model="formData.timezone_id"
                                :value="formData.timezone_id"
                                class="w-full p-0"
                                name="timezone_id"
                            >
                                <option>Select Timezone</option>
                                <option
                                    v-for="timezone of HelperStore.timezones"
                                    :value="timezone.id"
                                >
                                    {{ timezone.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.timezone_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .timezone_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4">
                            <FormLabel class="form-label" for="currency_id"
                                >Currency
                                <span class="text-red-600">*</span></FormLabel
                            >
                            <div>
                                <TomSelect
                                    id="currency_id"
                                    v-model="formData.currency_id"
                                    :value="formData.currency_id"
                                    class="w-full p-0"
                                    name="currency_id"
                                >
                                    <option selected>Select Currency</option>
                                    <option
                                        v-for="currency in CurrencyStore
                                            .currencies?.data"
                                        :value="currency.id"
                                    >
                                        {{ currency.code }}
                                    </option>
                                </TomSelect>
                            </div>
                            <template v-if="validate.currency_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .currency_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="shift_id"
                                >Default Shift
                                <span class="text-red-500">*</span>
                            </FormLabel>
                            <TomSelect
                                id="shift_id"
                                v-model="formData.shift_id"
                                :value="formData.shift_id"
                                class="w-full p-0"
                                name="shift_id"
                            >
                                <option>Select Shift</option>
                                <option
                                    v-for="shift of ShiftStore.allShifts"
                                    :value="shift.id"
                                >
                                    {{ shift.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.shift_id.$error">
                                <div
                                    v-for="(error, index) in validate.shift_id
                                        .$errors"
                                    id="shift_id_error"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="notes"
                                >Notes
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="notes"
                                v-model="formData.notes"
                                :value="formData.notes"
                                class="form-control w-full"
                                name="notes"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.notes.$error">
                                <div
                                    v-for="(error, index) in validate.notes
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="default_pc_setup"
                                >Default PC setup
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="default_pc_setup"
                                v-model="formData.default_pc_setup"
                                :value="formData.default_pc_setup"
                                class="form-control w-full"
                                name="default_pc_setup"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.default_pc_setup.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .default_pc_setup.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div
                        class="mt-4 border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-base mr-auto mb-4">
                                Client Billing Address
                            </h2>
                        </div>
                        <div class="mt-4">
                            <FormLabel class="form-label" for="address_line_1"
                                >Billing Address Line 1
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="billing_address_line_1"
                                v-model="formData.billing_address_line_1"
                                :value="formData.billing_address_line_1"
                                class="form-control w-full"
                                name="billing_address_line_1"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.billing_address_line_1.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .billing_address_line_1.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label" for="address_line_2"
                                >Billing Address Line 2
                            </FormLabel>
                            <FormInput
                                id="billing_address_line_2"
                                v-model="formData.billing_address_line_2"
                                :value="formData.billing_address_line_2"
                                class="form-control w-full"
                                name="billing_address_line_2"
                                placeholder=""
                                type="text"
                            />
                            <template
                                v-if="validate.billing_address_line_2.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .billing_address_line_2.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Billing Country
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="billing_country_id"
                                v-model="formData.billing_country_id"
                                :value="formData.billing_country_id"
                                class="w-full"
                                name="billing_country_id"
                                @update:modelValue="
                                    onChange(formData.billing_country_id, true)
                                "
                            >
                                <option>Select Country</option>
                                <option
                                    v-for="country of HelperStore.countries"
                                    :value="country.id"
                                >
                                    {{ country.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.billing_country_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .billing_country_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Billing State
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <TomSelect
                                id="billing_state_id"
                                v-model="formData.billing_state_id"
                                :value="formData.billing_state_id"
                                class="w-full"
                                name="billing_state_id"
                            >
                                <option>Select State</option>
                                <option
                                    v-for="state of HelperStore.billingStates"
                                    :value="state.id"
                                >
                                    {{ state.name }}
                                </option>
                            </TomSelect>
                        </div>
                        <div class="mt-1 mb-1">
                            <template v-if="validate.billing_state_id.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .billing_state_id.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Billing City
                                <span style="color: rgb(255, 0, 0)">*</span>
                            </FormLabel>
                            <FormInput
                                id="billing_city"
                                v-model="formData.billing_city"
                                :value="formData.billing_city"
                                class="form-control w-full"
                                maxlength="100"
                                name="billing_city"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.billing_city.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .billing_city.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-3">
                            <FormLabel class="form-label"
                                >Billing Zipcode
                                <span style="color: rgb(255, 0, 0)"
                                    >*</span
                                ></FormLabel
                            >
                            <FormInput
                                id="billing_zip"
                                v-model="formData.billing_zip"
                                :value="formData.billing_zip"
                                class="form-control w-full"
                                maxlength="100"
                                name="billing_zip"
                                placeholder=""
                                type="text"
                            />
                            <template v-if="validate.billing_zip.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .billing_zip.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- END: Form Layout -->
            </div>
        </div>
    </form>
    <div class="">
        <div class="text-right p-2">
            <Button
                id="btn_cancel"
                class="w-32 mb-2 mr-1"
                variant="warning"
                @click="closeModal"
            >
                Cancel
            </Button>
            <Button
                id="btn_process"
                class="w-32 mb-2 mr-1"
                variant="customPrimary"
                @click="submit"
            >
                {{ ClientStore.getMode }} Client
            </Button>
        </div>
    </div>
    <!-- Client Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">Client Saved Successfully</div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
    <!-- Client Success Notification -->
</template>
