<script setup lang="ts">
import { provide, reactive, ref, toRefs, onMounted, defineProps, defineEmits, computed } from "vue";
import { useRoute } from "vue-router";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useClientEmployeeRoleStore } from "@/stores/clientEmployeeRoleStore";
import { useEmployeesStore } from "@/stores/employeesStore";
import Button from "@/base-components/Button";
import { FormLabel } from "@/base-components/Form";
import Lucide from "@/base-components/Lucide";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import TomSelect from "@/base-components/TomSelect";

const EmployeeStore = useEmployeesStore();
const ClientEmployeeStore = useClientEmployeeRoleStore();
const route = useRoute();
const emit = defineEmits(["close"]);

const props = defineProps({
    internal_staffs: {
        type: Object,
        default: {},
    },
});

const form = reactive({
    client_id: route?.params?.id ?? 0,
    agents: [],
    role: "",
});
const rules = {
    agents: {
        required,
    },
};
const backendValidationErrors = ref({});
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();

const deleteConfirmation = (values: string[]) => {
    let message: string;

    if (values.length > 1) {
        message = `Are you sure you want to remove these ${values.length} items?`;
    } else {
        const agent = EmployeeStore.employees.find(
            (x) => x.id === parseInt(values[0])
        );

        message = `Are you sure you want to remove ${agent?.name}?`;
    }

    return confirm(message);
};

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const close = () => {
    form.agents = [];
    emit("close");
};

const onSubmit = async () => {
    console.log("onSubmit");
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;
    }

    try {
        await ClientEmployeeStore.saveClientEmployee(form);

        showSuccessNotification();
        close();
    } catch (error: any) {
        $externalResults.value = error.response.data.errors;

        backendValidationErrors.value = {
            message: ["Something went wrong."],
        };

        showErrorsNotification();
    }
};

onMounted(async () => {
    await EmployeeStore.getAllEmployees({sales: true});
});

const filteredEmployees = computed(() => {
    //@ts-ignore
    const internalStaffIds = props.internal_staffs.map(staff => staff.employee.id);

    return EmployeeStore.employees.filter(employee => 
        !internalStaffIds.includes(employee.id)
    );
});
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y p-5">
                    <div>
                        <FormLabel class="form-label" for="name"
                            >Internal Staff <span class="text-red-500">*</span>
                        </FormLabel>

                        <TomSelect
                            id="agents"
                            v-model="form.agents"
                            :options="{
                                placeholder: 'Select internal staffs',
                                onDelete: (values: string[]) => deleteConfirmation(values),
                            }"
                            class="w-full"
                            multiple
                            name="agents"
                        >
                        <option
                            v-for="employee in filteredEmployees"
                            :key="employee.id"
                            :value="employee.id"
                        >
                            {{ employee.name }}
                        </option>
                        </TomSelect>
                    </div>
                    <template v-if="validate.agents.$error">
                        <div
                            v-for="(error, index) in validate.agents.$errors"
                            :key="index"
                            class="text-danger mt-2"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    @click="close()"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    id="btn_process"
                    class="w-36 mb-2 mr-1"
                    variant="customPrimary"
                >
                    Save
                </Button>
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Sales agent has been successfully added.
            </div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>

<style scoped></style>
