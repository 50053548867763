<script lang="ts" setup>
import { onMounted, provide, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import { useAuthStore } from "@/stores/authStore";
import { useHelperStore } from "@/stores/helperStore";
import { useTicketsStore } from "@/stores/ticketsStore";
import TicketsInterface from "@/pages/interfaces/ticketsInterface";
import Button from "@/base-components/Button";
import ClassicEditor from "@/base-components/Ckeditor/ClassicEditor.vue";
import Lucide from "@/base-components/Lucide";
import { Menu } from "@/base-components/Headless";
import TomSelect from "@/base-components/TomSelect";
import Notification, { NotificationElement } from "@/base-components/Notification";
import Avatar from "@/pages/components/Avatar.vue";
import CustomIcon from "@/pages/Icons/CustomIcon.vue";
import ErrorPage from "@/pages/components/404.vue";
import FormSlider from "@/pages/FormSlider.vue";
import FormUpload from "@/pages/forms/FormUpload.vue";

const route = useRoute();
const id = Array.isArray(route.params.id)
    ? route.params.id[0]
    : route.params.id;

const emit = defineEmits(["status", "close"]);

const userIsApprover = ref(false);
const showApprovingBox = ref(false);
const ticketIsViewable = ref(true);
const showApproveBtn = ref(false);
const showCheckList = ref(false);
// @ts-ignore
const authId = ref(window.Laravel.user.id);

const showPage = ref(true);

const successNoteNotification = ref<NotificationElement>();
const successNoteNotificationToggle = () => {
    // Show notification
    successNoteNotification.value?.showToast();
};
provide("bind[successNoteNotification]", (el: NotificationElement) => {
    successNoteNotification.value = el;
});
const errorNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const ticketType = reactive<{
    data: {
        id: number;
        name: string;
        sequence: string[];
    };
}>({
    data: {
        id: 0,
        name: "",
        sequence: [],
    },
});

const isTicketCreator = ref(false);
const showApprovalButtons = ref(true);

const AuthStore = useAuthStore();

const ticket = reactive<TicketsInterface>({
    api_response: "",
    attachments_link: "",
    cc_emails: "",
    due_by: "",
    f_ticket_id: "",
    form_body: "",
    id: 0,
    is_escalated: "",
    requester_id: "",
    tags: "",
    ticket_type_id: 0,
    tid: "",
    tname: "",
    uname: "",
    urgent: "",
    user_id: "",
    created_at: "",
    approvals: [],
    notes: [],
    user: null,
    attachments: [],
    ticketType: null,
    status: null,
});

let formNote = reactive({
    note: "",
    ticket_id: ticket.id,
    note_type: "1",
});
const rules = {
    note: { required },
};
const $externalResults = ref({});
const backendValidationErrors = ref({});

const resetForm = () => {
    formNote.note = "";
};

const validate = useVuelidate(rules, toRefs(formNote), { $externalResults });

const HelperStore = useHelperStore();
const TicketsStore = useTicketsStore();

onMounted(async () => {
    console.log("mounted ticket details");
    
    //await TicketsStore.fill();
    await populateTicketDetails();

    setTimeout(function () {
        loading.value = false;
    }, 3000);
});

const populateTicketDetails = async () => {
    console.log("populateTicketDetails");

    await HelperStore.fillTicketTypes();
    await TicketsStore.getTicketDetails({ id: parseInt(id) }).then(
        (response) => {
            //@ts-ignore
            if (response.status == "success") {
                const ticketData = (response as { status: string; data: any })
                    .data;

                if (ticketData.permission.view) {
                    ticketIsViewable.value = true;
                    ticket.ticketType = ticketData.ticket.types;
                    console.log(ticket.ticketType);
                    ticket.api_response = ticketData.ticket.api_response;
                    ticket.attachments_link =
                        ticketData.ticket.attachments_link;
                    ticket.cc_emails = ticketData.ticket.cc_emails;
                    ticket.due_by = ticketData.ticket.due_by;
                    ticket.f_ticket_id = ticketData.ticket.f_ticket_id;
                    ticket.form_body = ticketData.ticket.form_body;
                    ticket.id = ticketData.ticket.id;
                    ticket.is_escalated = ticketData.ticket.is_escalated;
                    ticket.requester_id = ticketData.ticket.requester_id;
                    ticket.tags = ticketData.ticket.tags;
                    ticket.ticket_type_id = ticketData.ticket.ticket_type_id;
                    ticket.tid = ticketData.ticket.tid;
                    ticket.tname = ticketData.ticket.tname;
                    ticket.uname = ticketData.ticket.uname;
                    ticket.urgent = ticketData.ticket.urgent;
                    ticket.user_id = ticketData.ticket.user_id;
                    ticket.created_at = ticketData.ticket.created_at;

                    ticket.attachments = ticketData.ticket.attachments;
                    ticket.notes = ticketData.ticket.notes;
                    ticket.approvals = ticketData.ticket.approvals;
                    ticket.status = ticketData.ticket.status;
                    ticket.user = ticketData.ticket.user;

                    showCheckList.value = true;
                    //@ts-ignore
                    ticketType.data = HelperStore.getTicketType(
                        ticket.ticket_type_id
                    );
                    ticketType.data.sequence =
                        HelperStore.getTicketDetailsBodySequence(
                            ticketType.data.name
                        );

                    //todo:: validations and permissions
                    //if user == creator  == true
                    //if user != creator and not tl or manager = false
                    //if user == TL and has 1 approvals already = false
                    //if user == manager and has 0 or 1 approvals = true
                    //if user has approval entry already == reject
                    console.log("approval ticket data", ticketData);

                    userIsApprover.value =
                        (AuthStore.hasPermission(
                            "can approve assigned tickets"
                        ) ||
                            AuthStore.hasPermission(
                                "can approve all tickets"
                            )) &&
                        ticketData.ticket?.num_approvals_required > 0;

                    showApproveBtn.value =
                        (AuthStore.hasPermission(
                            "can approve assigned tickets"
                        ) ||
                            AuthStore.hasPermission(
                                "can approve all tickets"
                            )) &&
                        ticketData.ticket?.num_approvals_required > 0;
                    //@ts-ignore
                    if (window.Laravel.user.id == ticket.user_id) {
                        isTicketCreator.value = true;
                        console.log("isTicketCreators", isTicketCreator.value);
                    }

                    if (
                        showApproveBtn.value === true &&
                        //@ts-ignore
                        ticket.status.name !== "Approved"
                    ) {
                        showApprovingBox.value = true;
                    }
                    //@ts-ignore
                    if (ticket.status.name === "Not Approved") {
                        showApprovingBox.value = false;
                    }
                } else {
                    ticketIsViewable.value = false;
                }
            } else {
                ticketIsViewable.value = false;
            }
        }
    );
};



const getTicketTypeName = (id: any) => {
    return ticketType.data.name;
};

const approveTicket = async (data: { id: any }) => {
    console.log("approve ticket");
    approving.value = true;
    // showApprovalButtons.value = false;
    await TicketsStore.saveApprovals({
        id: data.id,
        approve: true,
        ticket_type_id: ticketType.data.id,
    })
        .then((response) => {
            if (response.status == "success") {
                showApprovingBox.value = false;
            }
            populateTicketDetails();
        })
        .finally(() => {
            approving.value = false;
        });
};
const declineTicket = async (data: { id: any }) => {
    showApprovalButtons.value = false;
    declining.value = true;
    await TicketsStore.saveApprovals({
        id: data.id,
        approve: false,
        ticket_type_id: ticketType.data.id,
    })
        .then((response) => {
            if (response.status == "success") {
                showApprovingBox.value = false;
            }
            populateTicketDetails();
        })
        .finally(() => {
            declining.value = false;
        });
};

const loading = ref(true);
const approving = ref(false);
const declining = ref(false);
const addNote = async () => {
    loading.value = true;
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        emit("status", false);

        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();

        setTimeout(function () {
            loading.value = false;
        }, 3000);
    } else {
        formNote.ticket_id = ticket.id;
        await TicketsStore.saveNote(formNote)
            .then((response) => {
                console.log("save note response", response);
                // if (response.status === "success") {
                resetForm();
                validate.value.$reset();

                successNoteNotificationToggle();

                setTimeout(function () {
                    populateTicketDetails();
                }, 2000);
                // } else {
                //     console.log("show toast error");
                // }
            })
            .catch((error: any) => {
                backendValidationErrors.value = {
                    message: ["Incomplete or Missing required data"],
                };

                $externalResults.value = error?.response?.data?.errors;
                showErrorsNotification();
            })
            .finally(() => {
                setTimeout(function () {
                    loading.value = false;
                }, 3000);
            });
    }
};
const downloadAttachment = async (ticket_id: any, file_name: any) => {
    console.log("downloadAttachment:", ticket_id);
    console.log("downloadAttachment:", file_name);

    await TicketsStore.downloadAttachment({
        ticket_id: ticket_id,
        file_name: file_name,
    });
};
let showSlider = ref(false);
const updateEmployee = async (data: { id: number }) => {
    console.log("updateEmployee", data);
    showSlider.value = true;
    formEmployeeSettings.id = data.id;
};
const formEmployeeSettings = reactive({
    id: 0,
});

const closeSlider = () => {
    console.log("closeSlider");
    populateTicketDetails();
    formEmployeeSettings.id = 0;
    showSlider.value = false;
};

const formEmployeeStatus = (status: any) => {
    console.log("status", status);
    if (status) {
        //loadLazyData();
    }
};
const stripEmail = (str: string) => {
    console.log("stripEmail");
    return str.replace(/([^.@\s]+)(\.[^.@\s]+)*@([^.@\s]+\.)+([^.@\s]+)/, "");
};

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "insertTable"],
    },
};
</script>
<template>
    <div v-if="showPage">
        <div v-if="ticketIsViewable">
            <div class="intro-y flex items-center mt-8">
                <h2 class="text-lg font-medium mr-auto">Ticket Details</h2>
            </div>
            <div class="grid grid-cols-12 gap-6 mt-5">
                <div class="col-span-8 lg:col-span-8 2xl:col-span-8 flex lg:block flex-col-reverse">
                    <div class="intro-y box mt-5 lg:mt-0 shadow-lg">
                        <div class="p-5 border-t border-slate-200/60 dark:border-darkmode-400">
                            <div class="flex space-x-3"></div>

                            <div class="relative flex items-center text-sm">
                                <div class="w-full mr-auto">
                                    <div class="surface-section">
                                        <div class="grid grid-cols-12 gap-y-6">
                                            <div
                                                v-for="fieldname in ticketType.data.sequence"
                                                class="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12"
                                            >
                                                <div class="text-slate-500">
                                                    {{ fieldname }}
                                                </div>
                                                <div class="mt-1.5 flex items-center">
                                                    <div class="text-lg">
                                                        <span v-html="JSON.parse(ticket.form_body)[fieldname]"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 intro-y box col-span-12 2xl:col-span-6 shadow-lg">
                        <div class="flex items-center px-5 py-3 border-b border-slate-200/60 dark:border-darkmode-400">
                            <h2 class="font-medium text-base mr-auto">Notes</h2>
                        </div>
                        <div class="divide-y divide-gray-200">
                            <div class="px-4 py-6 sm:px-6">
                                <ul class="space-y-8" role="list">
                                    <li
                                        v-for="note in ticket.notes"
                                        :key="note.id"
                                    >
                                        <div class="flex space-x-3">
                                            <div class="w-12 h-12 image-fit">
                                                <Avatar
                                                    :name="
                                                        note.user
                                                            ? note.user.name
                                                            : stripEmail(note.from)
                                                    "
                                                    :ring="'online'"
                                                    :size="'sm'"
                                                />
                                            </div>
                                            <div class="flex-1 ml-2">
                                                <div class="text-sm">
                                                    <a
                                                        class="font-medium text-gray-900"
                                                        href="#"
                                                    >
                                                        {{
                                                            note.user
                                                                ? note.user.name
                                                                : stripEmail(note.from)
                                                        }}
                                                    </a>
                                                </div>
                                                <div class="mt-1 text-sm text-gray-700 overflow-x-auto">
                                                    <div
                                                        class="prose prose-td:border prose-td:p-2"
                                                        v-html="
                                                            note.notes_html
                                                                ? note.notes_html
                                                                : note.notes
                                                        "
                                                    ></div>
                                                </div>
                                                <div class="mt-2 text-sm space-x-2">
                                                    <span class="text-gray-500 font-medium">
                                                        {{ moment(note.created_at).format("MMMM Do YYYY, h:mm:ss a") }}
                                                    </span>
                                                    {{ " " }}
                                                    <span class="text-gray-500 font-medium">&middot;</span>
                                                    {{ " " }}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div v-if="ticket.notes.length == 0">
                                    No notes yet.
                                </div>
                            </div>
                        </div>

                        <div class="bg-gray-50 px-4 py-6 sm:px-6">
                            <div class="flex space-x-3">
                                <div class="min-w-0 flex-1">
                                    <form action="#">
                                        <div>
                                            <label class="sr-only" for="notes">
                                                Notes
                                            </label>
                                            <ClassicEditor
                                                id="body"
                                                v-model.trim="formNote.note"
                                                :config="editorConfig"
                                                name="body"
                                            />
                                            <!--                                            <textarea-->
                                            <!--                                                id="notes"-->
                                            <!--                                                v-model="formNote.note"-->
                                            <!--                                                class="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"-->
                                            <!--                                                name="notes"-->
                                            <!--                                                placeholder="Add a note"-->
                                            <!--                                                rows="3"-->
                                            <!--                                            ></textarea>-->
                                            <template v-if="validate.note.$error">
                                                <div
                                                    v-for="(error, index) in validate.note.$errors"
                                                    :key="index"
                                                    class="text-danger mt-2"
                                                >
                                                    {{ error.$message }}
                                                </div>
                                            </template>
                                        </div>
                                        <div class="mt-3">
                                            <label
                                                class="form-label"
                                                for="crud-form-2"
                                            >
                                                Note Type
                                            </label>
                                            <TomSelect
                                                id="note_type"
                                                v-model="formNote.note_type"
                                                class="w-full"
                                                name="note_type"
                                            >
                                                <option value="1">Public Note</option>
                                                <option value="2">Private Note</option>
                                                <option value="3">Reply</option>
                                            </TomSelect>
                                        </div>
                                        <div class="mt-3 flex items-center justify-between">
                                            <a
                                                class="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900"
                                                href="#"
                                            >
                                                <span> </span>
                                            </a>
                                            <Button
                                                :disabled="loading"
                                                class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-custom-color-1000 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                type="button"
                                                @click="addNote()"
                                            >
                                                Add a note
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 intro-y box col-span-12 lg:col-span-6 shadow-lg">
                        <div class="flex items-center px-5 py-5 sm:py-3 border-b border-slate-200/60 dark:border-darkmode-400">
                            <h2 class="font-medium text-base mr-auto">
                                Ticket Attachments
                            </h2>
                            <!--                            <Menu>-->
                            <!--                                <Menu.Button tag="a" class="w-5 h-5 block" href="javascript:;">-->
                            <!--                                    <CustomIcon :width="18" :height="18" :icon="'CircleDots'"/>-->
                            <!--                                </Menu.Button>-->
                            <!--                                <Menu.Items class="w-40">-->
                            <!--                                    <Menu.Item>All Files</Menu.Item>-->
                            <!--                                </Menu.Items>-->
                            <!--                            </Menu>-->
                            <Button
                                variant="customPrimary"
                                @click="updateEmployee(ticket)"
                            >
                                Upload a file
                            </Button>
                        </div>
                        <div
                            v-for="attachment of ticket.attachments"
                            :key="attachment.id"
                            class="p-5"
                        >
                            <div class="flex items-center">
                                <div class="file">
                                    <CustomIcon
                                        :height="20"
                                        :icon="'Paperclip'"
                                        :width="20"
                                    />
                                </div>
                                <div class="ml-4">
                                    <a
                                        class="font-medium cursor-pointer"
                                        @click="downloadAttachment(ticket.id, attachment.name)"
                                    >
                                        {{ attachment.name }}
                                    </a>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                        {{ attachment.file_size }}
                                    </div>
                                </div>
                                <Menu class="ml-auto">
                                    <Menu.Button
                                        class="w-5 h-5 block"
                                        href="javascript:;"
                                        tag="a"
                                    >
                                        <CustomIcon
                                            :height="18"
                                            :icon="'CircleDots'"
                                            :width="18"
                                        />
                                    </Menu.Button>
                                    <Menu.Items class="w-40">
                                        <Menu.Item @click="downloadAttachment(ticket.id, attachment.name)">
                                            Download
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>
                            </div>
                        </div>
                        <div
                            v-if="isEmpty(ticket.attachments)"
                            class="px-4 py-6 sm:px-6"
                        >
                            No attachments yet.
                        </div>
                    </div>
                </div>
                <!-- END: Profile Menu -->

                <div class="col-span-4 -mt-5">
                    <div class="report-box-4__content xl:min-h-0 intro-x">
                        <div class="max-h-full xl:overflow-y-auto box mt-5 shadow-lg">
                            <div class="xl:sticky top-0 px-5 pt-5 py-3 border-b border-gray-200">
                                <div class="flex items-center">
                                    <div class="text-lg font-medium truncate mr-5">
                                        Properties
                                    </div>
                                </div>
                            </div>

                            <div class="p-4">
                                <div class="mt-2 col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                                    <div class="text-slate-500">
                                        Ticket Type
                                    </div>
                                    <div class="mt-1.5 flex items-center">
                                        <div class="text-lg">
                                            {{ getTicketTypeName(ticket.ticket_type_id) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                                    <div class="text-slate-500">
                                        Date Created
                                    </div>
                                    <div class="mt-1.5 flex items-center">
                                        <div class="text-lg">
                                            {{ moment(ticket.created_at).format("MMMM Do YYYY, h:mm:ss a") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                                    <div class="text-slate-500">
                                        Submitted By
                                    </div>
                                    <div class="mt-1.5 flex items-center">
                                        <div class="text-lg">
                                            {{
                                                ticket.user
                                                    ? ticket.user.name
                                                    : "N/A"
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                                    <div class="text-slate-500">Approver</div>
                                    <div class="mt-1.5 flex items-center">
                                        <div
                                            v-if="ticket.form_body"
                                            class="text-lg"
                                        >
                                            {{
                                                JSON.parse(ticket.form_body)["Approver"]
                                                    ? JSON.parse(ticket.form_body)["Approver"]
                                                    : "N/A"
                                            }}
                                        </div>
                                        <div v-else class="text-lg">N/A</div>
                                    </div>
                                </div>
                                <div class="mt-2 col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-12">
                                    <div class="text-slate-500">Status</div>
                                    <div class="mt-1.5 flex items-center">
                                        <div class="text-lg">
                                            {{
                                                ticket.status
                                                    ? ticket.status.name
                                                    : "N/A"
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div
                        v-if="
                            userIsApprover &&
                            showApprovingBox &&
                            ticket?.approvals.some(
                                (a) =>
                                    (+a.user_id === authId &&
                                        a.is_approved === 0 &&
                                        a.is_declined === 0) ||
                                    AuthStore.role[0] === 'hr' ||
                                    AuthStore.role[0] === 'admin'
                            )
                        "
                        class="intro-y box p-5 bg-custom-color-800 text-white mt-5"
                    >
                        <div class="flex items-center">
                            <div class="font-medium text-lg">
                                Approve this ticket?
                            </div>
                        </div>
                        <div class="mt-4"></div>
                        <div class="font-medium flex mt-5">
                            <Button
                                v-if="showApproveBtn"
                                id="approveTicketBtn"
                                class="btn py-1 px-2 text-white dark:text-slate-300 dark:bg-darkmode-400 dark:border-darkmode-400 shadow-lg"
                                type="button"
                                :disabled="approving || declining"
                                @click="approveTicket(ticket)"
                            >
                                <div v-if="approving" class="flex items-center">
                                    <svg
                                        aria-hidden="true"
                                        class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                        fill="none"
                                        viewBox="0 0 100 101"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>

                                    <div>Approving...</div>
                                </div>

                                <div v-else>Approve</div>
                            </Button>
                            <Button
                                class="btn py-1 px-2 border-transparent text-white dark:border-transparent ml-auto"
                                type="button"
                                :disabled="approving || declining"
                                @click="declineTicket(ticket)"
                            >
                                <div v-if="declining" class="flex items-center">
                                    <svg
                                        aria-hidden="true"
                                        class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                        fill="none"
                                        viewBox="0 0 100 101"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>

                                    <div>Declining...</div>
                                </div>

                                <div v-else>Decline</div>
                            </Button>
                        </div>
                    </div> -->

                    <div
                        v-if="
                            ticket.ticketType &&
                            !ticket.ticketType.send_to_fdesk_on_create
                        "
                        class="intro-y box p-5 bg-custom-color-800 text-white mt-5"
                    >
                        <div class="flex items-center justify-between">
                            <h2 class="text-lg font-medium text-white">
                                Ticket Status / CheckList
                            </h2>

                            <div>
                                <svg
                                    class="w-12 h-12 text-white mr-3 icon icon-tabler icon-tabler-checklist"
                                    fill="none"
                                    height="24"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    />
                                    <path
                                        d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8"
                                    />
                                    <path d="M14 19l2 2l4 -4"></path>
                                    <path d="M9 8h4"></path>
                                    <path d="M9 12h2"></path>
                                </svg>
                            </div>
                        </div>

                        <div class="w-52 sm:w-auto mx-auto mt-12">
                            <ul
                                v-for="approval in ticket.approvals"
                                class="grid grid-rows-2"
                                role="list"
                            >
                                <li>
                                    <div class="flex items-center justify-between">
                                        <div class="flex items-center">
                                            <div
                                                v-if="
                                                    approval.is_approved === 0 &&
                                                    approval.is_declined === 0
                                                "
                                                class="w-2 h-2 bg-white rounded-full mr-3"
                                            ></div>
                                            <div
                                                v-if="approval.is_declined === 1"
                                                class="w-2 h-2 bg-danger rounded-full mr-3"
                                            ></div>
                                            <div
                                                v-if="approval.is_approved === 1"
                                                class="w-2 h-2 bg-white rounded-full mr-3"
                                            ></div>
                                            <div>
                                                <span
                                                    v-if="
                                                        approval.is_approved === 0 &&
                                                        approval.is_declined === 0
                                                    "
                                                    class="truncate"
                                                >
                                                    <!-- <span
                                                        v-if="
                                                            approval.ticket_rule
                                                                .rules ===
                                                                'requires finance role approval' &&
                                                            ticket.ticketType
                                                                .id === 2
                                                        "
                                                    >
                                                        requires finance role
                                                        approval
                                                    </span> -->
                                                    <span>
                                                        {{ approval.ticket_rule.rules }}
                                                    </span>
                                                </span>
                                                <span
                                                    v-if="
                                                        approval.is_approved === 0 &&
                                                        approval.is_declined === 1
                                                    "
                                                    class="truncate font-bold"
                                                >
                                                    Declined By: {{ approval?.user?.name }}
                                                </span>

                                                <span
                                                    v-if="
                                                        approval.is_approved === 1 &&
                                                        approval.is_declined === 0
                                                    "
                                                    class="truncate font-bold"
                                                >
                                                    Approved By: {{ approval?.user?.name }}
                                                </span>
                                            </div>

                                            <div v-if="approval.is_approved === 1">
                                                <span class="font-medium ml-auto">
                                                    <svg
                                                        class="ml-4 w-4 h-4 text-white icon icon-tabler icon-tabler-check"
                                                        fill="none"
                                                        height="24"
                                                        stroke="currentColor"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                            stroke="none"
                                                        />
                                                        <path
                                                            d="M5 12l5 5l10 -10"
                                                        />
                                                    </svg>
                                                </span>
                                                <span
                                                    v-if="approval.is_declined === 1"
                                                    class="font-medium ml-auto"
                                                >
                                                    <svg
                                                        class="ml-4 w-4 h-4 text-danger icon icon-tabler icon-tabler-ban"
                                                        fill="none"
                                                        height="24"
                                                        stroke="currentColor"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                            stroke="none"
                                                        />
                                                        <path
                                                            d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"
                                                        />
                                                        <path
                                                            d="M5.7 5.7l12.6 12.6"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <template
                                                v-if="
                                                   (
                                                    (
                                                    (AuthStore.role.includes('team lead') ||
                                                    AuthStore.activatedPermissionsName.includes('can approve ' +  ticket.ticketType?.name.toLocaleLowerCase())
                                                    ) &&
                                                    authId === approval.user_id
                                                    ) || 
                                                   
                                                    (AuthStore.role.includes('account manager') ||
                                                    AuthStore.activatedPermissionsName.includes('can approve ' + ticket.ticketType?.name.toLocaleLowerCase())
                                                    ) &&
                                                    approval.user_id !== null
                                                   ) && ticket.user_id !== authId
                                                    "
                                            >
                                                <div
                                                    v-if="showApprovalButtons"
                                                    class="flex items-center"
                                                >
                                                    <Button
                                                        v-if="
                                                            approval.is_approved === 0 &&
                                                            approval.is_declined === 0
                                                        "
                                                        id="approveTicketBtn"
                                                        class="btn py-1 px-2 text-white dark:text-slate-300 dark:bg-darkmode-400 dark:border-darkmode-400 shadow-lg"
                                                        type="button"
                                                        :disabled="approving || declining"
                                                        @click="approveTicket(ticket)
                                                        "
                                                    >
                                                        <div
                                                            v-if="approving"
                                                            class="flex items-center"
                                                        >
                                                            <svg
                                                                aria-hidden="true"
                                                                class="w-3 h-3 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                fill="none"
                                                                viewBox="0 0 100 101"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                    fill="currentColor"
                                                                />
                                                                <path
                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                    fill="currentFill"
                                                                />
                                                            </svg>

                                                            <div>
                                                                Approving...
                                                            </div>
                                                        </div>

                                                        <div v-else>
                                                            Approve
                                                        </div>
                                                    </Button>

                                                    <Button
                                                        v-if="
                                                            approval.is_approved === 0 &&
                                                            approval.is_declined === 0
                                                        "
                                                        class="btn py-1 px-2 border-white text-white dark:border-transparent ml-4"
                                                        type="button"
                                                        :disabled="approving || declining"
                                                        @click="declineTicket(ticket)"
                                                    >
                                                        <div
                                                            v-if="declining"
                                                            class="flex items-center"
                                                        >
                                                            <svg
                                                                aria-hidden="true"
                                                                class="w-3 h-3 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                fill="none"
                                                                viewBox="0 0 100 101"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                    fill="currentColor"
                                                                />
                                                                <path
                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                    fill="currentFill"
                                                                />
                                                            </svg>

                                                            <div>
                                                                Declining...
                                                            </div>
                                                        </div>

                                                        <div v-else>
                                                            Decline
                                                        </div>
                                                    </Button>
                                                </div>
                                            </template>

                                            <template
                                                v-else-if="
                                                    ( 
                                                    AuthStore.role.includes('hr') ||
                                                    AuthStore.role.includes('admin') ||
                                                    (
                                                        AuthStore.role.includes('finance') ||
                                                        AuthStore.role.includes('payroll') &&
                                                        ticket.ticketType.id === 2
                                                    ) ||
                                                    (AuthStore.role.includes('it') && 
                                                    (ticket.ticketType.id === 28 || ticket.ticketType.id === 30)
                                                    )
                                                    ) && ticket.user_id !== authId
                                                "
                                            >
                                                <div
                                                    v-if="showApprovalButtons"
                                                    class="flex items-center"
                                                >
                                                    <Button
                                                        v-if="
                                                            approval.is_approved === 0 &&
                                                            approval.is_declined === 0
                                                        "
                                                        id="approveTicketBtn"
                                                        class="btn py-1 px-2 text-white dark:text-slate-300 dark:bg-darkmode-400 dark:border-darkmode-400 shadow-lg"
                                                        type="button"
                                                        :disabled="approving || declining"
                                                        @click="approveTicket(ticket)"
                                                    >
                                                        <div
                                                            v-if="approving"
                                                            class="flex items-center"
                                                        >
                                                            <svg
                                                                aria-hidden="true"
                                                                class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                fill="none"
                                                                viewBox="0 0 100 101"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                    fill="currentColor"
                                                                />
                                                                <path
                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                    fill="currentFill"
                                                                />
                                                            </svg>

                                                            <div>
                                                                Approving...
                                                            </div>
                                                        </div>

                                                        <div v-else>
                                                            Approve
                                                        </div>
                                                    </Button>

                                                    <Button
                                                        v-if="
                                                            approval.is_approved === 0 &&
                                                            approval.is_declined === 0
                                                        "
                                                        class="btn py-1 px-2 border-white text-white dark:border-transparent ml-4"
                                                        type="button"
                                                        :disabled="approving || declining"
                                                        @click="declineTicket(ticket)"
                                                    >
                                                        <div
                                                            v-if="declining"
                                                            class="flex items-center"
                                                        >
                                                            <svg
                                                                aria-hidden="true"
                                                                class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                                                fill="none"
                                                                viewBox="0 0 100 101"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                    fill="currentColor"
                                                                />
                                                                <path
                                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                    fill="currentFill"
                                                                />
                                                            </svg>

                                                            <div>
                                                                Declining...
                                                            </div>
                                                        </div>

                                                        <div v-else>
                                                            Decline
                                                        </div>
                                                    </Button>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <ErrorPage
                :btnRoute="'myTickets'"
                :btnText="'Back to My Tickets'"
            />
        </div>
    </div>

    <FormSlider
        :is-open="showSlider"
        header="Upload Ticket Attachments"
        size="lg"
        @close="closeSlider"
    >
        <FormUpload :id="ticket.id" @close="closeSlider"></FormUpload>
    </FormSlider>
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNoteNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">Note Saved Successfully</div>
        </div>
    </Notification>
    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
