<script lang="ts" setup>
import { onMounted, onUpdated, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useClientsStore } from "../stores/clientsStore";
import { usePointOfContactStore } from "../stores/pointOfContactStore";
import { useHelperStore } from "../stores/helperStore";
import { useUiStore } from "../stores/uiStore";
import { useAuthStore } from "../stores/authStore";
import { useEmployeesStore } from "../stores/employeesStore";
import Preloader1 from "./components/Preloader1.vue";
import Avatar from "./components/Avatar.vue";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import _ from "lodash";
import moment from "moment";
import FormUploadClient from "./forms/FormUploadClient.vue";
import { Menu, Tab } from "../base-components/Headless";
import Button from "../base-components/Button";
import Lucide from "../base-components/Lucide";
import FormSlider from "./FormSlider.vue";
import FormClient from "./forms/FormClient.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import TabEmployeeDetails from "./components/TabEmployeeDetails.vue";
import EmployeeInterface from "./interfaces/employeesInterface";
import ClientInterface from "./interfaces/clientsInterface";
import GridTeam from "./components/GridTeam.vue";
import ClientNote from "./components/ClientNote.vue";
import { ShiftInterface } from "./interfaces/shiftInterface";
import FormPOC from "./forms/FormPOC.vue";
import CustomIcon from "./Icons/CustomIcon.vue";
import ClientSalesAgent from "./components/clients/ClientSalesAgent.vue";
import ClientRecurringSubscription from "./components/clients/ClientRecurringSubscription.vue";
import ClientInvoiceConfig from "./components/clients/ClientInvoiceConfig.vue";
import { CurrencyInterface } from "./interfaces/currencyInterface";

const route = useRoute();
let id: string | string[] = Array.isArray(route.params.id)
    ? route.params.id[0]
    : route.params.id;

watch(
    () => route.params.id,
    async (newId) => {
        id = Array.isArray(newId) ? newId[0] : newId;
        if (typeof id !== "undefined") {
            await populateClientDetails();
        }
    }
);

const AuthStore = useAuthStore();
const UiStore = useUiStore();
const deleteConfirmationModal = ref(false);
const ClientsStore = useClientsStore();

const HelperStore = useHelperStore();
const EmployeesStore = useEmployeesStore();

interface ClientData {
    id: number;
    // add other properties here
}

interface TeamData {
    id: number;
    name: string;
    position: string;
    team: string;
}

const teamdata = reactive({
    data: [] as TeamData[],
});

let showPocSlider = ref(false);
let showClientSlider = ref(false);
let showUploadSlider = ref(false);

const updateClientData = reactive({ data: [] });
const employeeData = ref<EmployeeInterface | null>(null);

let client = reactive<ClientInterface>({
    trade_name: "",
    legal_business_name: "",
    local_business_identifier: "",
    company_general_email: "",
    website: "",
    timezone_id: 0,
    timezone: "",
    initial_staff_shift: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state_id: 0,
    state_name: "",
    zip: "",
    billing_address_line_1: "",
    billing_address_line_2: "",
    billing_city: "",
    billing_state_id: 0,
    billing_state_name: "",
    billing_zip: "",
    status: true,
    teams: [],
    point_of_contacts: [],
    id: 0,
    default_pc_setup: "",
    end_date: "",
    notes: "",
    start_date: "",
    primary_company_number: "",
    billing_country_id: 0,
    country_id: 0,
    country_name: "",
    attachments: [],
    currency_id: "",
    shift: {} as ShiftInterface,
    currency: {} as CurrencyInterface,
});

const uiData = reactive({
    clientId: route.query.id,
});

const isLoading = ref(true);
const isPocLoading = ref(false);

const ClientStore = useClientsStore();
const PocStore = usePointOfContactStore();

onMounted(async () => {
    // await AuthStore.getLoggedInUserDetails();
    isLoading.value = true;
    await populateClientDetails();
});

const loadAllClients = async () => {
    const params = {
        dt_params: JSON.stringify({}),
        searchable_columns: JSON.stringify({}),
    };

    await ClientsStore.getAllClientsForTable(params);
};

const layout = ref("list");
const populateClientDetails = async () => {
    if (AuthStore.hasPermission("can view client poc")) {
        await PocStore.fill({ client_id: id });
    }

    //@ts-ignore //Todo
    const clientData = await ClientStore.getClientDetails(parseInt(id));
    isLoading.value = false;
    isPocLoading.value = false;

    client.attachments = clientData?.attachments ?? [];
    client.trade_name = clientData.trade_name;
    client.legal_business_name = clientData.legal_business_name;
    client.local_business_identifier = clientData.local_business_identifier;
    client.company_general_email = clientData.company_general_email;
    client.website = clientData.website;
    client.timezone_id = clientData.timezone_id;
    client.initial_staff_shift = clientData.initial_staff_shift;
    client.shift = clientData.shift;

    client.address_line_1 = clientData.address_line_1;
    client.address_line_2 = clientData.address_line_2;
    client.city = clientData.city;
    client.state_id = clientData.state_id;
    client.zip = clientData.zip;
    client.state_name = clientData.state_name;

    client.created_at = clientData.created_at;
    client.default_pc_setup = clientData.default_pc_setup;
    client.end_date = clientData.end_date;
    client.notes = clientData.notes;
    client.primary_company_number = clientData.primary_company_number;
    client.start_date = clientData.start_date;
    client.status = clientData.status;

    client.billing_address_line_1 = clientData.billing_address_line_1;
    client.billing_address_line_2 = clientData.billing_address_line_2;
    client.billing_city = clientData.billing_city;
    client.billing_state_id = clientData.billing_state_id;
    client.billing_zip = clientData.billing_zip;
    client.billing_state_name = clientData.billing_state_name;
    client.billing_country_id = clientData.billing_country_id;
    client.country_id = clientData.country_id;

    const countryName = _.find(HelperStore.countries, {
        id: client.country_id,
    });
    client.country_name = countryName ? countryName.name : "";

    client.teams = clientData?.teams ?? [];
    client.point_of_contacts = clientData?.point_of_contacts ?? [];
    client.currency_id = String(clientData.currency_id);
    client.currency = clientData.currency;

    client.id = clientData.id;
    formPointOfContact.id = clientData.id;
    //generate teams
    _.forEach(clientData.teams, function (team, key) {
        _.forEach(team.employeeTeams, function (employees) {
            teamdata.data.push({
                id: employees.employee.id,
                name: employees.employee.name,
                position: employees.employee.position,
                team: team.name,
            });
        });
    });
    ClientStore.setTeams(_.orderBy(teamdata.data, ["name"], ["asc"]));
};

const search = (event: InputEvent) => {
    const keyword = (event.target as HTMLInputElement).value;
    console.log("search Key", keyword);
    if (keyword === "") {
        teamdata.data = ClientStore.teams;
    } else {
        teamdata.data = teamdata.data.filter((obj) => {
            return obj.name.toLowerCase().includes(keyword.toLowerCase());
        });
    }
};

const formPointOfContact = reactive({
    id: 0,
});

const formClientSettings = reactive({
    id: 0,
});

const formUpload = reactive({
    id: 0,
});
onUpdated(() => {
    UiStore.setBreadcrumb("Client Details > " + client.trade_name);
});

const closePocSlider = () => {
    console.log("closePocSlider details");
    formPointOfContact.id = 0;
    showPocSlider.value = false;
};
const closeClientSlider = () => {
    console.log("closePocSlider details");
    formClientSettings.id = 0;
    showClientSlider.value = false;
};
const closeUploadSlider = () => {
    formUpload.id = 0;
    showUploadSlider.value = false;
};

const formClientStatus = async (status: any) => {
    console.log("formClientStatus");
    if (status) {
        isLoading.value = true;
        await populateClientDetails();
    }
};

const formPocStatus = async (status: any) => {
    console.log("formPocStatus");
    if (status) {
        isPocLoading.value = true;
        await populateClientDetails();
    }
};

const addPOC = async () => {
    console.log("add POC");
    showPocSlider.value = true;
    formPointOfContact.id = 0;
};

const updatePOC = async (id: number) => {
    console.log("update POC", id);
    isPointOfContactFormSliderOut.value = true;
    formPointOfContact.id = id;
    clientId.value = client.id;
};

const deleteId = ref(0);
const alertModelSettings = reactive({
    isDisplayed: false,
    mode: "",
    subject: "",
    message: "",
});
const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = parseInt(data.id);
    setConfirmDialog(true);
};
const processDelete = (event: any) => {
    alertModelSettings.isDisplayed = false;
    if (event) {
        console.log(deleteId.value);
        PocStore.delete(deleteId.value).then((response) => {
            console.log("response", response);
            if (response && response.status === "success") {
                populateClientDetails();
                deleteId.value = 0;
            }
        });
    }
};
const uploadClientAttachment = async (data: any) => {
    isClientFormUploadSliderOut.value = true;
    //@ts-ignore //Todo
    formUpload.id = parseInt(id);
};
const downloadAttachment = async (ticket_id: any, file_name: any) => {
    console.log("downloadAttachment:", ticket_id);
    console.log("downloadAttachment:", file_name);

    await ClientStore.downloadAttachment({
        reference_id: ticket_id,
        file_name: file_name,
    });
};
const initials = (name: any) => {
    if (typeof name === "undefined") {
        return "";
    }
    let fullName = name;
    let split = fullName.split(" ");
    let firstInitial = split[0].charAt(0).toUpperCase();
    let secondInitial = split[split.length - 1].charAt(0).toUpperCase();
    return firstInitial + secondInitial;
};

const isClientFormSliderOut = ref(false);
const isPointOfContactFormSliderOut = ref(false);
const isClientFormUploadSliderOut = ref(false);
const isEmployeeViewDetailSliderOut = ref(false);
const clientId = ref(0);
const closeClientFormSlider = () => {
    clientId.value = 0;
    isClientFormSliderOut.value = false;
};

const closePointOfContactFormSlider = () => {
    clientId.value = 0;
    isPointOfContactFormSliderOut.value = false;
};

const closeClientFormUploadSlider = () => {
    isClientFormUploadSliderOut.value = false;
};

const closeEmployeeViewDetailSlider = () => {
    isEmployeeViewDetailSliderOut.value = false;
};

interface ClientApiData {
    data: any;
}

const clientApiData = reactive<ClientApiData>({ data: [] });
const updateClient = (data: ClientData) => {
    console.log("updateClient");
    isClientFormSliderOut.value = true;
    formClientSettings.id = data.id;
    clientId.value = data.id;
    clientApiData.data = data;
};

const createNewPointOfContact = () => {
    isPointOfContactFormSliderOut.value = true;
    formPointOfContact.id = 0;
    clientId.value = client.id;
};

const showConfirmDialog = ref(false);
const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};
</script>
<style>
.p-dataview-content .grid-nogutter {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: none;
    z-index: 1;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: none;
}
</style>
<template>
    <div
        v-if="
            Object.values(AuthStore.activatedPermissionsName).includes(
                'can view all clients'
            )
        "
        class="w-full"
    >
        <!-- First Row -->
        <div class="intro-y box px-5 pt-5 mt-5 mb-2">
            <div
                class="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5"
            >
                <!-- Client Information -->
                <div
                    class="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0"
                >
                    <div class="font-medium text-center lg:text-left lg:mt-3">
                        Client Information
                    </div>
                    <div class="ml-5 relative">
                        <div
                            class="sm:whitespace-normal font-medium text-lg mt-2 relative flex items-center"
                        >
                            <div class="mr-auto">
                                {{ client.legal_business_name }}
                            </div>
                            <Button
                                class="mb-2 mr-1"
                                variant="soft-secondary"
                                @click="updateClient(client)"
                            >
                                <Lucide class="w-5 h-5" icon="Pencil" />
                            </Button>
                        </div>
                        <div
                            class="truncate sm:whitespace-normal flex items-center mt-3"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'Building'"
                                :width="18"
                            />
                            {{ client.trade_name }}
                        </div>
                        <div
                            class="truncate sm:whitespace-normal flex items-center mt-3"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'IdCard'"
                                :width="18"
                            />
                            {{ client.local_business_identifier }}
                        </div>
                        <div
                            class="truncate sm:whitespace-normal flex items-center mt-3"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'MapPin'"
                                :width="18"
                            />
                            {{ client.country_name }}
                        </div>
                        <template v-if="isLoading">
                            <Preloader1></Preloader1>
                        </template>
                    </div>
                </div>
                <!-- Client Information -->
                <!-- Contact Details -->
                <div
                    class="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0"
                >
                    <div class="font-medium text-center lg:text-left lg:mt-3">
                        Contact Details
                    </div>
                    <div
                        class="flex flex-col justify-center items-center lg:items-start mt-4 relative"
                    >
                        <div
                            class="truncate sm:whitespace-normal flex items-center"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'Mail'"
                                :width="18"
                            />
                            {{ client.company_general_email }}
                        </div>
                        <div
                            class="truncate sm:whitespace-normal flex items-center mt-3"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'Chrome'"
                                :width="18"
                            />
                            <a
                                :href="'https://' + client.website"
                                rel="noopener noreferrer"
                                target="_blank"
                                >{{ client.website }}</a
                            >
                        </div>
                        <div
                            class="truncate sm:whitespace-normal flex items-center mt-3"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'Watch'"
                                :width="18"
                            />
                            {{
                                HelperStore.getTimezone(client.timezone_id).name
                            }}
                        </div>
                        <div
                            class="truncate sm:whitespace-normal flex items-center mt-3"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'Calendar'"
                                :width="18"
                            />
                            <div v-if="client.shift">
                                {{ client.shift.name }} ({{
                                    client.shift.start_time
                                }}
                                - {{ client.shift.end_time }})
                            </div>

                            <div v-else>-</div>
                        </div>
                        <template v-if="isLoading">
                            <Preloader1></Preloader1>
                        </template>
                    </div>
                </div>
                <!-- Contact Details -->
                <!-- Client Address -->
                <div
                    class="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0"
                >
                    <div class="font-medium text-center lg:text-left lg:mt-3">
                        Primary & Billing Addresses
                    </div>
                    <div
                        class="flex flex-col justify-center items-center lg:items-start mt-4 relative"
                    >
                        <div
                            class="truncate sm:whitespace-normal flex items-center"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'MapPin'"
                                :width="18"
                            />
                            {{ client.address_line_1 }}<br />
                            {{
                                client.address_line_2
                                    ? client.address_line_2
                                    : ""
                            }}<br v-if="client.address_line_2" />
                            {{ client.city }}, {{ client.state_name }},
                            {{ client.zip }}
                        </div>
                        <div
                            class="mt-2.5 truncate sm:whitespace-normal flex items-center"
                        >
                            <CustomIcon
                                :height="18"
                                :icon="'Bank'"
                                :width="18"
                            />
                            {{ client.billing_address_line_1 }} <br />
                            {{
                                client.billing_address_line_2
                                    ? client.billing_address_line_2
                                    : ""
                            }}<br v-if="client.billing_address_line_2" />
                            {{ client.billing_city }},
                            {{ client.billing_state_name }},
                            {{ client.billing_zip }}
                        </div>
                        <template v-if="isLoading">
                            <Preloader1></Preloader1>
                        </template>
                    </div>
                </div>
                <!-- Client Address -->
            </div>
        </div>
        <Tab.Group class="bg-white rounded">
            <Tab.List
                class="flex-col justify-center text-center sm:flex-row lg:justify-start"
                variant="link-tabs"
            >
                <Tab :fullWidth="false">
                    <Tab.Button class="py-4 cursor-pointer"
                        >Overview
                    </Tab.Button>
                </Tab>
                <Tab
                    v-if="AuthStore.hasPermission('can view client invoice configs')"
                    :fullWidth="false"
                >
                    <Tab.Button class="py-4 cursor-pointer">
                        Invoicing
                    </Tab.Button>
                </Tab>
            </Tab.List>
            <Tab.Panels class="mt-5 intro-y">
                <Tab.Panel class="bg-gray-50 px-4 py-2">
                    <div class="intro-y mt-5">
                        <div class="grid grid-cols-12 gap-6">
                            <!-- Point of Contacts -->
                            <div
                                v-if="AuthStore.hasPermission('can view client poc')"
                                class="intro-y box col-span-12 lg:col-span-4"
                            >
                                <div
                                    class="flex items-center px-5 py-3 border-b border-slate-200/60 dark:border-darkmode-400"
                                >
                                    <h2 class="font-medium leading-9 text-base mr-auto">
                                        Points of Contact
                                    </h2>
                                    <Button
                                        v-if="AuthStore.hasPermission('can create client poc')"
                                        class="w-10"
                                        size="sm"
                                        variant="soft-secondary"
                                        @click="createNewPointOfContact()"
                                    >
                                        <Lucide class="w-5 h-5" icon="Plus" />
                                    </Button>
                                </div>
                                <!-- if there are available points of contact -->
                                <div
                                    v-if="
                                        client.point_of_contacts?.length !== 0
                                    "
                                    class="p-5"
                                >
                                    <div
                                        v-for="poc in client.point_of_contacts"
                                        :key="poc.id"
                                        class="mt-4 relative flex items-center"
                                    >
                                        <div
                                            class="w-12 h-12 flex-none image-fit"
                                        >
                                            <Avatar
                                                :name="
                                                    poc.first_name +
                                                    ' ' +
                                                    poc.last_name
                                                "
                                                :ring="'online'"
                                                :size="'sm'"
                                            />
                                            <div
                                                v-if="poc.primary_poc_flag"
                                                class="absolute -mb-2 -mr-2 flex items-center justify-center bottom-0 right-0 bg-yellow-400 rounded-full p-1"
                                            >
                                                <CustomIcon
                                                    :height="14"
                                                    :icon="'Star'"
                                                    :width="14"
                                                />
                                            </div>
                                        </div>
                                        <div class="ml-4 mr-auto">
                                            <a class="font-medium" href="">{{
                                                poc.full_name
                                            }}</a>
                                            <div
                                                class="text-slate-500 mr-5 sm:mr-5"
                                            >
                                                {{ poc.title }} -
                                                <span class="italic">{{
                                                    poc.points_of_contact_role_name
                                                }}</span>
                                            </div>
                                        </div>
                                        <div
                                            class="flex flex-col justify-end items-end font-medium text-slate-600 dark:text-slate-500 mr-5"
                                        >
                                            <div>{{ poc.email }}</div>
                                            <div>{{ poc.phone_number }}</div>
                                            <div>
                                                {{
                                                    HelperStore.getTimezone(
                                                        poc.timezone_id
                                                    ).name
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="flex flex-col justify-end items-end font-medium text-slate-600 dark:text-slate-500"
                                        >
                                            <Menu>
                                                <Menu.Button
                                                    :as="Button"
                                                    variant="soft-secondary"
                                                >
                                                    <CustomIcon
                                                        :height="18"
                                                        :icon="'CircleDots'"
                                                        :width="18"
                                                    />
                                                </Menu.Button>
                                                <Menu.Items class="w-40">
                                                    <Menu.Item
                                                        v-if="
                                                            AuthStore.hasPermission(
                                                                'can update clients'
                                                            )
                                                        "
                                                        @click="
                                                            updatePOC(poc.id)
                                                        "
                                                        >Update
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        v-if="
                                                            AuthStore.hasPermission(
                                                                'can delete clients'
                                                            )
                                                        "
                                                        @click="
                                                            confirmDelete(
                                                                $event,
                                                                poc
                                                            )
                                                        "
                                                        >Delete
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Menu>
                                        </div>
                                    </div>
                                    <template v-if="isLoading || isPocLoading">
                                        <Preloader1></Preloader1>
                                    </template>
                                </div>
                                <!-- if there are available points of contact -->
                                <!-- if there are no available points of contact -->
                                <div v-else class="p-5">
                                    <div class="text-center">
                                        <CustomIcon
                                            :height="100"
                                            :icon="'UserCircle'"
                                            :width="100"
                                        />
                                        <div class="p-8 text-center mt-1">
                                            No points of contact available
                                        </div>
                                    </div>
                                </div>
                                <!-- if there are no available points of contact -->
                            </div>
                            <!-- Point of Contacts-->
                            <!--                 Recent Files-->
                            <div
                                v-if="AuthStore.hasPermission('can view client attachments')"
                                class="intro-y box col-span-12 lg:col-span-4"
                            >
                                <div
                                    class="intro-y box col-span-12 lg:col-span-6"
                                >
                                    <div
                                        class="flex items-center px-5 py-3 border-b border-slate-200/60 dark:border-darkmode-400"
                                    >
                                        <h2
                                            class="font-medium leading-9 text-base mr-auto"
                                        >
                                            Recent Files
                                        </h2>

                                        <Button
                                            v-if="
                                                AuthStore.hasPermission(
                                                    'can create client attachments'
                                                )
                                            "
                                            id="add-attachment"
                                            class="text-xs"
                                            variant="soft-secondary"
                                            @click="
                                                uploadClientAttachment(client)
                                            "
                                        >
                                            Upload a file
                                        </Button>
                                    </div>

                                    <div
                                        class="py-8 border-t border-slate-200/60 dark:border-darkmode-400"
                                    >
                                        <ul
                                            v-for="attachment of client.attachments"
                                            class="border border-gray-200 rounded-md divide-y divide-gray-200"
                                            role="list"
                                        >
                                            <li
                                                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                                            >
                                                <div
                                                    class="w-0 flex-1 flex items-center"
                                                >
                                                    <CustomIcon
                                                        :height="20"
                                                        :icon="'Paperclip'"
                                                        :width="20"
                                                    />
                                                    <span
                                                        class="ml-2 flex-1 w-0 truncate"
                                                    >
                                                        {{
                                                            attachment.original_name
                                                        }}
                                                        -
                                                        {{
                                                            moment(
                                                                attachment.created_at
                                                            ).format(
                                                                "YYYY-MM-DD"
                                                            )
                                                        }}
                                                        {{
                                                            initials(
                                                                attachment.user
                                                                    .name
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="ml-4 flex-shrink-0">
                                                    <a
                                                        v-if="
                                                            AuthStore.hasPermission(
                                                                'can download client attachments'
                                                            )
                                                        "
                                                        class="font-medium text-custom-color-800 hover:text-indigo-500"
                                                        href="#"
                                                        @click="
                                                            downloadAttachment(
                                                                attachment.client_id,
                                                                attachment.download_name
                                                            )
                                                        "
                                                    >
                                                        <CustomIcon
                                                            :height="20"
                                                            :icon="'Download'"
                                                            :width="20"
                                                        />
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>

                                        <div
                                            v-if="
                                                client.attachments.length === 0
                                            "
                                            class="p-8 text-center"
                                        >
                                            No attachments yet.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--                 Recent Files-->

                            <ClientNote v-if="AuthStore.hasPermission('can view client note')" />

                            <!-- Client Teams -->
                            <GridTeam
                                v-if="
                                    AuthStore.hasPermission(
                                        'can view all employees'
                                    )
                                "
                                :data="ClientStore.teams"
                                :name="'Client Teams'"
                            />
                            <!--  Client Teams -->

                            <ClientSalesAgent
                                v-if="
                                    AuthStore.hasPermission(
                                        'can view all employees'
                                    )
                                "
                            />
                        </div>
                    </div>
                </Tab.Panel>
                <Tab.Panel class="bg-gray-50 px-4 py-2">
                    <ClientInvoiceConfig />
                    <ClientRecurringSubscription />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>

        <!-- First Row -->

        <!-- Second Row -->

        <!-- Second Row -->
        <FormSlider
            :isOpen="isClientFormSliderOut"
            @close="closeClientFormSlider()"
        >
            <FormClient
                :id="clientId"
                :data="clientApiData.data"
                @close="closeClientFormSlider()"
                @status="formClientStatus($event)"
            ></FormClient>
        </FormSlider>

        <FormSlider
            :header="'Point of Contact Form Details'"
            :isOpen="isPointOfContactFormSliderOut"
            :size="'lg'"
            @close="closePointOfContactFormSlider()"
        >
            <FormPOC
                :id="formPointOfContact.id"
                @close="closePointOfContactFormSlider()"
                @status="formPocStatus($event)"
            >
            </FormPOC>
        </FormSlider>
        <FormSlider
            :header="'Upload Client Attachments'"
            :isOpen="isClientFormUploadSliderOut"
            :size="'md'"
            @close="closeClientFormUploadSlider()"
        >
            <FormUploadClient
                :id="formUpload.id"
                @close="closeClientFormUploadSlider()"
                @status="formClientStatus($event)"
            />
        </FormSlider>
        <ConfirmModal
            :displayConfirmModal="showConfirmDialog"
            second-description="Point of Contact has been successfully deleted."
            @closeConfirmModal="setConfirmDialog(false)"
            @proceedProcess="processDelete($event)"
        />
        <FormSlider
            :header="'View Employee Details'"
            :isOpen="isEmployeeViewDetailSliderOut"
            :size="'md'"
            @close="closeEmployeeViewDetailSlider()"
        >
            <div class="text-left">
                <TabEmployeeDetails
                    :employee="employeeData ? employeeData : undefined"
                />
            </div>
        </FormSlider>
    </div>
</template>
