<script lang="ts" setup>
import { useScheduleToday } from "../stores/schedulesTodayStore";
import { onMounted, ref } from "vue";
import { useClientsStore } from "../stores/clientsStore";
import { useShiftStore } from "../stores/shiftStore";
import { useTeamStore } from "../stores/teamStore";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import PageHeaderStandard from "./components/PageHeaderStandard.vue";
import LazyParamsInterface from "./interfaces/lazyParamsInterface";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { FormInput } from "../base-components/Form";
import Lucide from "../base-components/Lucide";
import Button from "../base-components/Button";
import { Dialog } from "../base-components/Headless";
import { useClockInsStore } from "../stores/clockInsStore";
import { useRouter } from "vue-router";
import MultiSelect from "primevue/multiselect";
import moment from "moment-timezone";

const ScheduleTodayStore = useScheduleToday();
const ClientsStore = useClientsStore();
const ShiftStore = useShiftStore();
const TeamStore = useTeamStore();
const ClockInsStore = useClockInsStore();
const router = useRouter();

const initialLazyParams = {
    first: 0,
    rows: 30,
    sortField: null,
    sortOrder: null,
    filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "employee.name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "employee.client.legal_business_name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "shift.name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "employee.employee_teams": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
    }
};

const range = ref({
    start: moment()
        .subtract(import.meta.env.VITE_APP_SHIFT_MISSED, "hours")
        .tz("Asia/Manila")
        .format("YYYY-MM-DD HH:mm"),
    end: moment()
        .add(import.meta.env.VITE_APP_SHIFT_UPCOMING, "hours")
        .tz("Asia/Manila")
        .format("YYYY-MM-DD HH:mm"),
});
const dialog = ref(false);
const dt = ref();
const lazyParams = ref<LazyParamsInterface>({ rows: initialLazyParams.rows });
const searchableColumns = ref([
    "employee.name",
    "employee.client.legal_business_name",
    "employee.third_party_employee_id",
    "shift.name",
]);
const teamFilter = ref("");
const widgetFilter = ref<any>([]);
const filters = ref(initialLazyParams.filters);
const loading = ref(false);
const showClearFilter = ref(false);

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableColumns.value),
        team_filter: teamFilter.value,
        widget_filter: widgetFilter.value,
    };

    await ScheduleTodayStore.getSchedules(params);

    loading.value = false;
};

const resetGlobalFilter = async () => {
    showClearFilter.value = false;

    filters.value = initialLazyParams.filters;

    teamFilter.value = "";
    widgetFilter.value = [];

    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };

    await onDataFetch();
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onClickTeamFilterClear = () => {
    teamFilter.value = "";

    onDataFetch();
};

const onFilterWidgetStatus = (status: string) => {
    showClearFilter.value = true;

    if (!widgetFilter.value.includes(status)) {
        widgetFilter.value.push(status);
    } else {
        widgetFilter.value = widgetFilter.value.filter(
            (item: string) => item !== status
        );
    }

    onDataFetch();
};

const onClickRedirect = () => {
    router.push("/");
};

onMounted(async () => {
    (window as any).Echo.channel("clockin-channel").listen(
        "ClockIn",
        async (e: any) => {
            console.log("go public");
            console.log(e);
            await onDataFetch();
            await fetchWidgetData();
        }
    );

    (window as any).Echo.channel("schedule-today").listen(
        "ScheduleTodayEvent",
        async (e: any) => {
            await onDataFetch();
            await fetchWidgetData();
        }
    );

    await onDataFetch();
    await ClientsStore.getAllClients();
    await ShiftStore.getAllShifts({ dropdown: true });
    await TeamStore.getAllTeams();
    await fetchWidgetData();

    startDateRange();
});

const fetchWidgetData = async () => {
    await ClockInsStore.getScheduledEmployeesForToday();
    await ClockInsStore.getTotalClockInsForToday();
    await ClockInsStore.getTotalNoClockInsForToday();
    await ClockInsStore.getTotalLates();
    await ClockInsStore.getTotalNoClockoutsForToday();
    await ClockInsStore.getTotalClockOutForToday();
    await ClockInsStore.getTotalOnBreakForToday();
};

const attendanceStatusClass = (status: string) => {
    switch (status) {
        case "Pending Clock In":
            return "bg-orange-200 text-orange-600";
        case "Running Late":
            return "bg-orange-200 text-orange-600";
        case "Clocked In":
            return "bg-green-200 text-green-600";
        case "Clocked Out":
            return "bg-red-200 text-red-600";
        case "On break":
            return "bg-yellow-200 text-yellow-600";
        case "Upcoming":
            return "bg-blue-200 text-blue-600";
        default:
            return "";
    }
};

const columns = ref([
    { name: "Employee", code: "Employee" },
    { name: "Client", code: "Client" },
    { name: "Teams", code: "Teams" },
    { name: "Shift", code: "Shift" },
    { name: "Shift Start", code: "Shift Start" },
    { name: "Shift End", code: "Shift End" },
    { name: "Actual Start", code: "Actual Start" },
    { name: "Actual End", code: "Actual End" },
    { name: "Break Start", code: "Break Start" },
    { name: "Break End", code: "Break End" },
    { name: "Status", code: "Status" },
    { name: "Actual Hours", code: "Actual Hours" },
]);
const selectedColumns = ref(columns.value);

const columnToggle = (val: any) => {
    selectedColumns.value = columns.value.filter((col) => val.includes(col));
};

const startDateRange = () => {
    setInterval(() => {
        range.value.start = moment()
            .subtract(import.meta.env.VITE_APP_SHIFT_MISSED, "hours")
            .tz("Asia/Manila")
            .format("YYYY-MM-DD HH:mm");
        range.value.end = moment()
            .add(import.meta.env.VITE_APP_SHIFT_UPCOMING, "hours")
            .tz("Asia/Manila")
            .format("YYYY-MM-DD HH:mm");
    }, 1000);
};
</script>

<template>
    <div class="w-full">
        <PageHeaderStandard title="Today's Schedules" />
        <div class="mt-5 intro-y">
            <div class="grid grid-cols-12 box">
                <div
                    class="flex flex-col justify-center col-span-12 px-8 py-12 lg:col-span-4"
                >
                    <div class="flex items-center">
                        <div>
                            <Lucide
                                class="w-10 h-10 text-pending"
                                icon="PieChart"
                            />
                        </div>
                        <div class="ml-2">Scheduled Employees Today</div>
                    </div>
                    <div class="flex items-center justify-start mt-4">
                        <div class="relative text-2xl font-medium ml-0.5">
                            {{ ClockInsStore.scheduledEmployeesToday }}
                        </div>
                    </div>
                </div>
                <div
                    class="col-span-12 p-8 border-t border-dashed lg:col-span-8 lg:border-t-0 lg:border-l border-slate-200 dark:border-darkmode-300"
                >
                    <div class="grid grid-cols-12 gap-y-8 gap-x-10">
                        <div
                            :class="{
                                '!text-green-700 font-semibold':
                                    widgetFilter.includes('Clocked In'),
                            }"
                            class="col-span-6 sm:col-span-6 md:col-span-4 cursor-pointer"
                            @click="onFilterWidgetStatus('Clocked In')"
                        >
                            <div
                                :class="{
                                    '!text-green-700 font-semibold':
                                        widgetFilter.includes('Clocked In'),
                                }"
                                class="text-slate-700 flex items-center"
                            >
                                <div>
                                    <Lucide
                                        class="h-4 w-4 mr-2"
                                        icon="Inspect"
                                    ></Lucide>
                                </div>

                                <div>Clocked In</div>
                            </div>
                            <div class="mt-1.5 flex items-center">
                                <div class="text-base">
                                    {{ ClockInsStore.clockInsToday }}
                                </div>
                            </div>
                        </div>

                        <div
                            :class="{
                                '!text-green-700 font-semibold':
                                    widgetFilter.includes('Running Late'),
                            }"
                            class="col-span-12 sm:col-span-6 md:col-span-4 cursor-pointer"
                            @click="onFilterWidgetStatus('Running Late')"
                        >
                            <div
                                :class="{
                                    '!text-green-700 font-semibold':
                                        widgetFilter.includes('Running Late'),
                                }"
                                class="text-slate-500 flex items-center"
                            >
                                <div>
                                    <Lucide
                                        class="h-4 w-4 mr-2"
                                        icon="Inspect"
                                    ></Lucide>
                                </div>

                                <div>Running Late</div>
                            </div>
                            <div class="mt-1.5 flex items-center">
                                <div class="text-base">
                                    {{ ClockInsStore.lates }}
                                </div>
                            </div>
                        </div>
                        <div
                            :class="{
                                '!text-green-700 font-semibold':
                                    widgetFilter.includes('Clocked Out'),
                            }"
                            class="col-span-12 sm:col-span-6 md:col-span-4 cursor-pointer"
                            @click="onFilterWidgetStatus('Clocked Out')"
                        >
                            <div
                                :class="{
                                    '!text-green-700 font-semibold':
                                        widgetFilter.includes('Clocked Out'),
                                }"
                                class="text-slate-500 flex items-center"
                            >
                                <div>
                                    <Lucide
                                        class="h-4 w-4 mr-2"
                                        icon="Inspect"
                                    ></Lucide>
                                </div>
                                <div>Clocked Out</div>
                            </div>
                            <div class="mt-1.5 flex items-center">
                                <div class="text-base">
                                    {{ ClockInsStore.clockOutToday }}
                                </div>
                            </div>
                        </div>

                        <div
                            :class="{
                                '!text-green-700 font-semibold':
                                    widgetFilter.includes('Pending Clock In'),
                            }"
                            class="col-span-12 sm:col-span-6 md:col-span-4 cursor-pointer"
                            @click="onFilterWidgetStatus('Pending Clock In')"
                        >
                            <div
                                :class="{
                                    '!text-green-700 font-semibold':
                                        widgetFilter.includes(
                                            'Pending Clock In'
                                        ),
                                }"
                                class="text-slate-500 flex items-center"
                            >
                                <div>
                                    <Lucide
                                        class="h-4 w-4 mr-2"
                                        icon="Inspect"
                                    />
                                </div>
                                <div>Pending Clock In</div>
                            </div>
                            <div class="mt-1.5 flex items-center">
                                <div class="text-base">
                                    {{ ClockInsStore.noClockInsToday }}
                                </div>
                            </div>
                        </div>

                        <div
                            :class="{
                                '!text-green-700 font-semibold':
                                    widgetFilter.includes('Working Late'),
                            }"
                            class="col-span-12 sm:col-span-6 md:col-span-4 cursor-pointer"
                            @click="onFilterWidgetStatus('Working Late')"
                        >
                            <div
                                :class="{
                                    '!text-green-700 font-semibold':
                                        widgetFilter.includes('Working Late'),
                                }"
                                class="text-slate-500 flex items-center"
                            >
                                <div>
                                    <Lucide
                                        class="h-4 w-4 mr-2"
                                        icon="Inspect"
                                    />
                                </div>
                                <div>Working Late</div>
                            </div>
                            <div class="mt-1.5 flex items-center">
                                <div class="text-base">
                                    {{ ClockInsStore.noClockOutsToday }}
                                </div>
                            </div>
                        </div>
                        <div
                            :class="{
                                '!text-green-700 font-semibold':
                                    widgetFilter.includes('On break'),
                            }"
                            class="col-span-12 sm:col-span-6 md:col-span-4 cursor-pointer"
                            @click="onFilterWidgetStatus('On break')"
                        >
                            <div
                                :class="{
                                    '!text-green-700 font-semibold':
                                        widgetFilter.includes('On break'),
                                }"
                                class="text-slate-500 flex items-center"
                            >
                                <div>
                                    <Lucide
                                        class="h-4 w-4 mr-2"
                                        icon="Inspect"
                                    />
                                </div>
                                <div>On Break</div>
                            </div>
                            <div class="mt-1.5 flex items-center">
                                <div class="text-base">
                                    {{ ClockInsStore.onBreakToday }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-4"
        >
            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <Button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </Button>
                </template>

                <div class="ml-2 w-56 relative text-slate-500">
                    <FormInput
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter($event)"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:filters="filters"
            :global-filter-fields="searchableColumns"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="initialLazyParams.rows"
            :rowsPerPageOptions="[30, 50, 100]"
            :totalRecords="ScheduleTodayStore.schedules.total"
            :value="ScheduleTodayStore.schedules.data"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            filter-display="menu"
            responsive-layout="scroll"
            show-gridlines
            striped-rows
            @filter="onFilter($event)"
            @page="onPage($event)"
            @sort="onSort($event)"
        >
            <template #header>
                <div class="flex justify-between align-center">
                    <div class="my-3">
                        Schedule Range: {{ range.start }} to {{ range.end }}
                    </div>

                    <div>
                        <label class="mx-4 text-custom-color-1000" for=""
                            >Column Toggle</label
                        >
                        <MultiSelect
                            v-model="selectedColumns"
                            :maxSelectedLabels="3"
                            :options="columns"
                            class="md:w-20rem text-sm"
                            optionLabel="name"
                            placeholder="Select Columns"
                            @update:modelValue="columnToggle"
                        />
                    </div>
                </div>
            </template>
            <template #empty> No available data.</template>

            <template #loading> Loading data. Please wait.</template>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Employee')"
                field="employee.name"
                header="Employee"
                sortable
            >
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by employee name"
                        type="text"
                    />
                </template>

                <template #body="{ data }">
                    {{ data.employee.name }} -
                    {{ data.employee.third_party_employee_id }}
                </template>
            </Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Client')"
                field="employee.client.legal_business_name"
                header="Client"
                sortable
            >
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="ClientsStore.clients"
                        option-label="legal_business_name"
                        option-value="legal_business_name"
                        placeholder="Select Client Business Name"
                    />
                </template>
            </Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Teams')"
                :show-add-button="false"
                :show-filter-match-modes="false"
                :show-filter-operator="false"
                field="employee.employee_teams"
                header="Teams"
                sort-field="team_name"
                sortable
            >
                <template #filter>
                    <Dropdown
                        v-model="teamFilter"
                        :options="TeamStore.teams"
                        option-label="name"
                        option-value="name"
                        placeholder="Select Team"
                    />
                </template>

                <template #filterclear>
                    <Button
                        class="border border-[#2196f3] text-[#2196f3] py-1.5"
                        @click="onClickTeamFilterClear"
                        >Clear
                    </Button>
                </template>

                <template #body="{ data }">
                    <div v-if="data.employee.employee_teams.length > 0">
                        <div
                            v-for="team in data.employee.employee_teams"
                            :key="team.id"
                        >
                            <div v-for="t in team.team" :key="t.id">
                                {{ t.name }}
                            </div>
                        </div>
                    </div>
                    <div v-else>No Team Assigned</div>
                </template>
            </Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Shift')"
                field="shift.name"
                header="Shift"
                sort-field="shift_id"
                sortable
            >
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="ShiftStore.allShifts"
                        option-label="name"
                        option-value="name"
                        placeholder="Select Shift"
                    />
                </template>
            </Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Shift Start')"
                field="shift.start_time"
                header="Shift Start Time"
                sort-field="start_time"
                sortable
            ></Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Shift End')"
                field="shift.end_time"
                header="Shift End Time"
                sort-field="end_time"
                sortable
            ></Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Status')"
                body-class="!p-0"
                field="attendance_status"
                header="Status"
                sortable
            >
                <template #body="{ data }">
                    <div
                        :class="attendanceStatusClass(data.attendance_status)"
                        class="text-center my-1 mx-2 px-4 py-1 text-xs font-medium rounded-full cursor-pointer shadow-lg"
                    >
                        {{ data.attendance_status }}
                    </div>
                </template>
            </Column>

            <Column
                v-if="
                    selectedColumns.some((col) => col.name === 'Actual Start')
                "
                field="attendance_start_time"
                header="Actual Start Time"
                sortable
            ></Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Actual End')"
                field="attendance_end_time"
                header="Actual End Time"
                sortable
            ></Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Break Start')"
                field="break_start_time"
                header="Break Start Time"
                sortable
            ></Column>

            <Column
                v-if="selectedColumns.some((col) => col.name === 'Break End')"
                field="break_end_time"
                header="Break End Time"
                sortable
            ></Column>

            <Column
                v-if="
                    selectedColumns.some((col) => col.name === 'Actual Hours')
                "
                field="attendance_total_hours"
                header="Actual Hours"
                sortable
            >
            </Column>
        </DataTable>
    </div>

    <Dialog
        :open="dialog"
        staticBackdrop
        @close="
            () => {
                dialog = !dialog;
            }
        "
    >
        <Dialog.Panel class="px-5 py-10">
            <div class="text-center">
                <div class="mb-5 text-danger-500">
                    <span class="text-red-500"
                        >No schedule has been generated for this day.</span
                    >
                </div>
                <div class="mb-5">
                    Please contact your system administrator.
                </div>
                <Button
                    class="w-auto"
                    type="button"
                    variant="primary"
                    @click="onClickRedirect()"
                >
                    Return to dashboard
                </Button>
            </div>
        </Dialog.Panel>
    </Dialog>
</template>
