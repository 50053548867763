<script lang="ts" setup>
import { defineEmits, defineProps, onMounted, ref, watch } from "vue";
import { useAuthStore } from "../../stores/authStore";
import TomSelect from "../../base-components/TomSelect";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { useTeamStore } from "../../stores/teamStore";

const emit = defineEmits(["itemValue", "itemName"]);

const props = defineProps({
    validate: {
        type: Object as () => {
            [key: string]: any;
        },
        default: {},
    },
});

const AuthStore = useAuthStore();

const TeamStore = useTeamStore();
const { myTeam } = storeToRefs(TeamStore);
const approvers = ref<Array<{ id: number; user_id: number; name: string }>>([]);

const selectItem = ref("");

watch(myTeam, (val) => {
    // console.log(val);
    getAllApprovers();
});

onMounted(async () => {
    await getAllApprovers();
});

const getValue = (item: any) => {
    console.log(item);
    const approverDetails = _.find(approvers.value, (approver) => {
        return approver.user_id === Number(selectItem.value);
    });
    if (approverDetails) {
        emit("itemName", approverDetails.name);
        emit("itemValue", selectItem.value);
    } else {
        emit("itemName", "");
        emit("itemValue", "");
    }
};
const getAllApprovers = async () => {

    console.log("get approvers");
    const myClient = TeamStore.myTeam;
    myClient.forEach((team: { employeeTeams: any[] }) => {
        team.employeeTeams.forEach((empTeams) => {
            if (
                empTeams.is_approver === 1 &&
                empTeams.employee.user_id !== null &&
                empTeams.employee.user_id !== AuthStore.authUser.id
            ) {
                approvers.value.push({
                    id: empTeams.employee.id,
                    user_id: empTeams.employee.user_id,
                    name: empTeams.employee.name,
                });
            }
        });
    });
    console.log("set approvers");
    console.log(approvers);
};
</script>
<template>
    <div class="mt-3 comp-approver">
        <label class="form-label"
            >Supervisor <span style="color: rgb(255, 0, 0)">*</span></label
        >
        <TomSelect
            id="approver"
            v-model="selectItem"
            :change="getValue(selectItem)"
            class="w-full select-approver mt-2"
            name="approver"
        >
            <option value="" :disabled="true">Select Supervisors</option>
            <option v-for="approver in approvers" :value="approver.user_id">
                {{ approver.name }}
            </option>
        </TomSelect>
    </div>
    <div>
        <template v-if="validate.approver.$error">
            <div
                v-for="(error, index) in validate.approver.$errors"
                :key="index"
                class="text-danger mt-2"
            >
                {{ error.$message }}
            </div>
        </template>
    </div>
</template>
