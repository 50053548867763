<script lang="ts" setup>
import {
    defineEmits,
    onMounted,
    PropType,
    provide,
    reactive,
    ref,
    toRefs,
} from "vue";
import { useVuelidate } from "@vuelidate/core";
import {
    FormInput,
    FormLabel,
    InputGroup,
} from "@/base-components/Form";
import Button from "@/base-components/Button";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import Notification, {
    NotificationElement,
} from "@/base-components/Notification";
import Lucide from "@/base-components/Lucide";
import { useEmployeeNonrefundableHistoryStore } from "@/stores/employeeNonrefundableHistoryStore";
// import TomSelect from "@/base-components/TomSelect";
import { useCurrencyStore } from "@/stores/currencyStore";
import EmployeeInterface from "@/pages/interfaces/employeesInterface";
import SliderFormSkeleton from "@/pages/components/SliderFormSkeleton.vue";
import SubmitButton from "@/pages/components/SubmitButton.vue";

const loading = ref(true);
const processing = ref(false);
const route = useRoute();
const emit = defineEmits(["close"]);
const props = defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
    employee: {
        type: Object as PropType<EmployeeInterface>,
        required: true,
    },
});

const EmployeeNonrefundableHistoryStore =
    useEmployeeNonrefundableHistoryStore();
const CurrencyStore = useCurrencyStore();

const notificationMessage = ref("");
const backendValidationErrors = ref<any>({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const form = reactive({
    id: 0,
    employee_id: +route?.params?.id,
    amount: 0,
    effective_from: "",
    effective_to: "",
});

const $externalResults = ref({});
const externalServerValidation = () => true;
const rules = {
    effective_from: { externalServerValidation },
    effective_to: { externalServerValidation },
    amount: { externalServerValidation },
};
const validate = useVuelidate(rules, toRefs(form), { $externalResults });

const resetForm = () => {
    form.amount = 0;
    form.effective_from = "";
    form.effective_to = "";
};

const onClose = () => {
    resetForm();
    emit("close");
};

const save = async () => {
    await EmployeeNonrefundableHistoryStore.addEmployeeNonrefundableHistory(
        form
    );
    notificationMessage.value =
        "Employee nonrefundable has been successfully added.";
};

const update = async () => {
    await EmployeeNonrefundableHistoryStore.updateEmployeeNonrefundableHistory(
        form
    );
    notificationMessage.value =
        "Employee nonrefundable has been successfully updated.";
};

const onSubmit = async () => {
    processing.value = true;
    validate.value.$reset();
    validate.value.$clearExternalResults();
    backendValidationErrors.value = {};
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        processing.value = false;
        return;
    }

    try {
        props.data.id ? await update() : await save();

        showSuccessNotification();
        onClose();
    } catch (error: any) {
        $externalResults.value = error?.response?.data?.errors;
        backendValidationErrors.value = error?.response?.data?.errors;
        showErrorsNotification();
    } finally {
        processing.value = false;
    }
};

onMounted(async () => {
    await CurrencyStore.getCurrencies();

    if (props.data.id) {
        form.id = props.data.id;
        form.amount = props.data.amount;
        form.effective_from = props.data.effective_from;
        form.effective_to = props.data.effective_to;
    }

    loading.value = false;
});
</script>

<template>
    <SliderFormSkeleton v-if="loading" />
    <form v-else @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y p-5">
                    <div>
                        <FormLabel class="form-label" for="name"
                            >Amount <span class="text-red-500">*</span>
                        </FormLabel>
                        <InputGroup>
                            <FormInput
                                id="amount"
                                v-model="form.amount"
                                :value="form.amount"
                                type="text"
                                class="form-control w-full"
                            />
                            <InputGroup.Text v-if="employee.client?.currency">
                                {{ employee.client?.currency?.code }}
                            </InputGroup.Text>
                        </InputGroup>
                        <template v-if="backendValidationErrors?.amount">
                            <div class="text-red-500 mt-1">
                                {{ backendValidationErrors?.amount[0] }}
                            </div>
                        </template>
                    </div>

                    <!--                    <div class="mt-4">-->
                    <!--                        <FormLabel class="form-label" for="currency_id"-->
                    <!--                            >Currency-->
                    <!--                            <span class="text-red-600">*</span></FormLabel-->
                    <!--                        >-->
                    <!--                        <div>-->
                    <!--                            <TomSelect-->
                    <!--                                id="currency_id"-->
                    <!--                                v-model="form.currency_id"-->
                    <!--                                :value="form.currency_id"-->
                    <!--                                class="w-full p-0"-->
                    <!--                                name="currency_id"-->
                    <!--                            >-->
                    <!--                                <option selected>Select Currency</option>-->
                    <!--                                <option-->
                    <!--                                    v-for="currency in CurrencyStore.currencies-->
                    <!--                                        ?.data"-->
                    <!--                                    :value="currency.id"-->
                    <!--                                >-->
                    <!--                                    {{ currency.code }}-->
                    <!--                                </option>-->
                    <!--                            </TomSelect>-->
                    <!--                        </div>-->
                    <!--                        <template v-if="validate.currency_id.$error">-->
                    <!--                            <div-->
                    <!--                                v-for="(error, index) in validate.currency_id-->
                    <!--                                    .$errors"-->
                    <!--                                :key="index"-->
                    <!--                                class="text-danger mt-2"-->
                    <!--                            >-->
                    <!--                                {{ error.$message }}-->
                    <!--                            </div>-->
                    <!--                        </template>-->
                    <!--                    </div>-->

                    <div class="mt-3">
                        <FormLabel class="form-label" for="effective_from">
                            Effective From <span class="text-red-500">*</span>
                        </FormLabel>
                        <FormInput
                            id="effective_from"
                            v-model="form.effective_from"
                            :value="form.effective_from"
                            class="form-control w-full"
                            maxlength="50"
                            name="effective_from"
                            placeholder=""
                            type="date"
                            max="9999-12-31"
                        />
                        <template v-if="backendValidationErrors?.effective_from">
                            <div class="text-red-500 mt-1">
                                {{ backendValidationErrors?.effective_from[0] }}
                            </div>
                        </template>
                    </div>

                    <div class="mt-3">
                        <FormLabel class="form-label" for="name">
                            Effective To
                        </FormLabel>
                        <FormInput
                            id="effective_to"
                            v-model="form.effective_to"
                            :disabled="!form.effective_from"
                            :min="
                                dayjs(form.effective_from)
                                    .add(1, 'day')
                                    .format('YYYY-MM-DD')
                            "
                            :value="form.effective_to"
                            class="form-control w-full"
                            maxlength="50"
                            name="effective_to"
                            placeholder=""
                            type="date"
                            max="9999-12-31"
                        />
                        <template v-if="backendValidationErrors?.effective_to">
                            <div class="text-red-500 mt-1">
                                {{ backendValidationErrors?.effective_to[0] }}
                            </div>
                        </template>
                    </div>
                </div>

                <div class="">
                    <div class="text-right p-2">
                        <Button
                            id="btn_cancel"
                            class="w-32 mb-2 mr-1"
                            type="button"
                            variant="warning"
                            @click="onClose"
                        >
                            Cancel
                        </Button>

                        <SubmitButton
                            class="w-36 mb-2 mr-1"
                            variant="customPrimary"
                            :label="form.id ? 'Update' : 'Create'"
                            :processingLabel="form.id ? 'Updating...' : 'Creating...'"
                            :isProcessing="processing"
                        />
                    </div>
                </div>
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                {{ notificationMessage }}
            </div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
