<script setup lang="ts">
import {computed, onMounted, provide, reactive, ref, toRefs} from "vue";
import Avatar from "../Avatar.vue";
import Textarea from "primevue/textarea";
import { useInvoiceNoteStore } from "../../../stores/invoiceNoteStore";
import { useRoute } from "vue-router";
import Button from "../../../base-components/Button";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import moment from "moment/moment";

const route = useRoute();
// const { notes, getInvoiceNotes, addNote } = useInvoiceNoteStore();
const InvoiceNoteStore = useInvoiceNoteStore();

const loading = ref(false);
const form = reactive({
    invoice_id: +route?.params?.id,
    note: "",
});
const backendValidationErrors = ref({});
const $externalResults = ref({});
const rules = {
    note: {
        required,
    },
};
const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const errorNotificationToggle = () => {
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};
const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};
const initials = (fullname: any) => {
    if (typeof fullname === "undefined") {
        return "";
    }
    let fullName = fullname;
    let split = fullName.split(" ");
    //let firstInitial = split[0].charAt(0).toUpperCase();
    let secondInitial = split[split.length - 1].charAt(0).toUpperCase();
    return split[0] + ' ' + secondInitial + '.';
};
const handleSubmit = async () => {
    loading.value = true;

    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();

        loading.value = false;

        return;
    }

    try {
        await InvoiceNoteStore.addNote(form);
        showSuccessNotification();
        form.note = "";
        validate.value.$reset();
    } catch (error: any) {
        backendValidationErrors.value = {
            message: ["Something went wrong."],
        };

        $externalResults.value = error?.response?.data?.errors;

        showErrorsNotification();
    } finally {
        loading.value = false;
    }
};

onMounted(async () => {
    console.log("Private Notes Component Mounted");

    await InvoiceNoteStore.getInvoiceNotes(+route?.params?.id);
});
</script>

<template>
    <div class="p-5 rounded-md box mt-5">
        <div>
            <div class="text-base font-medium truncate">Private Notes</div>

            <hr class="my-4" />
        </div>

        <div v-if="InvoiceNoteStore.notes.length === 0" class="mt-5">
            No available notes.
        </div>

        <div
            v-else
            class="mt-5 relative before:block before:absolute before:w-px before:h-[85%]  before:dark:bg-darkmode-400 before:ml-5 before:mt-5"
        >
            <div
                v-for="note in InvoiceNoteStore.notes"
                class="intro-x relative flex items-center mb-3"
            >
                <!-- before:bg-slate-200 -->
                <div
                    class="before:block before:absolute before:w-20 before:h-px before:bg-slate-200 before:dark:bg-darkmode-400 before:mt-5 before:ml-5"
                >
<!--                    <Avatar-->
<!--                        :name="note?.user?.name ?? 'Loading User'"-->
<!--                        :ring="'online'"-->
<!--                        :size="'sm'"-->
<!--                    />-->
                </div>
                <div class="box px-3 py-2 sm:px-5 sm:py-3 flex-1 flex-shrink min-w-0 zoom-in">
                    <div class="flex items-center">
                        <div class="font-medium truncate">
                            {{ initials(note?.user?.name) ?? "Loading user..." }}
                        </div>
                        <div class="text-xs text-slate-500 ml-auto whitespace-nowrap">
                            {{ moment(note.created_at, 'MM-DD-YYYY HH:mm A').format('MM/DD hh:mm A') }}
                        </div>
                    </div>
                    <div class="text-slate-500 mt-1 overflow-hidden text-ellipsis whitespace-nowrap">
                        {{ note.note }}
                    </div>
                </div>

            </div>
        </div>

        <div class="mt-4 flex flex-col gap-y-4">
            <Textarea
                v-model="form.note"
                class="w-full text-sm"
                :class="{ 'border-red-500': validate.note.$error }"
                placeholder="Add your notes here..."
                rows="5"
            />
            <template v-if="validate.note.$error">
                <div
                    v-for="(error, index) in validate.note.$errors"
                    :key="index"
                    class="text-danger"
                >
                    {{ error.$message }}
                </div>
            </template>

            <Button
                id="send"
                variant="customPrimary"
                :disabled="loading"
                @click="handleSubmit"
            >
                Send
            </Button>
        </div>
    </div>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Invoice note has been successfully added.
            </div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>

<style scoped></style>
