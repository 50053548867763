import { createRouter, createWebHistory, RouteRecordName } from "vue-router";
import PermissionInterface from "@/pages/interfaces/permissionInterface";
import Login from "../pages/Login.vue";
import SideMenu from "../pages/layout/SideMenu.vue";
import ClientsView from "../pages/ClientsView.vue";
import ClientDetailsView from "../pages/ClientDetailsView.vue";
import EmployeesView from "../pages/EmployeesView.vue";
import EmployeeDetailsView from "../pages/EmployeeDetailsView.vue";
import EmployeesAttendanceView from "../pages/EmployeesAttendanceView.vue";
import MyTeam from "../pages/MyTeam.vue";
import TeamView from "../pages/TeamView.vue";
import Tickets from "../pages/Tickets.vue";
import TicketDetails from "../pages/TicketDetails.vue";
import SubmitATicket from "../pages/SubmitATicket.vue";
import TicketList from "../pages/TicketList.vue";
import MyTickets from "../pages/MyTickets.vue";
import TicketsForApproval from "../pages/TicketsForApproval.vue";
import Dashboard from "../pages/Dashboard.vue";
import DashboardHr from "../pages/DashboardHr.vue";
import ShiftsView from "../pages/ShiftsView.vue";
import { DefineComponent } from "vue";
import ActivityLogs from "../pages/ActivityLogs.vue";
import ShiftActivityLogs from "../pages/ShiftActivityLogs.vue";
import { useUiStore } from "../stores/uiStore";
import ClientNotifications from "../pages/ClientNotifications.vue";
import FormClientNotifications from "../pages/forms/clientNotifications/FormClientNotifications.vue";
import FormClientNotificationsPreview from "../pages/forms/clientNotifications/FormClientNotificationsPreview.vue";
import EmployeeShiftsView from "../pages/EmployeeShiftsView.vue";
import ShiftDetailsEmployee from "../pages/ShiftDetailsEmployee.vue";
import SchedulesToday from "../pages/SchedulesToday.vue";
import PayrollConfigurationView from "../pages/payroll/PayrollConfigurationView.vue";
import MyTimesheetView from "../pages/my-timesheet/MyTimesheetView.vue";
import AccountManagementView from "../pages/AccountManagementView.vue";
import AccountManagement from "../pages/AccountManagement.vue";
import RolesAndPermissions from "../pages/RolesAndPermissions.vue";
import DashboardInvoices from "../pages/DashboardInvoices.vue";
import InvoiceView from "../pages/InvoiceView.vue";
import InvoiceDetailsView from "../pages/InvoiceDetailsView.vue";

let availableRoutes = [
    {
        path: "clients",
        name: "clients",
        component: ClientsView,
        breadcrumb: "All Clients",
        meta: {
            requiredPermission: "can view all clients",
        },
    },
    {
        path: "clients/:id",
        name: "clientsDetails",
        component: ClientDetailsView,
        meta: {
            requiredPermission: "can read clients",
        },
    },
    {
        path: "client-notifications",
        name: "clientNotifications",
        component: ClientNotifications,
        meta: {
            requiredPermission: [
                "can all view client notifications",
                "can view all clients"
            ],
        },
    },
    {
        path: "client-notifications-add",
        name: "clientNotificationsAdd",
        component: FormClientNotifications,
        meta: {
            requiredPermission: "can create client notifications",
        },
    },
    {
        path: "client-notifications-preview",
        name: "clientNotificationsPreview",
        component: FormClientNotificationsPreview,
        meta: {
            requiredPermission: "can all view client notifications",
        },
    },
    {
        path: "employees",
        name: "employees",
        component: EmployeesView,
        meta: {
            requiredPermission: [
                "can view all employees",
                "can read employees",
            ],
        },
    },
    {
        path: "employees/attendance",
        name: "employeesAttendance",
        component: EmployeesAttendanceView,
        meta: {
            requiredPermission: [
                "can view attendance",
                "can view all employees"
            ],
        },
    },
    {
        path: "employees/schedule-today",
        name: "employeesScheduleToday",
        component: SchedulesToday,
        meta: {
            requiredPermission: "can view employee schedule today",
        },
    },
    {
        path: "employees/shifts",
        name: "employeesShifts",
        component: EmployeeShiftsView,
        meta: {
            requiredPermission: [
                "can view employee shifts",
                "can view all employees"
            ],
        },
    },
    {
        path: "employees/:id",
        name: "employeesDetails",
        component: EmployeeDetailsView,
        meta: {
            requiredPermission: [
                "can view all employees",
                "can read employees",
            ],
        },
    },
    {
        path: "my-team",
        name: "myTeam",
        component: MyTeam,
    },
    {
        path: "/teams",
        name: "teams",
        component: TeamView,
        meta: {
            requiredPermission: "can view all teams",
        },
    },
    {
        path: "/all-tickets",
        name: "tickets",
        component: Tickets,
        meta: {
            requiredPermission: "can view all tickets",
        },
    },
    {
        path: "/ticket-details/:id",
        name: "ticketDetails",
        component: TicketDetails,
        meta: {
            requiredPermission: [
                "can view all tickets",
                "can view assigned tickets",
                "can view ticket for cctv recordings",
                "can view ticket for log ins activation",
                "can view ticket for log ins deactivation",
                "can view new candidate search ticket",
            ],
        },
    },
    {
        path: "/submit-a-ticket",
        name: "submitATicket",
        component: TicketList,
        meta: {
            requiredPermission: "can create ticket",
        },
    },
    {
        path: "/ticket",
        name: "ticket",
        component: SubmitATicket,
        meta: {
            requiredPermission: "can create ticket",
        },
    },
    {
        path: "/my-tickets",
        name: "myTickets",
        component: MyTickets,
        meta: {
            requiredPermission: "can create ticket",
        },
    },
    {
        path: "/tickets-for-approval",
        name: "ticketsForApproval",
        component: TicketsForApproval,
        meta: {
            requiredPermission: [
                "can approve all tickets",
                "can approve assigned tickets",
            ],
        },
    },
    {
        path: "/shifts",
        name: "shifts",
        component: ShiftsView,
        meta: {
            requiredPermission: "can view employee shifts",
        },
    },
    {
        path: "/shifts/:id",
        name: "shiftsDetails",
        component: ShiftDetailsEmployee,
        meta: {
            requiredPermission: "can view employee shift details",
        },
    },
    {
        path: "/activity-logs",
        name: "activityLogs",
        component: ActivityLogs,
        meta: {
            requiredRole: ["admin", "hr"],
        },
    },
    {
        path: "/activity-logs/shifts",
        name: "shiftActivityLogs",
        component: ShiftActivityLogs,
        meta: {
            requiredRole: ["admin", "hr"],
        },
    },
    {
        path: "/payroll-configurations",
        name: "payrollConfigurations",
        component: PayrollConfigurationView,
        meta: {
            requiredPermission: "can view payroll configurations",
        },
    },
    {
        path: "/my-timesheets",
        name: "myTimesheets",
        component: MyTimesheetView,
        // meta: {
        //     requiredPermission: "can view my timesheets",
        // },
    },
    {
        path: "/account-management-view",
        name: "accountManagementView",
        component: AccountManagementView,
        meta: {
            requiredRole: "admin",
        },
    },
    {
        path: "/account-management/:id",
        name: "account-management",
        component: AccountManagement,
        meta: {
            requiredRole: "admin",
        },
    },
    {
        path: "/roles-and-permissions",
        name: "rolesAndPermissions",
        component: RolesAndPermissions,
        meta: {
            requiredRole: "admin",
        },
    },
    {
        path: "invoices-dashboard",
        name: "invoicesDashboard",
        component: DashboardInvoices,
        meta: {
            requiredPermission: "can view invoices",
        },
    },
    {
        path: "/invoices",
        name: "invoices",
        component: InvoiceView,
        meta: {
            requiredPermission: "can view invoices",
        },
    },
    {
        path: "/invoices/:id",
        name: "invoice-details",
        component: InvoiceDetailsView,
        meta: {
            requiredPermission: "can view invoices",
        },
    },
];

// @ts-ignore
const roles = window.Laravel.role;
// @ts-ignore
if (window.Laravel.isLoggedin) {
    const hrDasbhoard = [
        "admin",
        "payroll",
        "accounts receivable",
        "hr",
        "recruitment",
        "it",
    ];

    let isHrDashboard = hrDasbhoard.includes(roles[0]);
    let isEmployee = roles[0] === "employee";

    if (isEmployee) {
        // Remove multiple route names from availableRoutes
        availableRoutes = availableRoutes.filter(
            (route) =>
                route.name !== "clients" &&
                route.name !== "clientsDetails" &&
                route.name !== "clientNotifications" &&
                route.name !== "clientNotificationsAdd" &&
                route.name !== "clientNotificationsPreview" &&
                route.name !== "employees" &&
                route.name !== "employeesAttendance" &&
                route.name !== "employeesScheduleToday" &&
                route.name !== "employeesShifts" &&
                route.name !== "employeesDetails" &&
                route.name !== "teams" &&
                route.name !== "shifts" &&
                route.name !== "invoice-details" &&
                route.name !== "invoices" &&
                route.name !== "invoicesDashboard" &&
                route.name !== "rolesAndPermissions" &&
                route.name !== "account-management" &&
                route.name !== "accountManagementView" &&
                route.name !== "payrollConfigurations" &&
                route.name !== "activityLogs" &&
                route.name !== "tickets"
        );
    }

    if (isHrDashboard) {
        availableRoutes.push({
            path: "/",
            name: "dashboard",
            component: DashboardHr as DefineComponent<
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                ""
            >,
        });
    } else {
        availableRoutes.push({
            path: "/",
            name: "dashboard",
            component: Dashboard,
        });
    }
}

export const routes = [
    {
        path: "/user-login",
        name: "login",
        component: Login,
    },
    {
        path: "/",
        component: SideMenu,
        children: availableRoutes,
    },
    {
        path: "/forbidden",
        name: "forbidden",
        component: () => import("@/pages/components/403.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "page-not-found",
        component: () => import("@/pages/components/404.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { left: 0, top: 0 };
    },
});

router.beforeEach((to, next) => {
    const publicRoutes = <RouteRecordName[]>[
        "login",
        "forgot-password",
        "reset-password",
        "forbidden",
        "page-not-found",
    ];

    const UiStore = useUiStore();
    //@ts-ignore
    UiStore.setBreadcrumb(to.name);
    const isAuthenticated = (window as any).Laravel?.isLoggedin;
    const requiredRole = to.meta.requiredRole;
    const requiredPermission = to.meta.requiredPermission;

    if (isAuthenticated) {
        if (to.name === "login" || to.name === "forgot-password" || to.name === "reset-password") {
            return "/";
        } else if (requiredRole) {
            const allowedRoles = Array.isArray(requiredRole) ? requiredRole : [requiredRole];
            if (
                (window as any).Laravel.role.filter((role: string) =>
                    allowedRoles.includes(role)
                ).length === 0
            ) {
                return "/forbidden";
            }
        } else if (requiredPermission) {
            const allowedPermissions = Array.isArray(requiredPermission) ? requiredPermission : [requiredPermission];
            if (
                (window as any).Laravel.permissions.filter(
                    (permission: PermissionInterface) =>
                        allowedPermissions.includes(permission.name)
                ).length === 0
            ) {
                return "/forbidden";
            }
        } else if (!to.matched.length) {
            return "/page-not-found";
        }
    } else if (!isAuthenticated) {
        if (!publicRoutes.includes(to.name || "")) {
            sessionStorage.setItem("redirectUrl", to.fullPath);

            return "/user-login";
        }
    }
});

export default router;
