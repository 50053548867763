<script lang="ts" setup>
import _ from "lodash";
import Button from "../../../base-components/Button";
import moment from "moment/moment";
import Lucide from "../../../base-components/Lucide/Lucide.vue";
import { useAuthStore } from "../../../stores/authStore";
import { useRoute } from "vue-router";
import { onMounted, PropType, ref } from "vue";
import CustomIcon from "../../Icons/CustomIcon.vue";
import { Menu } from "../../../base-components/Headless";
import FormSlider from "../../FormSlider.vue";
import EmployeeInterface, {
    EmployeeStatusHistoryInterface,
} from "../../interfaces/employeesInterface";
import CardSkeleton from "../CardSkeleton.vue";
import { useHelperStore } from "@/stores/helperStore";

const loading = ref(false);
const AuthStore = useAuthStore();
const HelperStore = useHelperStore();

const route = useRoute();

const { employee } = defineProps({
    employee: {
        type: Object as PropType<EmployeeInterface>,
        required: true,
    },
});

const slider = ref(false);
const selected = ref<EmployeeStatusHistoryInterface>();

const onClickUpdate = (item: EmployeeStatusHistoryInterface) => {
    selected.value = item;
    slider.value = !slider.value;
};

const onClose = () => {
    slider.value = !slider.value;
    selected.value = {} as EmployeeStatusHistoryInterface;
};

onMounted(async () => {
    loading.value = true;
    await HelperStore.fillEmployeeStatusHistories(
        +route.params.id
    );
    console.log('status histories', HelperStore.employeeStatusHistories)
    loading.value = false;
});
</script>

<template>
    <CardSkeleton v-if="loading" />

    <div v-else class="intro-y box mt-5 lg:mt-0 mb-5">
        <div class="relative flex items-center py-3 px-4">
            <h2 class="font-medium text-base mr-auto">Status History</h2>
        </div>

        <div
           
            class="p-2 border-t border-slate-200/60 dark:border-darkmode-400"
        >
            <ul
                class="rounded-md divide-y divide-gray-200 mb-2 shadow"
                role="list"
            >
                <li
                    v-for="(
                        item, index
                    ) in HelperStore.employeeStatusHistories"
                    :key="item.id"
                    class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                    <div class="ml-2 flex-1 w-0 truncate">
                        <div
                            class="mt-2 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Status Type: </div>
                            <div class="font-bold ml-2">
                                {{ item.employee_status_type?.name }}
                            </div>
                        </div>

                        <div
                            class="mt-2 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Status Group:</div>
                            <div>
                                {{ item.employee_status_type?.employee_status_type_group?.name }}
                            </div>
                        </div>
                        <div
                            class="mt-2 flex justify-between text-gray-500 w-2/4"
                        >
                            <div>Effective Date:</div>
                            <div>
                                {{
                                    moment(item.start_date).format(
                                        "YYYY-MM-DD"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div
            v-if="
                HelperStore.employeeStatusHistories.length === 0
            "
            class="px-4 py-6 sm:px-6"
        >
            No status history yet.
        </div>
    </div>
</template>

<style scoped></style>
