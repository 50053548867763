<script lang="ts" setup>
import { onMounted, reactive, ref, toRefs } from "vue";
import PageHeaderStandard from "../../components/PageHeaderStandard.vue";
import { useClientsStore } from "../../../stores/clientsStore";
import { useUserStore } from "../../../stores/userStore";
import { useAuthStore } from "../../../stores/authStore";
import { useClientNotificationsStore } from "../../../stores/clientNotificationsStore";
import { required } from "@vuelidate/validators";
import _ from "lodash";
import { useVuelidate } from "@vuelidate/core";
import router from "../../../router";
import Button from "../../../base-components/Button";
import { FormInput, FormLabel } from "../../../base-components/Form";
import Lucide from "../../../base-components/Lucide";
import { ClassicEditor } from "../../../base-components/Ckeditor";

const ClientStore = useClientsStore();
const ClientNotificationsStore = useClientNotificationsStore();

const UserStore = useUserStore();

const AuthStore = useAuthStore();

const editorData = ref("<p>Content of the editor.</p>");

const editorConfig = {
    toolbar: {
        items: ["bold", "italic"],
    },
};
const selectAll = ref(true);

onMounted(async () => {
    ClientNotificationsStore.poc_id = [];
    console.log("mounted client notifications add");
    // await AuthStore.getLoggedInUserDetails();

    await ClientStore.getAllClients().then((response) => {
        console.log("response", ClientStore.clients);
        if (!_.isEmpty(ClientNotificationsStore.s_poc_id)) {
            ClientNotificationsStore.poc_id = ClientNotificationsStore.s_poc_id;
        } else {
            _.forEach(ClientStore.clients, function (client) {
                _.forEach(client.point_of_contacts, function (poc) {
                    ClientNotificationsStore.poc_id.push(poc.user_id);
                    console.log("poc.id", poc.id);
                });
            });
        }
    });

    await UserStore.getUsersForNotifications();
    console.log(ClientStore.clients);
});

interface FormDataInterface {
    poc: boolean;
    bcc: boolean;
    recipients: string;
    subject: string;
    body: string;
    attachment: any[];
    bcc_id: any[];
    poc_id: any[];
}

const addFormData = reactive({
    poc: true,
    bcc: true,
    recipients: "",
    subject: "",
    body: "",
    attachment: [],
    bcc_id: [] as { id: number; email: string }[],
    poc_id: [] as number[],
});

const rules = {
    poc: { required },
    bcc: { required },
    recipients: {},
    subject: { required },
    body: { required },
};

const validate = useVuelidate(rules, toRefs(addFormData));
const submit = async () => {
    validate.value.$touch();
    if (validate.value.$invalid) {
    } else {
        const save = ref(true);
        addFormData.poc_id = ClientNotificationsStore.poc_id;
        const form = new FormData();
        if (attachments.data.length > 0) {
            _.forEach(attachments.data, function (file) {
                if (
                    file instanceof Blob &&
                    typeof file.type === "string" &&
                    file.type.length > 0
                ) {
                    form.append("attachments[]", file);
                }
            });
            console.log(form);
            await ClientNotificationsStore.uploadTemp(form).then((response) => {
                if (response && response.status == "error") {
                    save.value = false;
                    console.log("show error toasts");
                    attachments.data = [];
                } else {
                    if (response && response.data) {
                        ClientNotificationsStore.temp.attachment =
                            response.data;
                        addFormData.attachment = response.data;
                        console.log(ClientNotificationsStore.temp.attachment);
                    }
                }
            });
        }
        if (save.value === true) {
            ClientNotificationsStore.s_poc_id = ClientNotificationsStore.poc_id;
            ClientNotificationsStore.poc_id = [];
            addFormData.bcc_id = [];
            _.forEach(UserStore.usersForNotifications, function (bcc) {
                addFormData.bcc_id.push({ id: bcc.id, email: bcc.email });
            });
            //@ts-ignore
            ClientNotificationsStore.saveTemp(addFormData);
            console.log("show success toasts");
            router.push({ name: "clientNotificationsPreview" });
        }
    }
};
const resetForm = () => {
    addFormData.poc_id = [];
    addFormData.bcc_id = [];
    addFormData.recipients = "";
    addFormData.subject = "";
    addFormData.body = "";
};

const updateTempPoc = (id: any) => {
    console.log("updateTempPoc", id);
    ClientNotificationsStore.updateTempPoc(id);
};

interface AttachmentsInterface {
    data: File[];
}

const attachments: AttachmentsInterface = {
    data: [],
};

const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files) {
        const fileList = Array.from(files);
        attachments.data = [...attachments.data, ...fileList];
    }
};

const selectAllCheckBoxes = (e: Event) => {
    const target = e.target as HTMLInputElement;
    console.log(target.checked);
    selectAll.value = target.checked;
    if (selectAll.value) {
        _.forEach(ClientStore.clients, function (client) {
            _.forEach(client.point_of_contacts, function (poc) {
                ClientNotificationsStore.poc_id.push(poc.user_id);
                console.log("poc.id", poc.id);
            });
        });
    } else {
        ClientNotificationsStore.poc_id = [];
    }
};
</script>
<template>
    <div>
        <PageHeaderStandard :title="'Add new client notification'" />

        <form @submit.prevent="submit">
            <div class="mt-4">
                <span class="text-md font-bold">Client Contacts</span>
                <div class="form-check form-switch mt-2">
                    <input
                        :checked="selectAll"
                        class="form-check-input"
                        name="select_all"
                        type="checkbox"
                        @click="selectAllCheckBoxes($event)"
                    />
                    <label class="form-check-label text-sm" for="select_all">
                        Select All
                    </label>
                </div>
            </div>
            <div
                class="grid grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-1 mt-4"
            >
                <template v-for="client in ClientStore.clients">
                    <div
                        class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                    >
                        <div
                            class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                        >
                            <h2 class="font-medium text-sm mr-auto mb-4">
                                {{ client.legal_business_name }}
                            </h2>
                        </div>
                        <div class="mt-3">
                            <div class="mt-4">
                                <template
                                    v-for="poc in client.point_of_contacts"
                                >
                                    <div class="form-check form-switch mt-2">
                                        <input
                                            :checked="
                                                Object.values(
                                                    ClientNotificationsStore.poc_id
                                                ).includes(poc.user_id)
                                            "
                                            :value="poc.user_id"
                                            class="form-check-input"
                                            name="poc"
                                            type="checkbox"
                                            @change="updateTempPoc(poc.user_id)"
                                        />
                                        <label
                                            class="form-check-label text-sm"
                                            for="poc.user_id"
                                        >
                                            {{ poc.full_name }}
                                        </label>
                                    </div>
                                </template>
                                <template v-if="validate.poc.$error">
                                    <div
                                        v-for="(error, index) in validate.poc
                                            .$errors"
                                        :key="index"
                                        class="text-danger mt-2"
                                    >
                                        {{ error.$message }}
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div class="mt-4">
                <div
                    class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                >
                    <div
                        class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                    >
                        <h2 class="font-medium text-base mr-auto mb-4">
                            Internal users to be bcc'd
                        </h2>
                    </div>
                    <div class="mt-3 flex">
                        <template
                            v-for="user in UserStore.usersForNotifications"
                        >
                            <div class="">
                                <div class="form-check form-switch mt-2 ml-2">
                                    <label
                                        class="py-1 px-2 rounded-full text-xs bg-success text-white font-medium"
                                        >{{ user.name }}
                                    </label>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <div
                    class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                >
                    <div
                        class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                    >
                        <h2 class="font-medium text-base mr-auto mb-4">
                            Additional Information
                        </h2>
                    </div>
                    <div class="mt-4">
                        <FormLabel class="form-label" for="legal_business_name">
                            Additional Recipients
                        </FormLabel>
                        <FormInput
                            v-model.trim="addFormData.recipients"
                            class="form-control w-full"
                            name="recipients"
                            placeholder=""
                            type="text"
                        />
                        <span class="text-gray-500 text-xs"
                            >Comma separated emails. Ex. name@remotemployee.com,
                            name2@remoteemployee.com</span
                        >

                        <template v-if="validate.recipients.$error">
                            <div
                                v-for="(error, index) in validate.recipients
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <FormLabel class="form-label" for="legal_business_name">
                            Subject <span style="color: rgb(255, 0, 0)">*</span>
                        </FormLabel>
                        <FormInput
                            v-model.trim="validate.subject.$model"
                            class="form-control w-full"
                            name="subject"
                            placeholder=""
                            type="text"
                        />

                        <template v-if="validate.subject.$error">
                            <div
                                v-for="(error, index) in validate.subject
                                    .$errors"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                    <div class="mt-4">
                        <label class="form-label" for="body">
                            Body / Message
                            <span style="color: rgb(255, 0, 0)">*</span>
                        </label>
                        <ClassicEditor
                            id="body"
                            v-model.trim="addFormData.body"
                            :config="editorConfig"
                            name="body"
                        />
                        <template v-if="validate.body.$error">
                            <div
                                v-for="(error, index) in validate.body.$errors"
                                id="error_body"
                                :key="index"
                                class="text-danger mt-2"
                            >
                                {{ error.$message }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <div
                    class="border border-gray-400/50 px-4 py-6 rounded-md shadow-lg"
                >
                    <div
                        class="flex items-center border-b border-gray-400/50 dark:border-darkmode-400"
                    >
                        <h2 class="font-medium text-base mr-auto mb-4">
                            Attachments
                        </h2>
                    </div>
                    <div class="mt-3 sm:col-span-2">
                        <label
                            class="form-label inline-block mb-2 text-gray-700"
                            for="message"
                            >Select your files:</label
                        >
                        <div class="mt-1">
                            <input
                                class="form-control block w-3/4 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                multiple
                                name="attachments"
                                type="file"
                                @change="uploadFile"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4 flex flex-row-reverse">
                <Button
                    class="btn bg-custom-color-1000 text-white w-42 mr-1 mb-1"
                    type="submit"
                >
                    Continue
                    <Lucide class="w-4 h-4 mr-2" icon="ChevronRight" />
                </Button>
            </div>
        </form>
    </div>
    <!-- BEGIN: Success Notification Content -->
    <div
        id=" success-notification-content"
        class="toastify-content hidden flex"
    >
        <Lucide icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Success</div>
            <div class="text-slate-500 mt-1">
                Notification was saved successfully
            </div>
        </div>
    </div>
    <!-- END: Success Notification Content -->
    <!-- BEGIN: Failed Notification Content -->
    <div id="failed-notification-content" class="toastify-content hidden flex">
        <Lucide icon="XCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">Error occured!</div>
            <div class="text-slate-500 mt-1">
                Notification was not saved successfully. Please try again.
            </div>
        </div>
    </div>
</template>
