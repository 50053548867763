<script setup lang="ts">
import {
    reactive,
    defineEmits,
    ref,
    toRefs,
    provide,
    defineProps,
    onMounted,
} from "vue";
import { PayrollConfigurationInterface } from "../../interfaces/payrollConfigurationInterface";
import { FormInput, FormLabel } from "../../../base-components/Form";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import FormTextarea from "../../../base-components/Form/FormTextarea.vue";
import Button from "../../../base-components/Button";
import Notification, {
    NotificationElement,
} from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import { usePayrollConfigurationStore } from "../../../stores/payrollConfigurationStore";

const PayrollConfigurationStore = usePayrollConfigurationStore();

const emit = defineEmits(["close"]);
const props = defineProps({
    payrollConfiguration: {
        type: Object as () => PayrollConfigurationInterface,
        default: () => ({}),
    },
});

const form = reactive<PayrollConfigurationInterface>({
    id: 0,
    start_date: "",
    end_date: "",
    time_correction_end_date: "",
    lock_in_date: "",
    description: "",
    notes: "",
});
const rules = {
    start_date: { required },
    end_date: { required },
    time_correction_end_date: { required },
    lock_in_date: { required },
    description: "",
    notes: "",
};
const $externalResults = ref({});
const validate = useVuelidate(rules, toRefs(form), { $externalResults });
const backendValidationErrors = ref({});
const errorNotification = ref<NotificationElement>();
const successNotification = ref<NotificationElement>();
const notificationMessage = ref("");

const errorNotificationToggle = () => {
    // Show notification
    errorNotification.value?.showToast();
};
provide("bind[errorNotification]", (el: NotificationElement) => {
    errorNotification.value = el;
});
const showErrorsNotification = () => {
    errorNotificationToggle();
};

const successNotificationToggle = () => {
    successNotification.value?.showToast();
};
provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});
const showSuccessNotification = () => {
    successNotificationToggle();
};

const closeModal = () => {
    resetForm();
    emit("close");
};

const resetForm = () => {
    form.id = 0;
    form.start_date = "";
    form.end_date = "";
    form.time_correction_end_date = "";
    form.lock_in_date = "";
    form.description = "";
    form.notes = "";
};

const onSubmit = async () => {
    validate.value.$reset();
    validate.value.$clearExternalResults();
    validate.value.$touch();

    if (validate.value.$invalid) {
        backendValidationErrors.value = {
            message: ["Incomplete or Missing required data"],
        };

        showErrorsNotification();
        return;
    }

    try {
        props.payrollConfiguration.id ? await update() : await save();

        validate.value.$reset();
        closeModal();
        showSuccessNotification();
    } catch (error: any) {
        $externalResults.value = error?.response?.data?.errors;
    }
};

const save = async () => {
    await PayrollConfigurationStore.savePayrollConfiguration(form);
    notificationMessage.value = "Payroll configuration has been added.";
};

const update = async () => {
    await PayrollConfigurationStore.updatePayrollConfiguration(form);
    notificationMessage.value = "Payroll configuration has been updated.";
};

onMounted(async () => {
    const { payrollConfiguration } = toRefs(props);

    if (payrollConfiguration.value.id) {
        form.id = payrollConfiguration.value.id;
        form.start_date = payrollConfiguration.value.start_date;
        form.end_date = payrollConfiguration.value.end_date;
        form.time_correction_end_date =
            payrollConfiguration.value.time_correction_end_date;
        form.lock_in_date = payrollConfiguration.value.lock_in_date;
        form.description = payrollConfiguration.value.description;
        form.notes = payrollConfiguration.value.notes;
    }
});
</script>

<template>
    <form @submit.prevent="onSubmit">
        <div class="grid grid-cols-12 gap-6 mt-5 items-left text-left">
            <div class="intro-y col-span-12">
                <div class="intro-y pb-5 pl-5 pr-2">
                    <div class="grid grid-cols-12 gap-6">
                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="start_date"
                                >Start Date
                                <span class="text-red-600">*</span>
                            </FormLabel>
                            <FormInput
                                id="start_date"
                                v-model="form.start_date"
                                :value="form.start_date"
                                class="form-control w-full"
                                maxlength="50"
                                name="start_date"
                                type="date"
                                max="9999-12-31"
                            />
                            <template v-if="validate.start_date.$error">
                                <div
                                    v-for="(error, index) in validate.start_date
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="end_date"
                                >End Date
                                <span class="text-red-600">*</span>
                            </FormLabel>
                            <FormInput
                                id="end_date"
                                v-model="form.end_date"
                                :value="form.end_date"
                                class="form-control w-full"
                                maxlength="50"
                                name="end_date"
                                type="date"
                                max="9999-12-31"
                            />
                            <template v-if="validate.end_date.$error">
                                <div
                                    v-for="(error, index) in validate.end_date
                                        .$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel
                                class="form-label"
                                for="time_correction_end_date"
                                >Time Correction End Date
                                <span class="text-red-600">*</span>
                            </FormLabel>
                            <FormInput
                                id="time_correction_end_date"
                                v-model="form.time_correction_end_date"
                                :value="form.time_correction_end_date"
                                class="form-control w-full"
                                maxlength="50"
                                name="time_correction_end_date"
                                type="datetime-local"
                                max="9999-12-31 23:59:59"
                            />
                            <template
                                v-if="validate.time_correction_end_date.$error"
                            >
                                <div
                                    v-for="(error, index) in validate
                                        .time_correction_end_date.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>

                        <div class="mt-4 col-span-12 lg:col-span-6">
                            <FormLabel class="form-label" for="lock_in_date"
                                >Lock In Date
                                <span class="text-red-600">*</span>
                            </FormLabel>
                            <FormInput
                                id="lock_in_date"
                                v-model="form.lock_in_date"
                                :value="form.lock_in_date"
                                class="form-control w-full"
                                maxlength="50"
                                name="lock_in_date"
                                type="datetime-local"
                                max="9999-12-31 23:59:59"
                            />
                            <template v-if="validate.lock_in_date.$error">
                                <div
                                    v-for="(error, index) in validate
                                        .lock_in_date.$errors"
                                    :key="index"
                                    class="text-danger mt-2"
                                >
                                    {{ error.$message }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="description"
                            >Description
                        </FormLabel>

                        <FormTextarea
                            id="description"
                            v-model="form.description"
                            :value="form.description"
                            class="form-control w-full"
                        />
                    </div>

                    <div class="mt-4">
                        <FormLabel class="form-label" for="notes"
                            >Notes
                        </FormLabel>

                        <FormTextarea
                            id="notes"
                            v-model="form.notes"
                            :value="form.notes"
                            class="form-control w-full"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="text-right p-2">
                <Button
                    id="btn_cancel"
                    class="w-32 mb-2 mr-1"
                    type="button"
                    variant="warning"
                    @click="closeModal"
                >
                    Cancel
                </Button>
                <Button
                    id="btn_process"
                    class="w-36 mb-2 mr-1"
                    type="submit"
                    variant="customPrimary"
                >
                    {{ props.payrollConfiguration.id ? "Update" : "Save" }}
                </Button>
            </div>
        </div>
    </form>

    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                {{ notificationMessage }}
            </div>
        </div>
    </Notification>

    <Notification
        v-if="backendValidationErrors && Object.keys(backendValidationErrors).length"
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="errorNotification"
    >
        <Lucide class="text-danger" icon="X" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                <ul class="list-disc">
                    <template v-for="errorBag in backendValidationErrors">
                        <li class="text-red-500">
                            {{ errorBag[0] }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </Notification>
</template>
