<script lang="ts" setup>
import {defineEmits, defineProps, onMounted, provide, reactive, ref, toRefs,} from "vue";
import {helpers, required, requiredIf} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useHelperStore} from "@/stores/helperStore";
import {useRoute} from "vue-router";
import {useEmployeesStore} from "@/stores/employeesStore";
import {useTicketsStore} from "@/stores/ticketsStore";
import moment from "moment";
import Button from "@/base-components/Button";
import Litepicker from "@/base-components/Litepicker";
import {
    FormInput,
    FormLabel,
    FormSwitch,
} from "@/base-components/Form";
import _ from "lodash";
import Notification, {NotificationElement} from "@/base-components/Notification";
import Lucide from "@/base-components/Lucide";
import router from "@/router";
import EmployeeInterface from "@/pages/interfaces/employeesInterface";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});
const TicketsStore = useTicketsStore();

const props = defineProps({
    ticketTypeId: Number,
    wfhSetup: {
        type: Array,
        default: () => [
            "Mini - PC",
            "Monitor",
            "Keyboard",
            "Mouse",
            "Headset",
            "LAN/Ethernet Cable",
            "Others"
        ]
    }
});

const emit = defineEmits(["output", "ticketType"]);

const HelperStore = useHelperStore();

const EmployeesStore = useEmployeesStore();

const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const employee = reactive({
    data: null as EmployeeInterface | null,
});

const wfhSetup = ref<string[]>(props.wfhSetup as string[]);

const ticketForm = reactive({
    subject: "Request for WFH PC Setup",
    wfhSetup: [] as Array<string>,
    others: "",
    dateOfPickUp: moment().add(1,'days').format('YYYY-MM-DD'),
    timeOfPickUp: "",
});

const rules = {
    wfhSetup: {required},
    others: { required: requiredIf(() => ticketForm.wfhSetup.includes("Others")) },
    dateOfPickUp: {required, dateAfter: helpers.withMessage('The date of pickup must be after today', (value: string) => {
        return new Date(value) > new Date();
    })},
    timeOfPickUp: {required},
};

const toggleWFHSetup = (item: string) => {
    if (ticketForm.wfhSetup.includes(item)) {
        ticketForm.wfhSetup = ticketForm.wfhSetup.filter((i) => i !== item);
    } else {
        ticketForm.wfhSetup.push(item);
        // ensure that Others is always at the end of the array values
        if (item !== "Others" && ticketForm.wfhSetup.includes('Others')) {
            ticketForm.wfhSetup = ticketForm.wfhSetup.filter((i) => i !== 'Others');
            ticketForm.wfhSetup.push('Others');
        }
    }
};

onMounted(async () => {
    //@ts-ignore
    //@ts-ignore
    console.log("populateEmployeeDetails", window.Laravel.user.id);

    await EmployeesStore.getUserEmployeeData(
        //@ts-ignore
        parseInt(window.Laravel.user.id)
    ).then((response) => {
        employee.data = EmployeesStore.userEmployee;
        console.log("AU", employee.data);
    });
});

const formReset = () => {
    ticketForm.wfhSetup = [];
    ticketForm.others = "";
    ticketForm.dateOfPickUp = moment().add(1, 'days').format('YYYY-MM-DD');
    ticketForm.timeOfPickUp = "";
    attachments.data = [];
};

const validate = useVuelidate(rules, toRefs(ticketForm));
const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const wfhSetupText = ticketForm.wfhSetup.join(', ') + (ticketForm.wfhSetup.includes('Others') ? `: ${ticketForm.others}`: '');

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("WFH Setup", wfhSetupText);
        desc_data.append("Date of Pick Up", ticketForm.dateOfPickUp);
        desc_data.append("Time of Pick Up", ticketForm.timeOfPickUp);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        //@ts-ignore
        const userName = window.Laravel.user.name;
        form.append(
            "subject",
            ticketForm.subject + ": " + userName
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? '');
        form.append("status", ticketType?.f_status_id?.toString() ?? '');
        form.append("type", ticketType?.f_type?.toString() ?? '');
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? '');

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        TicketsStore.save(form).then((response) => {
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", {render: true, type: "success"});
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: {id: response.data.id},
                });
            } else {
                if(response.error?.errors) {
                attachment_errors.value = response.error?.errors['attachments.0']
                }
                if(response.error.error) {
                    attachment_error.value = response.error?.error
                }
                // emit("output", {render: true, type: "danger"});
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});

const attachment_errors = ref<any>({});
const attachment_error = ref<any>(null);
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        // attachments.data.push(Array.from(files));
        const fileArray = Array.from(files);
        attachments.data = [fileArray];
    }
    attachment_errors.value = ""
    attachment_error.value = ""
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-4">
            <FormLabel class="form-label mb-0" for="wfhSetup">
                WFH Setup <span class="text-red-600">*</span>
            </FormLabel>

            <template v-if="validate.wfhSetup.$error">
                <div
                    v-for="(error, index) in validate.wfhSetup.$errors"
                    :key="index"
                    class="text-danger mt-4"
                >
                    {{ error.$message }}
                </div>
            </template>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                <div
                    v-for="item in wfhSetup"
                    :key="item"
                    class="mt-4 flex flex-row align-center items-center"
                >
                    <FormSwitch>
                        <FormSwitch.Input
                            :id="item.toLowerCase()"
                            :checked="ticketForm.wfhSetup.includes(item)"
                            type="checkbox"
                            @change="toggleWFHSetup(item)"
                        />
                    </FormSwitch>

                    <label :for="item.toLowerCase()" class="ml-2">
                        {{ item }}
                    </label>
                </div>

                <div v-if="ticketForm.wfhSetup.includes('Others')" class="md:col-span-2 mt-4">
                    <FormInput
                        v-model="ticketForm.others" 
                        class="form-control w-full"
                        maxlength="50"
                        id="other_items"
                        name="other_items"
                        placeholder="Please specify other item/s..."
                        type="text"
                    />
                    <template v-if="validate.others.$error">
                        <div
                            v-for="(error, index) in validate.others.$errors"
                            :key="index"
                            class="text-danger mt-4"
                        >
                            {{ error.$message }}
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="mt-4 grid grid-cols-12 gap-6">
            <div class="col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="pickup_date">
                    Date of Pick Up <span class="text-red-600">*</span>
                </FormLabel>
                <Litepicker
                    id="pickup_date"
                    v-model="ticketForm.dateOfPickUp"
                    :options="{
                        autoApply: true,
                        format: 'YYYY-MM-DD',
                        startDate: ticketForm.dateOfPickUp,
                        autoRefresh: true,
                        resetButton: false,
                        minDate: moment().add(1,'days').format('YYYY-MM-DD'),
                        dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true,
                        },
                    }"
                    class="form-control w-full block mx-auto"
                    name="pickup_date"
                />
                <template v-if="validate.dateOfPickUp.$error">
                    <div
                        v-for="(error, index) in validate.dateOfPickUp.$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>

            <div class="col-span-12 lg:col-span-6">
                <FormLabel class="form-label" for="pickup_time">
                    Time of Pick Up <span style="color: rgb(255, 0, 0)">*</span>
                </FormLabel>
                <FormInput
                    id="pickup_time"
                    v-model="ticketForm.timeOfPickUp"
                    :value="ticketForm.timeOfPickUp"
                    class="form-control w-full"
                    maxlength="50"
                    name="pickup_time"
                    placeholder=""
                    type="time"
                />
                <template v-if="validate.timeOfPickUp.$error">
                    <div
                        v-for="(error, index) in validate.timeOfPickUp.$errors"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-4 sm:col-span-2">
            <FormLabel class="form-label inline-block mb-2 text-gray-700" for="attachment">
                Attachment
            </FormLabel>
            <div class="mt-1">
                <input
                    id="attachment"
                    class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    multiple
                    type="file"
                    @change="uploadFile"
                />
            </div>
            <template v-if="attachment_error">
                <div
                    id="error_reason"
                    class="text-danger mt-2"
                >
                    {{ attachment_error }}
                </div>
            </template>
            <template v-if="attachment_errors">
                <div
                    v-for="(error, index) in attachment_errors"
                    id="error_reason"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error }}
                </div>
            </template>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning shadow-sm py-2 px-3
                    inline-flex items-center justify-center
                    rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
            >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle"/>
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>

<style>
.litepicker .container__days .day-item.is-today.is-locked,
.litepicker .container__days .day-item.is-today.is-locked:hover {
    color: #9e9e9e !important;
}
</style>
