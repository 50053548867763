<script lang="ts" setup>
import OptionButtons from "./components/clockins/OptionButtons.vue";
import ClockInsLogs from "./components/clockins/ClockInsLogs.vue";
import { useClockInsStore } from "../stores/clockInsStore";
import { useAuthStore } from "../stores/authStore";
import MyShiftDetails from "./components/clockins/MyShiftDetails.vue";
import { computed, onMounted, ref, watch } from "vue";
import moment from "moment-timezone";
import ClockInChart from "./components/clockins/ClockInChart";
import CurrentTIme from "./components/clockins/CurrentTIme.vue";

interface Status {
    status: string;
    color: string;
}

const ClockInsStore = useClockInsStore();
const AuthStore = useAuthStore();
const onShift = ref(1);
const mobileErrorMessoge = ref<string>('All Time Management actions must be done from a PC.');
const networkErrorMessage = ref<string>('Unable to start shift. The network you are connecting from is not found as an authorized office location. If you feel this is an error, please reach out to IT.');

onMounted(async () => {
    console.log("clock ins mounted");
    await ClockInsStore.getMyShiftDetailsToday();

    // @ts-ignore
    if (AuthStore.myShift === "") {
        ClockInsStore.enableClockIn = false;
        ClockInsStore.enableClockInError =
            "No shift assigned to your account. Please contact your Team Leader or HR in order to assign a shift on your account.";
    }
});

const setActiveShiftTab = () => {
    const { myAttendance } = ClockInsStore;
    if (
        !myAttendance ||
        !myAttendance.start_time ||
        myAttendance.start_time.toString() === ""
    ) {
        onShift.value = 1;
    } else if (myAttendance.end_time !== null) {
        onShift.value = 1;
    } else {
        onShift.value = 0;
    }
};

const workStatus = computed<Status>(() => {
    const { myAttendance } = ClockInsStore;

    if (myAttendance.start_time === null) {
        return {
            status: "Pending Clock In",
            color: "text-orange-600",
        };
    }

    if (myAttendance.status === "Clocked In") {
        return {
            status: myAttendance.status,
            color: "text-green-600",
        };
    }

    if (myAttendance.status === "Working Late") {
        return {
            status: myAttendance.status,
            color: "text-orange-600",
        };
    }

    if (myAttendance.status === "Clocked Out") {
        return {
            status: myAttendance.status,
            color: "text-red-600",
        };
    }

    if (myAttendance.status === "On Break") {
        return {
            status: myAttendance.status,
            color: "text-yellow-600",
        };
    }

    return {
        status: "Working",
        color: "text-green-900",
    };
});

watch(() => ClockInsStore.myAttendance, setActiveShiftTab);

const totalHoursRendered = computed(() => {
    const startTime = moment(ClockInsStore.myAttendance.start_time);
    const currentTime = moment(); // Get the current time

    if (startTime.isValid()) {
        const duration = moment.duration(currentTime.diff(startTime));
        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.asMinutes()) % 60;
        const totalMinutes = hours * 60 + minutes;
        console.log(
            `${hours} hours ${minutes} minutes (${totalMinutes} minutes in total)`
        );
        return totalMinutes;
    }

    return 0;
});

const getAllCompletedBreaks = computed(() => {
    const breaks = ClockInsStore.myAttendance.breaks;
    const filteredBreaks = breaks.filter(
        (shiftBreak) => shiftBreak.total_hours !== 0
    );

    const totalMinutesArray = filteredBreaks.map((shiftBreak) => {
        const totalMinutes = shiftBreak.total_hours * 60;
        return totalMinutes;
    });
    return totalMinutesArray;
});

const totalHoursRemaining = computed(() => {
    const totalHours = AuthStore.myShift.total_hours;
    const renderedMinutes = totalHoursRendered.value;
    const remainingMinutes = totalHours * 60 - renderedMinutes;
    const remainingHours = Math.floor(remainingMinutes / 60);
    const remainingMinutesFormatted = remainingMinutes % 60;
    const totalRemainingMinutes =
        remainingHours * 60 + remainingMinutesFormatted;

    return totalRemainingMinutes;
});
</script>
<template>
    <div id="clock-in-container" class="p-4">
        <div v-if="!ClockInsStore.enableClockIn">
            <div class="p-5 mt-2 box bg-danger intro-x shadow-lg">
            <div class="flex flex-wrap gap-3">
                <div class="mr-auto">
                    <div
                        class="flex items-center text-white text-lg text-opacity-70 dark:text-slate-300"
                    >
                        {{ ClockInsStore.enableClockInError }}
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div v-else-if="
                typeof AuthStore.myShift === 'string' &&
                AuthStore.myShift === 'no shift assigned'
            "
            class="p-5 mt-2 box bg-danger intro-x shadow-lg"
        >
            <div class="flex flex-wrap gap-3">
                <div class="mr-auto">
                    <div
                        class="flex items-center text-white text-lg text-opacity-70 dark:text-slate-300"
                    >
                        No shift assigned to your account. Please contact your
                        Team Leader or HR in order to assign a shift on your
                        account.
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <MyShiftDetails />
            <div class="col-span-12 mt-4 intro-y">
                <template v-if="ClockInsStore.myAttendance.start_time">
                    <div
                        class="bg-success p-4 flex items-center mb-6 box dark:border-darkmode-600 text-white text-center"
                        dismissible
                        variant="success"
                    >
                        <div class="mx-8 text-lg lg:text-sm">
                            Clock in on
                            {{
                                moment(
                                    ClockInsStore.myAttendance.start_time
                                ).format("ddd MMMM D, YYYY [at] HH:mm:ss")
                            }}
                        </div>
                    </div>
                </template>
            </div>
            <div
                class="shadow-xl mt-4 intro-x relative before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-300 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70"
            >
                <div class="p-5 box bg-slate-200">
                    <div :class="workStatus.color" class="font-bold text-xl">
                        {{ workStatus.status }}
                    </div>
                    <div v-if="onShift === 0" class="relative">
                        <ClockInChart
                            :height="208"
                            :total-breaks="getAllCompletedBreaks"
                            :totalHoursRemaining="totalHoursRemaining"
                            :totalHoursRendered="totalHoursRendered"
                            class="mt-3"
                        />
                        <CurrentTIme />
                        <div class="mx-auto mt-5 w-52 sm:w-auto">
                            <div class="flex items-center">
                                <span class="truncate">
                                    <b>Expected Time:</b>
                                    {{ AuthStore.myShift.total_hours }}
                                </span>
                                <span class="ml-auto">
                                    <b>Est. Worked Hours:</b> 0.00
                                </span>
                            </div>
                        </div>
                        <!--
                            <div class="mx-auto mt-5 w-52 sm:w-auto">
                                <div class="flex items-center">
                                    <span class="truncate">
                                        <b>Expected Time:</b>
                                        {{ AuthStore.myShift.total_hours }}
                                    </span>
                                    <span class="ml-auto">
                                        <b>Est. Worked Hours:</b> 0.00
                                    </span>
                                </div>
                            </div>
                        -->
                    </div>

                    <div v-else>
                        <div class="relative mt-20">
                            <CurrentTIme />
                        </div>
                    </div>
                </div>
            </div>

            <ClockInsLogs />
            <OptionButtons />
        </div>
        <div v-if="ClockInsStore.enableClockInError === mobileErrorMessoge || ClockInsStore.enableClockInError === networkErrorMessage">
            <OptionButtons />
        </div>
    </div>
</template>
