<script lang="ts" setup>
import { useAuthStore } from "../../stores/authStore";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import { useRoute } from "vue-router";

import router from "../../router";

import { useHelperStore } from "../../stores/helperStore";
import { useUiStore } from "../../stores/uiStore";
import { defineEmits, onUpdated, provide, reactive, ref, toRefs } from "vue";
import { useFreskdeskStore } from "../../stores/freshdeskStore";
import { useTicketsStore } from "../../stores/ticketsStore";
import TomSelect from "../../base-components/TomSelect";
import Button from "../../base-components/Button";
import { ClassicEditor } from "../../base-components/Ckeditor";
import { FormLabel } from "../../base-components/Form";
import _ from "lodash";
import Notification, {
    NotificationElement,
} from "../../base-components/Notification";
import Lucide from "../../base-components/Lucide";

const toastData = reactive({
    message: "",
    subject: "",
    severity: "",
});
const TicketsStore = useTicketsStore();

const AuthStore = useAuthStore();
const HelperStore = useHelperStore();
const UiStore = useUiStore();
const FreshdeskStore = useFreskdeskStore();
const route = useRoute();
const id = Array.isArray(route.query.id) ? route.query.id[0] : route.query.id;
const categories = ref(2);
const emit = defineEmits(["output", "ticketType"]);

const ticketForm = reactive({
    dateFiled: "",
    employeeId: "",
    name: "",
    issueDescription: "",
    requestStatus: "Pending",
    type: "Request",
    priority: "Low",
    issueType: "Hardware",
});

const rules = {
    issueDescription: { required },
    requestStatus: { required },
    type: { required },
    priority: { required },
    issueType: { required },
};

const attachment_files = reactive({
    data: [],
});

const editorConfig = {
    toolbar: {
        items: ["bold", "italic", "link"],
    },
};

onUpdated(() => {
    const ticketType = HelperStore.getTicketType(Number(id ?? 0));
    UiStore.setBreadcrumb("Submit a Ticket > " + ticketType.name);
});

const validate = useVuelidate(rules, toRefs(ticketForm));

const formReset = () => {
    ticketForm.employeeId = "";
    ticketForm.name = "";
    ticketForm.issueDescription = "";
    ticketForm.priority = "";
    ticketForm.issueType = "";
};

const submitTicket = async () => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

    validate.value.$touch();

    if (validate.value.$invalid) {
        console.error("missing data fields");
        toastData.severity = "danger";
        toastData.message = "All fields required!";
        toastData.subject = "Please provide all the required information";
        console.log("show error notification");
    } else {
        const ticketType = HelperStore.getTicketType(Number(id ?? 0));

        const desc_data = new FormData();
        desc_data.append("Date Filed", currentDate);
        desc_data.append("Priority", ticketForm.priority);
        desc_data.append("Issue Type", ticketForm.issueType);
        desc_data.append("Issue Description", ticketForm.issueDescription);

        var form_body = {};
        //@ts-ignore
        desc_data.forEach((value, key) => (form_body[key] = value));

        const form = new FormData();
        form.append("id", String(id));
        form.append(
            "subject",
            "IT - Support Request: " + AuthStore.authUser.name
        );
        form.append("priority", ticketType?.f_priority_id?.toString() ?? "");
        form.append("status", ticketType?.f_status_id?.toString() ?? "");
        form.append("type", ticketType?.f_type?.toString() ?? "");
        form.append("type", ticketType.f_type);
        form.append("form_body", JSON.stringify(form_body));
        form.append("ticket_type_id", ticketType?.id?.toString() ?? "");

        if (attachments.data !== null) {
            _.forEach(attachments.data, function (files) {
                _.forEach(files, function (file) {
                    form.append("attachments[]", file);
                });
            });
        }

        await TicketsStore.save(form).then((response) => {
            console.log("tix response", response);
            if (response.status === "success") {
                validate.value.$reset();
                emit("output", { render: true, type: "success" });
                if (id !== null) {
                    emit("ticketType", HelperStore.getTicketType(parseInt(id)));
                }
                showSuccessNotification();
                formReset();
                router.push({
                    name: "ticketDetails",
                    params: { id: response.data.id },
                });
            } else {
                if(response.error?.errors) {
                    attachment_errors.value = response.error?.errors['attachments.0']
                }
                if(response.error.error) {
                    attachment_error.value = response.error?.error
                }
                console.log("response not success:", response);
                // emit("output", { render: true, type: "danger" });
            }
        });
    }
};

interface Attachments {
    data: File[][];
}

const attachments: Attachments = reactive({
    data: [[]],
});

const attachment_errors = ref<any>({});
const attachment_error = ref<any>(null);
const uploadFile = (e: Event) => {
    const files = (e.target as HTMLInputElement).files;
    if (files !== null) {
        // attachments.data.push(Array.from(files));
        const fileArray = Array.from(files);
        attachments.data = [fileArray];
    }
    attachment_errors.value = ""
    attachment_error.value = ""
};

const successNotification = ref<NotificationElement>();
const successNotificationToggle = () => {
    // Show notification
    successNotification.value?.showToast();
};

provide("bind[successNotification]", (el: NotificationElement) => {
    successNotification.value = el;
});

const showSuccessNotification = () => {
    successNotificationToggle();
};
</script>
<template>
    <div class="intro-y box p-5">
        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Priority
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="priority"
                v-model="ticketForm.priority"
                class="w-full"
                name="priority"
            >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
            </TomSelect>
            <template v-if="validate.priority.$error">
                <div
                    v-for="(error, index) in validate.priority.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel class="form-label" for="crud-form-1"
                >Issue Type
                <span style="color: rgb(255, 0, 0)">*</span></FormLabel
            >
            <TomSelect
                id="issueType"
                v-model="ticketForm.issueType"
                class="w-full"
                name="issueType"
            >
                <option value="Hardware">Hardware</option>
                <option value="Software">Software</option>
                <option value="Internet Connection">Internet Connection</option>
                <option value="Network Connection">Network Connection</option>
                <option value="Client Software">Client Software</option>
                <option value="Reset Password">Reset Password</option>
                <option value="Access Card">Access Card</option>
                <option value="Request for Seating Arrangement">
                    Request for Seating Arrangement
                </option>
            </TomSelect>
            <template v-if="validate.issueType.$error">
                <div
                    v-for="(error, index) in validate.issueType.$errors"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error.$message }}
                </div>
            </template>
        </div>

        <div class="mt-3">
            <FormLabel
                >Issue Description
                <span class="text-slate-400 mt-1"
                    >(please include exact error message and screenshots if
                    possible.)</span
                >
                <span style="color: rgb(255, 0, 0)">*</span>
            </FormLabel>
            <div class="mt-2">
                <ClassicEditor
                    id="issueDescription"
                    v-model="ticketForm.issueDescription"
                    :config="editorConfig"
                    name="issueDescription"
                />
                <template v-if="validate.issueDescription.$error">
                    <div
                        v-for="(error, index) in validate.issueDescription
                            .$errors"
                        id="error_issueDescription"
                        :key="index"
                        class="text-danger mt-2"
                    >
                        {{ error.$message }}
                    </div>
                </template>
            </div>
        </div>

        <div class="mt-3 sm:col-span-2">
            <FormLabel
                class="form-label inline-block mb-2 text-gray-700"
                for="message"
                >Attachment
            </FormLabel>
            <div class="mt-1">
                <input
                    class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    multiple
                    type="file"
                    @change="uploadFile"
                />
            </div>
            <template v-if="attachment_error">
                <div
                    id="error_reason"
                    class="text-danger mt-2"
                >
                    {{ attachment_error }}
                </div>
            </template>
            <template v-if="attachment_errors">
                <div
                    v-for="(error, index) in attachment_errors"
                    id="error_reason"
                    :key="index"
                    class="text-danger mt-2"
                >
                    {{ error }}
                </div>
            </template>
        </div>

        <div class="text-right mt-5">
            <router-link
                class="btn text-white bg-warning shadow-sm py-2 px-3
                    inline-flex items-center justify-center
                    rounded-md w-28 text-center"
                to="/submit-a-ticket"
                type="button"
            >
                Cancel
            </router-link>
            <Button
                class="ml-2"
                type="button"
                variant="customPrimary"
                @click="submitTicket"
                >Submit Ticket
            </Button>
        </div>
    </div>
    <!-- Success Notification -->
    <Notification
        :options="{
            duration: 3000,
        }"
        class="flex"
        refKey="successNotification"
    >
        <Lucide class="text-success" icon="CheckCircle" />
        <div class="ml-4 mr-4">
            <div class="font-medium">System Notification</div>
            <div class="mt-1 text-slate-500">
                Ticket was created successfully.
            </div>
        </div>
    </Notification>
    <!-- Success Notification -->
</template>
