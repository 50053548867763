<script lang="ts" setup>
import { onMounted } from "vue";
import { useActivityLogsStore } from "../../stores/activityLogsStore";
import Avatar from "./Avatar.vue";
import router from "../../router";

const ActivityLogStore = useActivityLogsStore();

onMounted(async () => {
    await ActivityLogStore.fill();
});

const gotoLogs = (logs: any) => {
    let link = "/";
    const created = logs.event.includes("created");
    const updated = logs.event.includes("updated");
    console.log(" description logs", logs.description);
    switch (logs.description) {
        case "Team":
            router.push({ name: "teams" });
            break;
        case "Employee":
            console.log(" employees logs", logs.properties.attribute.id);
            router.push({
                name: "employeesDetails",
                params: { id: logs.properties.attribute.id },
            });
            break;
        case "Client":
            console.log(" clients logs", logs.properties.attribute.id);
            router.push({
                name: "clientsDetails",
                params: { id: logs.properties.attribute.id },
            });
            break;
        case "Ticket":
            router.push({
                name: "ticketDetails",
                params: { id: logs.properties.attribute.id },
            });
            break;
        default:
            break;
    }
};

const gotoNotifications = () => {
    router.push({ name: "notifications" });
};
</script>
<template>
    <div>
        <div class="intro-x flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">Recent Activities</h2>
            <a
                class="ml-auto text-primary truncate"
                href=""
                @click="gotoNotifications"
                >Show More</a
            >
        </div>
        <div
            class="mt-5 relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-5"
        >
            <div
                v-for="log in ActivityLogStore.logs"
                class="intro-x relative flex items-center mb-3"
            >
                <div
                    class="before:block before:absolute before:w-20 before:h-px before:bg-slate-200 before:dark:bg-darkmode-400 before:mt-5 before:ml-5"
                >
                    <Avatar
                        :name="log.performedByName || 'Loading User'"
                        :ring="'online'"
                        :size="'sm'"
                    />
                </div>
                <div
                    class="box px-5 py-3 ml-4 flex-1 zoom-in"
                    @click="gotoLogs(log)"
                >
                    <div class="flex items-center">
                        <div class="font-medium">
                            {{ log.performedByName }}
                        </div>
                        <div class="text-xs text-slate-500 ml-auto">
                            {{ log.created_at }}
                        </div>
                    </div>
                    <div class="text-slate-500 mt-1">{{ log.event }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
