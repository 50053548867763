import { defineStore } from "pinia";
import Http from "@/services/Http";
import moment from "moment";
import { EmployeeReFeeInterface } from "@/pages/interfaces/employeesInterface";
import ApiResponseInterface from "@/pages/interfaces/apiResponseInterface";

export const useEmployeeReFeeStore = defineStore("EmployeeReFeeStore", {
    state: (): {
        employeeReFees: EmployeeReFeeInterface[];
        employeeReFee: EmployeeReFeeInterface;
    } => {
        return {
            employeeReFees: [] as EmployeeReFeeInterface[],
            employeeReFee: {} as EmployeeReFeeInterface,
        };
    },

    actions: {
        async getEmployeeReFees(employeeId: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<EmployeeReFeeInterface[]>
            >(`/employee-re-fees?employee_id=${employeeId}`);

            this.employeeReFees = data;
        },

        async getEmployeeReFee(id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).get<
                ApiResponseInterface<EmployeeReFeeInterface>
            >(`/employee-re-fees/${id}`);

            this.employeeReFee = data;
        },

        async addEmployeeReFee(payload: EmployeeReFeeInterface): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).post<
                ApiResponseInterface<EmployeeReFeeInterface>
            >("/employee-re-fees", payload);

            let inOrder = true;
            const hasItems = this.employeeReFees.length > 0;
            const newEffectiveFrom = moment(data.effective_from);
            if (hasItems) {
                const latestAmount = this.employeeReFees[0];
                if (latestAmount.effective_to == null && newEffectiveFrom.isAfter(latestAmount.effective_from)) {
                    latestAmount.effective_to = newEffectiveFrom.subtract(1, 'day').format('YYYY-MM-DD');
                    this.employeeReFees.splice(0, 1, latestAmount);
                } else if (newEffectiveFrom.isBefore(latestAmount.effective_from)) {
                    inOrder = false;
                    this.employeeReFees.splice(0, 1, data);
                    this.employeeReFees.unshift(latestAmount);
                }
            }
            if (inOrder) {
                this.employeeReFees.unshift(data);
            }
        },

        async updateEmployeeReFee(
            payload: EmployeeReFeeInterface
        ): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            const { data } = await new Http(baseUrl).put<
                ApiResponseInterface<EmployeeReFeeInterface>
            >(`/employee-re-fees/${payload.id}`, payload);

            const index = this.employeeReFees.findIndex(
                (item) => item.id === data.id
            );
            this.employeeReFees.splice(index, 1, data);
        },

        async deleteEmployeeReFee(id: number): Promise<void> {
            const baseUrl = import.meta.env.VITE_API_URL;

            await new Http(baseUrl).delete(`/employee-re-fees/${id}`);

            const index = this.employeeReFees.findIndex(
                (item) => item.id === id
            );

            this.employeeReFees.splice(index, 1);
        },
    },
});
