<script lang="ts" setup>
import Button from "../../base-components/Button/Button.vue";
import Lucide from "../../base-components/Lucide";
import { useClientNoteStore } from "../../stores/clientNoteStore";
import { onMounted, ref } from "vue";
import FormSlider from "../FormSlider.vue";
import { useRoute } from "vue-router";
import FormClientNote from "../forms/client/FormClientNote.vue";
import { ClientNoteInterface } from "../interfaces/clientNoteInterface";
import ConfirmModal from "./ConfirmModal.vue";
import { useAuthStore } from "../../stores/authStore";

const route = useRoute();
const NoteStore = useClientNoteStore();
const AuthStore = useAuthStore();
const note = ref<ClientNoteInterface>();
const slider = ref(false);
const deleteId = ref(0);
const deleted = ref();
const showConfirmDialog = ref(false);

onMounted(() => {
    const clientId = route.params.id;

    NoteStore.getClientNotes(+clientId);
});

const open = (data: ClientNoteInterface) => {
    slider.value = !slider.value;

    if (data && Object.keys(data).length > 0) {
        note.value = data;
    } else {
        note.value = {} as ClientNoteInterface;
    }
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        NoteStore.deleteNote(deleteId.value)
            .then(() => {
                deleteId.value = 0;
                deleted.value = true;
            })
            .catch(() => {
                deleted.value = false;
            });
    }
};
</script>

<template>
    <div class="intro-y box col-span-12 lg:col-span-4 bg-white">
        <div class="intro-y box col-span-12 lg:col-span-6">
            <div
                class="flex items-center px-5 py-3 sm:py-3 border-b border-slate-200/60 dark:border-darkmode-400"
            >
                <h2 class="font-medium leading-9 text-base mr-auto">Notes</h2>

                <Button
                    v-if="AuthStore.hasPermission('can add client note')"
                    id="add-note"
                    class="w-10"
                    size="sm"
                    variant="soft-secondary"
                    @click="slider = !slider"
                >
                    <Lucide class="w-5 h-5" icon="Plus" />
                </Button>
            </div>

            <div
                class="py-8 border-t border-slate-200/60 dark:border-darkmode-400"
            >
                <ul
                    v-if="NoteStore.notes.length > 0"
                    class="border border-gray-200 rounded-md divide-y divide-gray-200"
                    role="list"
                >
                    <li
                        v-for="note in NoteStore.notes"
                        class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    >
                        <div class="w-0 flex-1 flex items-center">
                            {{ note.note }}
                        </div>

                        <div class="ml-4 flex gap-x-2 flex-shrink-0">
                            <a
                                v-if="
                                    AuthStore.hasPermission(
                                        'can update clients'
                                    )
                                "
                                class="font-medium text-blue-500 hover:text-blue-800"
                                href="#"
                                @click="open(note)"
                            >
                                <Lucide class="w-4 h-4" icon="Edit" />
                            </a>

                            <a
                                v-if="
                                    AuthStore.hasPermission(
                                        'can delete clients'
                                    )
                                "
                                class="font-medium text-red-500 hover:text-red-800"
                                href="#"
                                @click="confirmDelete($event, note)"
                            >
                                <Lucide class="w-4 h-4" icon="Trash" />
                            </a>
                        </div>
                    </li>
                </ul>

                <div
                    v-if="NoteStore.notes.length === 0"
                    class="p-8 text-center"
                >
                    No available notes.
                </div>
            </div>
        </div>
    </div>

    <FormSlider :isOpen="slider" :size="'md'" header="Add Note" @close="open">
        <FormClientNote :note="note" @close="slider = !slider" />
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Note has been successfully deleted."
        @closeConfirmModal="setConfirmDialog(false)"
        @deleted="deleted = $event"
        @proceedProcess="processDelete($event)"
    />
</template>
