<script lang="ts" setup>
import { onMounted, ref, inject, watch, Ref } from "vue";
import { useMyTimesheetStore } from "@/stores/myTimesheetStore";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { EmployeeAttendanceInterface } from "@/pages/interfaces/employeeAttendanceInterface";
import moment from "moment-timezone";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import FormInput from "@/base-components/Form/FormInput.vue";
import Lucide from "@/base-components/Lucide/Lucide.vue";
import PageHeaderStandard from "../components/PageHeaderStandard.vue";
import Button from "@/base-components/Button";
import { useTeamStore } from "@/stores/teamStore";
import { useClientsStore } from "@/stores/clientsStore";
import { useAuthStore } from "@/stores/authStore";

const MyTimesheetStore = useMyTimesheetStore();
const ClientsStore = useClientsStore();
const TeamStore = useTeamStore();
const AuthStore = useAuthStore();

const initialLazyParams = {
    first: 0,
    rows: 30,
    sortField: null,
    sortOrder: null,
    filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "employee.name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "employee.client.legal_business_name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        "shift.name": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        "employee.employee_teams": {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
    },
};

const dt = ref();
const lazyParams = ref<LazyParamsInterface>({ rows: initialLazyParams.rows });
const loading = ref(false);
const filters = ref(initialLazyParams.filters);
const teamFilter = ref("");
const searchableColumns = ref([
    "employee.name",
    "employee.third_party_employee_id",
    "employee.client.legal_business_name",
    "employee.shift.name",
]);
const selectAll = ref(false);
const selectedAttendance = ref<EmployeeAttendanceInterface[]>();
const showClearFilter = ref(false);
const expandedRows = ref([]);

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    showClearFilter.value = true;

    lazyParams.value = event;

    await onDataFetch();
};

const onSelectAllChange = (event: { checked: any }) => {
    selectAll.value = event.checked;

    selectedAttendance.value = selectAll.value
        ? MyTimesheetStore.attendances.data
        : [];
};

const onRowSelect = () => {
    selectAll.value =
        selectedAttendance.value?.length ===
        MyTimesheetStore.attendances.data.length;
};

const onRowUnselect = () => {
    selectAll.value = false;
};

const onRowCollapse = () => {
    console.log("onRowSelect");
};

const onRowExpand = () => {
    console.log("onRowExpand");
};

const setEndTime = (data: any) => {
    const start = moment(data.start_time);
    const end = data?.end_time ? moment(data.end_time) : null;
    const now = moment();
    const shift = {
        start: moment(start.format("YYYY-MM-DD") + " " + data.shift.start_time),
        end: moment(start.format("YYYY-MM-DD") + " " + data.shift.end_time),
    };

    if (end === null && now.isBefore(shift.end)) {
        return "On Shift";
    }

    if (end === null && now.isAfter(shift.end)) {
        return "No End Shift Logged";
    }

    return moment(data.end_time).format("MM-DD-YYYY HH:mm:ss");
};

const resetGlobalFilter = async () => {
    showClearFilter.value = false;

    filters.value = initialLazyParams.filters;

    teamFilter.value = "";

    lazyParams.value = {
        ...initialLazyParams,
        filters: filters.value,
    };

    await onDataFetch();
};

const onClickTeamFilterClear = () => {
    teamFilter.value = "";

    onDataFetch();
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableColumns.value),
        team_filter: teamFilter.value,
    };

    await MyTimesheetStore.getAttendances(params);

    loading.value = false;
};

const attendance = inject<{isUpdated: Ref<boolean>; setIsUpdated: (arg:boolean) => void}>('attendance');

if (attendance) {
    watch(attendance.isUpdated, (hasUpdated) => {
        if(hasUpdated) {
            onDataFetch();
            setTimeout(() => {
                attendance?.setIsUpdated(false);
            }, 1000);
        }
    });
}

onMounted(async () => {
    const allowedRoles = ["admin", "hr"];
    await onDataFetch();

    if (allowedRoles.includes(AuthStore.role[0])) {
        await ClientsStore.getAllClients();
        await TeamStore.getAllTeams();
    }
});
</script>

<template>
    <div class="w-full">
        <PageHeaderStandard title="Employees Attendance" />

        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
            <div class="hidden md:block mx-auto text-slate-500"></div>

            <div class="flex w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
                <template v-if="showClearFilter">
                    <Button
                        class="btn bg-custom-color-1000 text-white w-full mx-3 rounded-md px-4"
                        @click="resetGlobalFilter"
                    >
                        Clear
                        <span
                            class="h-3 w-4 pi pi-filter-slash p-component"
                        ></span>
                    </Button>
                </template>

                <div class="ml-2 w-56 relative text-slate-500">
                    <FormInput
                        v-model="filters.global.value"
                        :value="filters.global.value"
                        class="w-56 pr-10 !box"
                        placeholder="Search..."
                        type="text"
                        @keyup.enter="onFilter($event)"
                    />

                    <Lucide
                        class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                        icon="Search"
                    />
                </div>
            </div>
        </div>

        <DataTable
            ref="dt"
            v-model:expanded-rows="expandedRows"
            v-model:filters="filters"
            v-model:selection="selectedAttendance"
            :globalFilterFields="searchableColumns"
            :lazy="true"
            :loading="loading"
            :paginator="true"
            :rows="initialLazyParams.rows"
            :rowsPerPageOptions="[30, 50, 100]"
            :selectAll="selectAll"
            :totalRecords="MyTimesheetStore.attendances.total"
            :value="MyTimesheetStore.attendances.data"
            class="mt-4 p-datatable-sm rounded-lg shadow-lg text-xs"
            filterDisplay="menu"
            responsiveLayout="scroll"
            showGridlines
            stripedRows
            @filter="onFilter($event)"
            @page="onPage($event)"
            @sort="onSort($event)"
            @select-all-change="onSelectAllChange($event)"
            @row-select="onRowSelect"
            @row-unselect="onRowUnselect"
            @row-collapse="onRowCollapse"
            @row-expand="onRowExpand"
        >
            <template #empty> No available data.</template>

            <template #loading> Loading data. Please wait.</template>

            <Column :expander="true" class="!min-w-0 w-0.5" />

            <Column class="w-0.5 text-center" selectionMode="multiple"></Column>

            <Column field="employee.name" header="Employee" sortable>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by employee name"
                        type="text"
                    />
                </template>

                <template #body="{ data }">
                    {{ data.employee.name }} -
                    {{ data.employee.third_party_employee_id }}
                </template>
            </Column>
            <Column
                field="employee.client.legal_business_name"
                header="Client"
                sortable
            >
                <template #filter="{ filterModel }">
                    <Dropdown
                        v-model="filterModel.value"
                        :options="ClientsStore.clients"
                        option-label="legal_business_name"
                        option-value="legal_business_name"
                        placeholder="Select Client Business Name"
                    />
                </template>
            </Column>

            <Column
                :show-add-button="false"
                :show-filter-match-modes="false"
                :show-filter-operator="false"
                field="employee.employee_teams"
                header="Teams"
                sortable
            >
                <template #filter>
                    <Dropdown
                        v-model="teamFilter"
                        :options="TeamStore.teams"
                        option-label="name"
                        option-value="name"
                        placeholder="Select Team"
                    />
                </template>

                <template #filterclear>
                    <Button
                        class="border border-[#2196f3] text-[#2196f3] py-1.5"
                        @click="onClickTeamFilterClear"
                        >Clear
                    </Button>
                </template>

                <template #body="{ data }">
                    <div v-if="data.employee.employee_teams.length > 0">
                        <div
                            v-for="team in data.employee.employee_teams"
                            :key="team.id"
                        >
                            <div v-for="t in team.team" :key="t.id">
                                {{ t.name }}
                            </div>
                        </div>
                    </div>
                    <div v-else>No Team Assigned</div>
                </template>
            </Column>

            <Column field="shift.name" header="Shift" sortable>
                <template #filter="{ filterModel }">
                    <InputText
                        v-model="filterModel.value"
                        class="p-column-filter"
                        placeholder="Search by shift name"
                        type="text"
                    />
                </template>
            </Column>
            <Column field="start_time" header="Start" sortable>
                <template #body="{ data }">
                    {{ moment(data.start_time).format("MM-DD-YYYY HH:mm:ss") }}
                </template>
            </Column>

            <Column field="end_time" header="End" sortable>
                <template #body="{ data }">
                    <div v-if="data.end_time === null">
                        <span
                            :class="{
                                'text-green-600':
                                    data.attendance_status === 'Clocked In',
                                'text-orange-600':
                                    data.attendance_status === 'Working Late',
                                'text-yellow-600':
                                    data.attendance_status === 'On break',
                            }"
                            class="font-bold"
                        >
                            {{ data.attendance_status }}
                        </span>
                    </div>

                    <div v-else>{{ setEndTime(data) }}</div>
                </template>
            </Column>

            <Column
                class="!text-center"
                field="total_hours"
                header="Total Hours"
            >
                <template #body="{ data }">
                    {{ data.total_hours }}
                </template>
            </Column>

            <template #expansion="slotProps">
                <h5 class="text-md">
                    <b class="text-md">Breaks</b>
                </h5>

                <DataTable
                    :value="slotProps.data.breaks"
                    class="mt-2 text-xs"
                    responsiveLayout="scroll"
                >
                    <template #empty> No available data.</template>

                    <template #loading> Loading data. Please wait.</template>

                    <Column
                        field="type.friendly_name"
                        header="Break Type"
                        sortable
                    />
                    <Column field="start_time" header="Start" sortable>
                        <template #body="{ data }">
                            {{
                                moment(data.start_time)
                                    .tz("Asia/Manila")
                                    .format("MM-DD-YYYY HH:mm:ss")
                            }}
                        </template>
                    </Column>
                    <Column field="end_time" header="End" sortable>
                        <template #body="{ data }">
                            <div v-if="data.end_time !== null">
                                {{
                                    moment(data.end_time)
                                        .tz("Asia/Manila")
                                        .format("MM-DD-YYYY HH:mm:ss")
                                }}
                            </div>
                        </template>
                    </Column>
                    <Column
                        class="!text-center"
                        field="total_hours"
                        header="Total Hours"
                    >
                        <template #body="{ data }">
                            {{ data.total_hours }}
                        </template>
                    </Column>
                </DataTable>
            </template>
        </DataTable>
    </div>
</template>
