<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { useAuthStore } from "@/stores/authStore";
import { useClientInvoiceConfigStore } from "@/stores/clientInvoiceConfigStore";
import { useInvoiceStore } from "@/stores/invoiceStore";
import { useClientsStore } from "@/stores/clientsStore";
import { ClientInvoiceConfigInterface } from "@/pages/interfaces/clientInvoiceConfigInterface";
import ClientInterface from "@/pages/interfaces/clientsInterface";
import LazyParamsInterface from "@/pages/interfaces/lazyParamsInterface";
import Button from "@/base-components/Button";
import TomSelect from "@/base-components/TomSelect";
import ConfirmModal from "@/pages/components/ConfirmModal.vue";
import FormSlider from "@/pages/FormSlider.vue";
import FormClientInvoiceConfig from "@/pages/forms/client/FormClientInvoiceConfig.vue";

const route = useRoute();
const AuthStore = useAuthStore();
const ClientInvoiceConfigStore = useClientInvoiceConfigStore();
const InvoiceStore = useInvoiceStore();
const ClientsStore = useClientsStore();

enum BillPeriod {
    current_month = "Current Month",
    future_month = "Future Month",
}

const billType = ref("current_month");
const billMonth = ref(dayjs().format("MM"));
const billYear = ref(dayjs().format("YYYY"));
const override = ref(false);
enum BillType {
    adjustment = "Adjustment Only",
    monthly = "Monthly Cycle",
    combined = "Monthy Cycle w/ Adjustment",
    manual = "Manual",
}

const bill_period = ref("current_month");
const slider = ref(false);
const deleteId = ref(0);
const deleted = ref();
const showConfirmDialog = ref(false);
const lazyParams = ref<LazyParamsInterface>({ rows: 30 });
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "client.legal_business_name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
});
const searchableCols = ref(["client.legal_business_name"]);
const loading = ref(false);
const generating = ref(false);
const showClearFilter = ref(false);
const dt = ref();
const config = ref<ClientInvoiceConfigInterface>();
const client = ref<ClientInterface>();
const onClickButton = (data?: ClientInvoiceConfigInterface) => {
    slider.value = !slider.value;

    if (
        data &&
        Object.keys(data).length > 0 &&
        !(data instanceof PointerEvent)
    ) {
        config.value = data;
    } else {
        config.value = {} as ClientInvoiceConfigInterface;
    }
};

const close = async () => {
    slider.value = !slider.value;

    await onDataFetch();
};

const onFilter = async (event: any) => {
    showClearFilter.value = filters.value.global.value !== "";

    lazyParams.value.filters = filters.value;

    await onDataFetch();
};

const onSort = async (event: any) => {
    showClearFilter.value = true;
    lazyParams.value = event;

    await onDataFetch();
};

const onPage = async (event: any) => {
    lazyParams.value = event;

    await onDataFetch();
};

const confirmDelete = (event: { preventDefault: () => void }, data: any) => {
    event.preventDefault();
    deleteId.value = data.id;
    setConfirmDialog(true);
};

const setConfirmDialog = (value: boolean) => {
    showConfirmDialog.value = value;
};

const processDelete = (event: any) => {
    if (event === false) {
        deleteId.value = 0;
    } else {
        ClientInvoiceConfigStore.deleteClientInvoiceConfig(deleteId.value)
            .then(() => {
                deleteId.value = 0;
                deleted.value = true;
            })
            .catch((error) => {
                deleted.value = false;
            });
    }
};

const onDataFetch = async () => {
    loading.value = true;

    const params = {
        client_id: route?.params?.id,
        dt_params: JSON.stringify(lazyParams.value),
        searchable_columns: JSON.stringify(searchableCols.value),
    };

    await ClientInvoiceConfigStore.getClientInvoiceConfigs(params);

    loading.value = false;
};

const onClickGenerate = async (event: string) => {
    generating.value = true;
    const params = {
        client_id: route?.params?.id,
        type: billType.value,
        month: billMonth.value,
        year: billYear.value,
        override: override.value,
    };

    try {
        await InvoiceStore.generateInvoice(params);
        await onDataFetch();
    } catch (error) {
        console.log(error);
    } finally {
        generating.value = false;
    }
};

const isLocal = computed(() => import.meta.env.VITE_APP_ENV === "local");

const months = computed(() => {
    const currentMonth = dayjs().format("MM");

    const succeedingMonths = Array.from(
        {
            length: 12 - Number(dayjs().format("MM")),
        },
        (_, i) => {
            return dayjs()
                .add(i + 1, "month")
                .format("MM");
        }
    );

    return [
        {
            label: dayjs(currentMonth, "MM").format("MMMM"),
            value: currentMonth,
        },
        ...succeedingMonths.map((month) => {
            return {
                label: dayjs(month, "MM").format("MMMM"),
                value: month,
            };
        }),
    ];
});

onMounted(async () => {
    await onDataFetch();

    if (ClientsStore.clientDetails) {
        client.value = ClientsStore.clientDetails;
    } else {
        await ClientsStore.getClientDetails(+route?.params?.id);
        client.value = ClientsStore.clientDetails;
    }
});
</script>

<template>
    <div class="intro-y box col-span-12 border-2 mt-2">
        <div
            class="flex items-center px-5 py-5 sm:py-5 border-b border-slate-200/60 dark:border-darkmode-400"
        >
            <div class="flex-1 flex items-center">
                <h2 id="sales-agent" class="font-medium text-base mr-auto">
                    Invoice Configurations
                </h2>

                <div v-if="isLocal" class="flex justify-between items-center">
                    <div class="ml-2 flex items-center">
                        <input
                            id="override"
                            type="checkbox"
                            v-model="override"
                            :value="override"
                        />
                        <label for="override" class="mx-1"> Override </label>
                    </div>

                    <div class="ml-1">
                        <TomSelect
                            v-model="billMonth"
                            id="bill_month"
                            class="w-52 h-[34px] p-0 text-sm"
                            name="bill_month"
                        >
                            <option
                                v-for="month in months"
                                :value="month.value"
                            >
                                {{ month.label }}
                            </option>
                        </TomSelect>
                    </div>

                    <div class="ml-2">
                        <TomSelect
                            v-model="billYear"
                            id="bill_year"
                            class="w-52 h-[34px] p-0 text-sm"
                            name="bill_year"
                        >
                            <option
                                v-for="year in [2024, 2025, 2026]"
                                :value="year"
                            >
                                {{ year }}
                            </option>
                        </TomSelect>
                    </div>

                    <div class="ml-1">
                        <TomSelect
                            v-model="billType"
                            id="bill_period"
                            class="w-52 h-[34px] p-0 text-sm"
                            name="bill_period"
                        >
                            <option value="current_month">Current Month</option>
                            <option value="future_month">Future Month</option>
                        </TomSelect>
                    </div>

                    <Button
                        class="ml-1 text-xs"
                        variant="soft-secondary"
                        :disabled="
                            (
                                ClientInvoiceConfigStore.clientInvoiceConfigs
                                    ?.data || []
                            ).length === 0 || generating
                        "
                        @click="onClickGenerate(bill_period)"
                    >
                        <div v-if="generating" class="flex items-center">
                            <svg
                                aria-hidden="true"
                                class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                fill="none"
                                viewBox="0 0 100 101"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                />
                            </svg>

                            <div>Generating invoice...</div>
                        </div>

                        <div v-else>Generate Invoice</div>
                    </Button>
                </div>

                <Button
                    v-if="
                        AuthStore.hasPermission(
                            'can create client invoice configs'
                        )
                    "
                    id="add-invoice-config"
                    class="text-xs ml-1"
                    variant="soft-secondary"
                    @click="onClickButton()"
                >
                    Add Invoice Config
                </Button>
            </div>
        </div>

        <div class="p-5">
            <DataTable
                ref="dt"
                v-model:filters="filters"
                :globalFilterFields="searchableCols"
                :lazy="true"
                :loading="loading"
                :paginator="true"
                :rows="30"
                :rowsPerPageOptions="[30, 50, 100]"
                :totalRecords="
                    ClientInvoiceConfigStore.clientInvoiceConfigs?.total
                "
                :value="ClientInvoiceConfigStore.clientInvoiceConfigs?.data"
                class="mt-4 p-datatable-sm text-xs"
                filterDisplay="menu"
                responsiveLayout="scroll"
                showGridlines
                stripedRows
                @filter="onFilter($event)"
                @page="onPage($event)"
                @sort="onSort($event)"
            >
                <template #empty> No available data.</template>

                <template #loading> Loading data. Please wait.</template>

                <Column
                    :sortable="true"
                    field="client.legal_business_name"
                    header="Client"
                >
                </Column>

                <Column :sortable="true" field="setup_fee" header="Setup Fee" />
                <Column :sortable="true" field="re_fee" header="RE Fee" />
                <Column :sortable="true" field="start_date" header="Start Date">
                    <template #body="{ data }">
                        {{ dayjs(data.start_date).format("YYYY-MM-DD") }}
                    </template>
                </Column>
                <Column :sortable="true" field="end_date" header="End Date">
                    <template #body="{ data }">
                        <div v-if="data.end_date !== null">
                            {{ dayjs(data.end_date).format("YYYY-MM-DD") }}
                        </div>
                    </template>
                </Column>
                <Column :sortable="true" field="bill_type" header="Bill Type">
                    <template #body="{ data }">
                        {{ BillType[data.bill_type as keyof typeof BillType] }}
                    </template>
                </Column>
                <Column
                    :sortable="true"
                    field="bill_period"
                    header="Bill Period"
                >
                    <template #body="{ data }">
                        {{
                            BillPeriod[
                                data.bill_period as keyof typeof BillPeriod
                            ]
                        }}
                    </template>
                </Column>
                <Column :sortable="true" field="bill_day" header="Bill Day" />

                <Column class="!w-[5%]">
                    <template #body="{ data }">
                        <div class="flex justify-center items-center">
                            <div
                                v-if="
                                    AuthStore.hasPermission(
                                        'can update client invoice configs'
                                    )
                                "
                            >
                                <svg
                                    :id="'update_' + data.id"
                                    class="icon icon-tabler icon-tabler-edit cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="onClickButton(data)"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path
                                        d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"
                                    ></path>
                                    <path
                                        d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"
                                    ></path>
                                    <path d="M16 5l3 3"></path>
                                </svg>
                            </div>

                            <div
                                v-if="
                                    AuthStore.hasPermission(
                                        'can delete client invoice configs'
                                    )
                                "
                                class="ml-2"
                            >
                                <svg
                                    :id="'delete_' + data.id"
                                    class="icon icon-tabler icon-tabler-trash cursor-pointer"
                                    fill="none"
                                    height="18"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="confirmDelete($event, data)"
                                >
                                    <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                        stroke="none"
                                    ></path>
                                    <path d="M4 7l16 0"></path>
                                    <path d="M10 11l0 6"></path>
                                    <path d="M14 11l0 6"></path>
                                    <path
                                        d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                                    ></path>
                                    <path
                                        d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <FormSlider
        :isOpen="slider"
        :size="'md'"
        header="Invoice Configuration Form"
        @close="onClickButton"
    >
        <FormClientInvoiceConfig
            :client="client as ClientInterface"
            :config="config"
            @close="onClickButton"
        />
    </FormSlider>

    <ConfirmModal
        :deleted="deleted"
        :displayConfirmModal="showConfirmDialog"
        second-description="Invoice config has been successfully deleted."
        @closeConfirmModal="setConfirmDialog(false)"
        @deleted="deleted = $event"
        @proceedProcess="processDelete($event)"
    />
</template>

<style scoped></style>
